import styled from '@emotion/styled';
import {
  COLORS,
  createTextStyles,
  shouldForwardProp,
  TextProps,
  TextSize,
  TextWeight
}  from 'packages/constants';
import * as styledSystem from 'styled-system';

import { BaseProps } from '../index.types';

const whiteSpace = styledSystem.style({
  prop: 'whiteSpace',
  cssProperty: 'white-space',
});

const baseStyledSystem = styledSystem.compose(
  styledSystem.background,
  styledSystem.border,
  styledSystem.display,
  styledSystem.flexbox,
  styledSystem.layout,
  styledSystem.overflow,
  styledSystem.position,
  styledSystem.padding,
  styledSystem.space,
  whiteSpace,
);

interface CustomParagraphProps extends Omit<TextProps, 'size' | 'weight'> {
  textSize?: keyof typeof TextSize;
  textWeight?: keyof typeof TextWeight;
}

interface ParagraphProps extends BaseProps,
  styledSystem.PaddingProps,
  styledSystem.MarginProps,
  CustomParagraphProps {}

const textStyles = ({
  color = COLORS.Neutral[1000],
  textWeight = TextWeight.Normal,
  textSize = TextSize.Size4,
}: CustomParagraphProps) => {
  const paragraphWeight = TextWeight[textWeight];
  const paragraphSize = TextSize[textSize];

  return createTextStyles({
    color,
    weight: paragraphWeight,
    size: paragraphSize,
  });
};

/**
 * @default
 * color: COLORS.Neutral[1000]
 * textWeight: TextWeight.Normal
 * textSize: TextSize.Size4
 */
const Paragraph = styled('p', { shouldForwardProp })<ParagraphProps & React.ButtonHTMLAttributes<HTMLParagraphElement>>`
  ${baseStyledSystem};
  ${textStyles};
`;

Paragraph.displayName = 'Paragraph';

export { Paragraph };
