/* eslint-disable @typescript-eslint/prefer-literal-enum-member */
import styled from '@emotion/styled';
import {
  COLORS,
  createTextStyles,
  shouldForwardProp,
  TextProps,
  TextSize,
  TextWeight
}  from 'packages/constants';
import React, { HTMLAttributes } from 'react';
import * as styledSystem from 'styled-system';

import { BaseProps } from '../index.types';

const whiteSpace = styledSystem.style({
  prop: 'whiteSpace',
  cssProperty: 'white-space',
});

const baseStyledSystem = styledSystem.compose(
  styledSystem.background,
  styledSystem.border,
  styledSystem.display,
  styledSystem.flexbox,
  styledSystem.layout,
  styledSystem.overflow,
  styledSystem.position,
  styledSystem.padding,
  styledSystem.space,
  whiteSpace,
);

export enum HeaderSizeType {
  'h1' = TextSize.Size1,
  'h2' = TextSize.Size2,
  'h3' = TextSize.Size3,
  'h4' = TextSize.Size4,
  'h5' = TextSize.Size5,
  'h6' = TextSize.Size6
}

interface CustomHeaderProps extends Omit<TextProps, 'size' | 'weight'> {
  type?: keyof typeof HeaderSizeType;
  textWeight?: keyof typeof TextWeight;
}

export interface HeaderProps extends BaseProps,
  styledSystem.PaddingProps,
  styledSystem.MarginProps,
  CustomHeaderProps
  {}

const textStyles = ({
  color = COLORS.Neutral[1000],
  textWeight = TextWeight.Heavy,
  type,
}: CustomHeaderProps) => {
  const headerWeight = TextWeight[textWeight];
  const headerSize = HeaderSizeType[type || 'h1'];

  return createTextStyles({color, weight: headerWeight, size: TextSize[headerSize]});
};

/**
 * @default
 * color: COLORS.Neutral[1000]
 * textWeight: TextWeight.Heavy
 * type: HeaderSizeType.h1
 */
const Header = styled(
  ({ type = 'h1', ...rest }: CustomHeaderProps) => React.createElement(type, rest),
  { shouldForwardProp })<HeaderProps & HTMLAttributes<HTMLHeadingElement>>`
    ${baseStyledSystem}
    ${textStyles}
  `;

Header.displayName = 'Header';

export { Header };
