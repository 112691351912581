import { useQuery } from '@tanstack/react-query';
import semver from 'semver';
import { z } from 'zod';

import { isAxiosError } from '@/utils/axios';

import { api } from '../shared';
import { getSpaceVersionQuery } from '../spacesQueries';

const APIGroupVersion = z.object({ groupVersion: z.string(), version: z.string() });

// Note: The versions are sorted to help with feature discovery
const APIGroup = z.object({
  versions: APIGroupVersion.array()
    .transform(l => l.sort((a, b) => compareK8sVersions(a.version, b.version)))
    .optional(),
  preferredVersion: APIGroupVersion.optional(),
});

export async function getApiGroup(orgName: string, spaceName: string, groupName: string) {
  try {
    const res = await api()
      .get<unknown>(
        `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(spaceName)}/apis/${encodeURIComponent(
          groupName,
        )}`,
      )
      .then(r => r.data);
    return APIGroup.parse(res);
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 404) {
      return null;
    }

    throw e;
  }
}

const APIResourceList = z.object({ groupVersion: z.string(), resources: z.object({ name: z.string() }).array() });

export async function getApiResourceList(orgName: string, spaceName: string, groupName: string, version: string) {
  const res = await api()
    .get<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(spaceName)}/apis/${encodeURIComponent(
        groupName,
      )}/${encodeURIComponent(version)}`,
    )
    .then(r => r.data);
  return APIResourceList.parse(res);
}

export const K8sVersionRegexp = /^v([1-9]\d*)([a-z]*)([1-9]\d*)?$/;

/**
 * Sort kubernetes versions (e.g. `v1` or `v1alpha1`).
 *
 * See: https://kubernetes.io/docs/tasks/extend-kubernetes/custom-resources/custom-resource-definition-versioning/#version-priority
 */
export function compareK8sVersions(a: string, b: string): number {
  const amatch = a.match(K8sVersionRegexp);
  const bmatch = b.match(K8sVersionRegexp);

  const sort1 = (amatch ? -1 : 1) - (bmatch ? -1 : 1);
  if (sort1 !== 0) {
    return sort1;
  }
  if (!amatch || !bmatch) {
    return a.localeCompare(b);
  }

  const aver = parseInt(bmatch[1], 10);
  const bver = parseInt(amatch[1], 10);
  const astr = amatch[2];
  const bstr = bmatch[2];

  const sort2 = (astr === '' ? -1 : 1) - (bstr === '' ? -1 : 1);
  if (sort2 !== 0) {
    return sort2;
  }

  const aprever = parseInt(amatch[3] ?? '0', 10);
  const bprever = parseInt(bmatch[3] ?? '0', 10);

  return bstr.localeCompare(astr) || aver - bver || bprever - aprever;
}

/**
 * React hook that returns if a space matches a semver range. It returns undefined until it retrieves the version, then
 * true if in range and false otherwise. If `undefined` is passed to either `orgName` or `spaceName` false is returned.
 *
 * Example: `useSpaceVersionSatisfiesRange('upbound', 'some-space, '>= 1.3.1')` means the space much be greater than
 *          equal to 1.3.1.
 */
export function useSpaceVersionSatisfiesRange(
  orgName: string | undefined,
  spaceName: string | undefined,
  range: string,
  enabled = true,
): boolean | undefined {
  const { data, isLoading, error } = useQuery({
    ...getSpaceVersionQuery(orgName ?? '', spaceName ?? ''),
    enabled: enabled && !!orgName && !!spaceName,
  });

  if (!enabled || isLoading) {
    return undefined;
  }

  if (!error) {
    const parsed = semver.parse(data);
    if (parsed) {
      return semver.satisfies(parsed, range, { includePrerelease: true });
    }
  }

  return false;
}
