import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import { COLORS } from 'packages/constants';
import React, { HTMLAttributes, ReactNode } from 'react';
import * as styledSystem from 'styled-system';
import { COLORS as SHARED_COLORS, SHADES } from 'upbound-frontend-constants';

import { BaseProps } from '../index.types';

// TODO: The following is the POC for the table component.
// It needs to be refactored to be more generic and reusable.
// The aim of the POC was to leverage mono-repo package
const Table = ({
  children,
  ...props
}: Omit<BaseProps & HTMLAttributes<HTMLTableElement>, 'css' | keyof styledSystem.LayoutProps> & {
  children?: ReactNode;
  className?: string | undefined;
}) => <table {...props}>{children}</table>;

const StyledTable = styled(Table, { shouldForwardProp: prop => isPropValid(prop) })<{
  nested?: string;
  bottomSquare?: boolean;
}>`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  background-color: ${COLORS.Neutral[0]};
  border-top-left-radius: ${props => (props.nested === 'true' ? '0' : '6px')};
  border-top-right-radius: ${props => (props.nested === 'true' ? '0' : '6px')};
  border-bottom-left-radius: ${props => (props.bottomSquare ? '0' : '6px')};
  border-bottom-right-radius: ${props => (props.bottomSquare ? '0' : '6px')};

  tr {
    th {
      border-top: 1px solid ${SHARED_COLORS.Neutral[100]};
    }

    td,
    th {
      padding: 12px;
      vertical-align: middle;

      :first-of-type {
        padding-left: 20px;
      }

      :last-of-type {
        padding-right: 20px;
      }
    }

    th,
    td {
      border-bottom: 1px solid ${SHARED_COLORS.Neutral[100]};

      &:first-of-type {
        border-left: ${props => (props.nested === 'true' ? '0' : `1px solid ${SHARED_COLORS.Neutral[100]}`)};
      }

      &:last-child {
        border-right: ${props => (props.nested === 'true' ? '0' : `1px solid ${SHARED_COLORS.Neutral[100]}`)};
      }
    }

    &:first-of-type {
      th:first-of-type {
        border-top-left-radius: ${props => (props.nested === 'true' ? '0' : '6px')};
      }

      th:last-child {
        border-top-right-radius: ${props => (props.nested === 'true' ? '0' : '6px')};
      }
    }

    &:last-child {
      td:first-of-type {
        border-bottom-left-radius: ${props => (props.bottomSquare ? '0' : '6px')};
      }

      td:last-child {
        border-bottom-right-radius: ${props => (props.bottomSquare ? '0' : '6px')};
      }

      border-bottom: ${props => (props.nested === 'true' ? '0' : `1px solid ${SHARED_COLORS.Neutral[100]}`)};
    }
  }

  & > thead > tr {
    & > th {
      font-size: 12px;
      color: ${COLORS.Neutral[600]};
    }
  }
`;

Table.displayName = 'Table';

const TableRow = styled.tr<{ hoverable?: boolean; disabled?: boolean }>`
  transition: filter 0.2s ease-in-out;
  background-color: #fff;
  opacity: ${props => (props.disabled ? 0.8 : 1)};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'default')};

  td {
    word-break: break-all;

    &:first-of-type {
      p {
        color: ${SHARED_COLORS.Neutral[1000]} !important;
      }
    }
  }

  ${props =>
    props.hoverable &&
    `
    &:hover {
      cursor: pointer;
      filter: drop-shadow(0 0 0.275rem ${SHARED_COLORS.Neutral[200]});
      transform: scale(1);
    }
  `}
`;

const TablePill = styled.span<{ pillColor?: string }>`
  background-color: ${props => props.pillColor || SHADES.Neutral[50]};
  color: ${SHARED_COLORS.Neutral[1000]};
  border-radius: 11px;
  padding: 2px 10px;
  line-height: 18px;
  font-size: 12px;
  min-height: 22px;
  position: relative;
  display: inline-block;
  margin-right: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100%;
`;

const TableHeader = styled.th`
  &:first-of-type {
    padding-left: 20px !important;
  }

  text-align: left;
  line-height: 14px;

  > div {
    display: flex;
  }
`;

const EmptyResultsContainer = styled.span`
  line-height: 20px;
  margin-left: 32px;
  font-size: 14px;
  color: ${COLORS.Neutral[600]};
  text-align: left;
`;

export { StyledTable as Table, TableRow, TablePill, TableHeader, EmptyResultsContainer };
