import { createMedia } from '@artsy/fresnel';

import { COLORS } from './styles';

// Create the array of number values we'll use as a base in the rest of the file
const breakpointNumbers = {
  xs: 480,
  s: 576,
  m: 768,
  l: 992,
  xl: 1170,
};

// Create the array of values for `styled-system`
const breakpoints: Array<string> & {
  xxs?: string;
  xs?: string;
  s?: string;
  m?: string;
  l?: string;
  xl?: string;
} = Object.values(breakpointNumbers).map(bp => `${bp}px`);

// Define the shortcuts for `styled-system`
breakpoints.xs = breakpoints[0];
breakpoints.s = breakpoints[1];
breakpoints.m = breakpoints[2];
breakpoints.l = breakpoints[3];
breakpoints.xl = breakpoints[4];

// Setup `fresnel` and its exports
const AppMedia = createMedia({ breakpoints: { xxs: 0, ...breakpointNumbers } });
const MediaStyle = AppMedia.createMediaStyle();
const { Media, MediaContextProvider } = AppMedia;

// Create CSS media query helper
const MQ = {
  xs: `@media screen and (min-width: ${breakpoints.xs})`,
  s: `@media screen and (min-width: ${breakpoints.s})`,
  m: `@media screen and (min-width: ${breakpoints.m})`,
  l: `@media screen and (min-width: ${breakpoints.l})`,
  xl: `@media screen and (min-width: ${breakpoints.xl})`,
};

// Define the theme for `styled-system`
const StyledSystemTheme = {
  breakpoints,
  colors: COLORS,
  mediaQueries: MQ,
};

// Export
export { Media, MQ, MediaContextProvider, MediaStyle, StyledSystemTheme };
