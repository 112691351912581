import { css } from '@emotion/react';

export const fontBase = css`
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
`;

export const fontAvenir = css`
  ${fontBase}
  font-family: 'Avenir', 'Arial', sans-serif;
  font-weight: normal;
`;

export const fontAvenirBold = css`
  ${fontBase}
  font-family: 'Avenir', 'Arial', sans-serif;
  font-weight: bold;
`;

export const fontConsolas = css`
  ${fontBase}
  font-family: 'Consolas', monospace;
  font-weight: normal;
  ${fontBase}
`;
