import jsyaml from 'js-yaml';
import semver from 'semver';
import z from 'zod';

import { SpaceConnectionStatus } from '@/generated/upbound-graphql';

import { api, proxyApi } from './shared';

export enum PodPhaseEnum {
  Pending = 'Pending',
  Running = 'Running',
  Succeeded = 'Succeeded',
  Failed = 'Failed',
  Completed = 'Completed',
  Unknown = 'Unknown',
}

export type PodPhase = keyof typeof PodPhaseEnum;

const ItemsMetadataResponse = z.object({
  continue: z.string().optional(),
  remainingItemCount: z.number().optional(),
});

export type ItemsMetadata = z.infer<typeof ItemsMetadataResponse>;

const ItemsResponse = z.object({
  items: z.array(z.unknown()),
  metadata: ItemsMetadataResponse,
});

export type Items = z.infer<typeof ItemsResponse>;

export enum ControlPlaneUpgradeChannel {
  None = 'None',
  Patch = 'Patch',
  Stable = 'Stable',
  Rapid = 'Rapid',
}

export type CreateControlPlaneOptions = {
  crossplane?: { autoUpgrade?: { channel?: ControlPlaneUpgradeChannel }; version?: string };
  restore?: {
    source?: {
      apiGroup: string;
      kind: string;
      name: string;
    };
  };
};

export function createControlPlane(
  orgName: string,
  spaceName: string,
  groupName: string,
  controlPlaneName: string,
  options: CreateControlPlaneOptions,
): Promise<void> {
  return api()
    .post(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/apis/spaces.upbound.io/v1beta1/namespaces/${encodeURIComponent(groupName)}/controlplanes`,
      {
        apiVersion: 'spaces.upbound.io/v1beta1',
        kind: 'ControlPlane',
        metadata: {
          name: controlPlaneName,
        },
        spec: options,
      },
    )
    .then(() => undefined);
}

export function updateControlPlane(
  orgName: string,
  spaceName: string,
  groupName: string,
  controlPlaneName: string,
  options: CreateControlPlaneOptions,
): Promise<void> {
  return api()
    .patch(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/apis/spaces.upbound.io/v1beta1/namespaces/${encodeURIComponent(groupName)}/controlplanes/${encodeURIComponent(
        controlPlaneName,
      )}?fieldManager=kubectl-patch`,
      {
        spec: options,
      },
      { headers: { 'Content-Type': 'application/merge-patch+json' } },
    )
    .then(() => undefined);
}

export const CONTROL_PLANE_CLASS_DEV = 'small';
export const CONTROL_PLANE_TTL_ANNOTATION = 'internal.spaces.upbound.io/ttl-delete-at';

export const SpaceGroupControlPlane = z.object({
  metadata: z.object({
    name: z.string(),
    namespace: z.string(),
    creationTimestamp: z.string(),
    deletionTimestamp: z.string().optional(),
    annotations: z
      .object({ [CONTROL_PLANE_TTL_ANNOTATION]: z.string().optional() })
      .passthrough()
      .optional(),
  }),
  spec: z.object({
    class: z.string().or(z.literal(CONTROL_PLANE_CLASS_DEV)).optional(),
    crossplane: z.object({
      version: z.string().optional(),
      autoUpgrade: z.object({ channel: z.nativeEnum(ControlPlaneUpgradeChannel).optional() }).optional(),
      state: z.enum(['Running', 'Paused']).optional(),
    }),
  }),
  status: z
    .object({
      conditions: z
        .object({
          lastTransitionTime: z.string(),
          type: z.string(),
          status: z.string(),
          reason: z.string(),
          message: z.string().optional(),
        })
        .array()
        .optional(),
    })
    .optional(),
});
export type SpaceGroupControlPlane = z.infer<typeof SpaceGroupControlPlane>;

export async function getControlPlane(orgName: string, spaceName: string, groupName: string, controlPlaneName: string) {
  const res = await api()
    .get<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/apis/spaces.upbound.io/v1beta1/namespaces/${encodeURIComponent(groupName)}/controlplanes/${controlPlaneName}`,
    )
    .then(r => r.data);

  return SpaceGroupControlPlane.parse(res);
}

const SpaceVersionResponse = z
  .object({ gitVersion: z.string() })
  .transform(d => d.gitVersion.match(/\+upbound-spaces-(.*)/)?.[1]);

export function getSpaceVersion(orgName: string, spaceName: string) {
  return api()
    .get<unknown>(`/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(spaceName)}/version`)
    .then(d => SpaceVersionResponse.parse(d.data));
}

const SpaceGroupControlPlaneListResponse = z.object({
  items: SpaceGroupControlPlane.array().optional(),
});

export async function getSpaceGroupControlPlanes(orgName: string, spaceName: string, groupName: string) {
  const cps = await api()
    .get<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/apis/spaces.upbound.io/v1beta1/namespaces/${encodeURIComponent(groupName)}/controlplanes`,
    )
    .then(r => r.data);

  return SpaceGroupControlPlaneListResponse.parse(cps).items || [];
}

const ControlPlaneGroupResponse = z.object({
  metadata: z.object({
    creationTimestamp: z.string(),
    deletionTimestamp: z.string().optional(),
    name: z.string(),
    uid: z.string(),
    labels: z.record(z.string()).optional(),
  }),
});

export type ControlPlaneGroup = z.infer<typeof ControlPlaneGroupResponse>;

export async function getControlPlaneGroup(orgName: string, spaceName: string, groupName: string) {
  const res = await api()
    .get<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(spaceName)}/api/v1/namespaces/${groupName}`,
    )
    .then(r => r.data);

  return ControlPlaneGroupResponse.parse(res);
}

const ControlPlaneGroupsResponse = z.object({
  items: z.array(ControlPlaneGroupResponse),
});

export type ControlPlaneGroupsResponse = z.infer<typeof ControlPlaneGroupsResponse>;

export async function getControlPlaneGroups(orgName: string, spaceName: string): Promise<ControlPlaneGroupsResponse> {
  const res = await api()
    .get<unknown>(`/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(spaceName)}/api/v1/namespaces`)
    .then(r => r.data);

  return ControlPlaneGroupsResponse.parse(res);
}

const SpaceResponse = z.object({
  metadata: z.object({
    name: z.string(),
    uid: z.string().optional(),
    creationTimestamp: z.string().nullable(),
  }),
  spec: z.object({
    mode: z.string(),
  }),
  status: z
    .object({
      fqdn: z.string().optional(),
      connection: z
        .object({
          status: z
            .string()
            .toUpperCase()
            .refine((status): status is SpaceConnectionStatus =>
              [
                SpaceConnectionStatus.Connected,
                SpaceConnectionStatus.Unknown,
                SpaceConnectionStatus.Unreachable,
              ].includes(status as SpaceConnectionStatus),
            )
            .optional(),
        })
        .optional(),
    })
    .optional(),
});

export type Space = z.infer<typeof SpaceResponse>;

export async function getSpace(orgName: string, spaceName: string) {
  const res = await api()
    .get<unknown>(`/apis/upbound.io/v1alpha1/namespaces/${orgName}/spaces/${spaceName}`)
    .then(r => r.data);

  return SpaceResponse.parse(res);
}

// SECRETS
const SharedSecretStoreResponse = z.object({
  apiVersion: z.string(),
  kind: z.string(),
  metadata: z.object({
    name: z.string(),
    namespace: z.string(),
    creationTimestamp: z.string(),
    uid: z.string(),
  }),
  spec: z
    .object({
      namespaceSelector: z.object({
        names: z.string().array().optional(),
        labelSelectors: z
          .array(
            z.object({
              matchLabels: z.record(z.string()).optional(),
            }),
          )
          .optional(),
      }),
      controlPlaneSelector: z.object({
        names: z.string().array().optional(),
        labelSelectors: z
          .array(
            z.object({
              matchLabels: z.record(z.string()).optional(),
            }),
          )
          .optional(),
      }),
      provider: z.object({
        // TODO: figure out better way to validate provider types
        fake: z.object({}).optional(),
        aws: z.object({}).optional(),
        azurekv: z.object({}).optional(),
        vault: z.object({}).optional(),
        gcpsm: z.object({}).optional(),
      }),
    })
    .optional(),
  status: z
    .object({
      provisioned: z
        .array(
          z.object({
            controlPlane: z.string(),
          }),
        )
        .optional(),
    })
    .optional(),
});

export async function getSharedSecretStore(orgName: string, spaceName: string, groupName: string, storeName: string) {
  const res = await api()
    .get<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/apis/spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(
        groupName,
      )}/sharedsecretstores/${encodeURIComponent(storeName)}`,
    )
    .then(r => r.data);

  return SharedSecretStoreResponse.parse(res);
}

export type SharedSecretStore = z.infer<typeof SharedSecretStoreResponse>;

const SharedSecretStoresResponse = z.object({
  apiVersion: z.string(),
  kind: z.string(),
  metadata: z.object({}),
  status: z.object({}).optional(),
  items: z.array(SharedSecretStoreResponse).optional(),
});

export async function getSharedSecretStores(orgName: string, spaceName: string, groupName: string) {
  const res = await api()
    .get<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/apis/spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(groupName)}/sharedsecretstores`,
    )
    .then(r => r.data);

  return SharedSecretStoresResponse.parse(res).items;
}

const SharedExternalSecretResponse = z.object({
  apiVersion: z.string(),
  kind: z.string(),
  metadata: z.object({
    name: z.string(),
    namespace: z.string(),
    creationTimestamp: z.string(),
    uid: z.string(),
  }),
  spec: z
    .object({
      namespaceSelector: z.object({
        names: z.string().array().optional(),
        labelSelectors: z
          .array(
            z.object({
              matchLabels: z.record(z.string()),
            }),
          )
          .optional(),
      }),
      controlPlaneSelector: z.object({
        names: z.string().array().optional(),
        labelSelectors: z
          .array(
            z.object({
              matchLabels: z.record(z.string()),
            }),
          )
          .optional(),
      }),
      externalSecretSpec: z
        .object({
          data: z
            .object({
              remoteRef: z.object({
                metadataPolicy: z.string().optional(),
                version: z.string().optional(),
                conversionStrategy: z.string().optional(),
                decodingStrategy: z.string().optional(),
                key: z.string(),
              }),
              secretKey: z.string(),
            })
            .array(),
          refreshInterval: z.string().optional(),
          secretStoreRef: z
            .object({
              name: z.string(),
              kind: z.string().optional(),
            })
            .optional(),
          target: z
            .object({
              creationPolicy: z.string().optional(),
              deletionPolicy: z.string().optional(),
            })
            .optional(),
        })
        .optional(),
    })
    .optional(),
  status: z
    .object({
      provisioned: z
        .array(
          z.object({
            controlPlane: z.string(),
          }),
        )
        .optional(),
    })
    .optional(),
});

export async function getSharedExternalSecret(
  orgName: string,
  spaceName: string,
  groupName: string,
  secretName: string,
) {
  const res = await api()
    .get<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/apis/spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(
        groupName,
      )}/sharedexternalsecrets/${secretName}`,
    )
    .then(r => r.data);

  return SharedExternalSecretResponse.parse(res);
}

const SharedExternalSecretsResponse = z.object({
  apiVersion: z.string(),
  kind: z.string(),
  metadata: z.object({
    resourceVersion: z.string(),
  }),
  status: z.object({}).optional(),
  items: z.array(SharedExternalSecretResponse).optional(),
});

export type SharedExternalSecret = z.infer<typeof SharedExternalSecretResponse>;

export async function getSharedExternalSecrets(orgName: string, spaceName: string, groupName: string) {
  const res = await api()
    .get<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/apis/spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(groupName)}/sharedexternalsecrets`,
    )
    .then(r => r.data);

  return SharedExternalSecretsResponse.parse(res);
}

const APISpecResponse = z.object({
  components: z.object({
    schemas: z.object({
      'io.upbound.spaces.v1alpha1.SharedSecretStore': z.object({
        properties: z.object({
          spec: z.any(),
        }),
      }),
      'io.upbound.spaces.v1alpha1.SharedExternalSecret': z.object({
        properties: z.object({
          spec: z.any(),
        }),
      }),
      'io.upbound.spaces.v1alpha1.SharedBackup': z.object({
        properties: z.object({
          spec: z.any(),
        }),
      }),
      'io.upbound.spaces.v1alpha1.SharedBackupConfig': z.object({
        properties: z.object({
          spec: z.any(),
        }),
      }),
      'io.upbound.spaces.v1alpha1.SharedBackupSchedule': z.object({
        properties: z.object({
          spec: z.any(),
        }),
      }),
    }),
  }),
});

export async function getAPISpec(orgName: string, spaceName: string) {
  const res = await api()
    .get<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/openapi/v3/apis/spaces.upbound.io/v1alpha1`,
    )
    .then(r => r.data);

  return APISpecResponse.parse(res);
}

// BACKUPS

const BackupJob = z.object({
  kind: z.string(),
  metadata: z.object({
    name: z.string(),
    namespace: z.string(),
    creationTimestamp: z.string(),
    generation: z.number(),
    resourceVersion: z.string(),
    labels: z.record(z.string()).optional(),
    uid: z.string(),
  }),
  spec: z.object({
    configRef: z.object({
      name: z.string(),
      kind: z.string(),
    }),
    controlPlane: z.string(),
    deletionPolicy: z.string(),
  }),
  status: z.object({
    conditions: z
      .array(
        z.object({
          lastTransitionTime: z.string(),
          type: z.string(),
          status: z.string(),
          reason: z.string(),
          message: z.string().optional(),
        }),
      )
      .optional(),
    phase: z.nativeEnum(PodPhaseEnum).optional(),
  }),
});

export type BackupJob = z.infer<typeof BackupJob>;

export async function getBackupJob(orgName: string, spaceName: string, groupName: string, backupJobName: string) {
  const url = `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
    spaceName,
  )}/apis/spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(groupName)}/backups/${encodeURIComponent(
    backupJobName,
  )}`;

  const res = await api()
    .get<unknown>(url)
    .then(r => r.data);

  return BackupJob.parse(res);
}

const BackupJobsResponse = z.object({
  items: BackupJob.array(),
  metadata: ItemsMetadataResponse,
});

export type BackupJobsResponse = z.infer<typeof BackupJobsResponse>;

export async function getBackupJobs(
  orgName: string,
  spaceName: string,
  groupName: string,
  limit: number = 16,
  cursor?: string,
) {
  const searchParams = new URLSearchParams();

  if (limit) {
    searchParams.append('limit', limit.toString());
  }
  if (cursor) {
    searchParams.append('continue', cursor);
  }
  const params = searchParams.toString();
  const query = params ? `?${params}` : '';

  const url = `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
    spaceName,
  )}/apis/spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(groupName)}/backups${query}`;

  const res = await api()
    .get<unknown>(url)
    .then(r => r.data);

  return BackupJobsResponse.parse(res);
}

const SharedBackupSchedule = z.object({
  kind: z.string(),
  metadata: z.object({
    name: z.string(),
    namespace: z.string(),
    creationTimestamp: z.string(),
    generation: z.number(),
    resourceVersion: z.string(),
    uid: z.string(),
  }),
  spec: z.object({
    configRef: z.object({
      name: z.string(),
      kind: z.string(),
    }),
    controlPlaneSelector: z.object({
      labelSelectors: z
        .array(
          z.object({
            matchLabels: z.record(z.string()),
          }),
        )
        .optional(),
      names: z.array(z.string()).optional(),
    }),
    schedule: z.string(),
  }),
  status: z.object({
    selectedControlPlanes: z.array(z.string()).optional(),
    phase: z.nativeEnum(PodPhaseEnum).optional(),
  }),
});

export type SharedBackupSchedule = z.infer<typeof SharedBackupSchedule>;

const SharedBackupScheduleListResponse = z.object({
  items: SharedBackupSchedule.array(),
});

const SharedBackupScheduleResponse = SharedBackupSchedule;

export async function getSharedBackupSchedule(
  orgName: string,
  spaceName: string,
  groupName: string,
  scheduleName: string,
) {
  const namespaceName = encodeURIComponent(groupName);

  const url = `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
    spaceName,
  )}/apis/spaces.upbound.io/v1alpha1/namespaces/${namespaceName}/sharedbackupschedules/${scheduleName}`;

  const res = await api()
    .get<unknown>(url)
    .then(r => r.data);

  return SharedBackupScheduleResponse.parse(res);
}

export async function getSharedBackupSchedules(orgName: string, spaceName: string, groupName: string) {
  const namespaceName = encodeURIComponent(groupName);

  const url = `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
    spaceName,
  )}/apis/spaces.upbound.io/v1alpha1/namespaces/${namespaceName}/sharedbackupschedules`;

  const res = await api()
    .get<unknown>(url)
    .then(r => r.data);

  // TODO: This is a temporary workaround until the API serves the correct data.
  return SharedBackupScheduleListResponse.parse(res);
}

const SharedBackupConfig = z.object({
  kind: z.string(),
  metadata: z.object({
    name: z.string(),
    namespace: z.string(),
    creationTimestamp: z.string(),
    generation: z.number(),
    resourceVersion: z.string(),
    uid: z.string(),
  }),
  spec: z.object({
    objectStorage: z.object({
      bucket: z.string(),
      config: z.record(z.unknown()).optional(),
      credentials: z.object({
        source: z.string(),
      }),
      provider: z.string(),
    }),
  }),
  status: z.object({}).optional(),
});

export type SharedBackupConfig = z.infer<typeof SharedBackupConfig>;

const SharedBackupConfigResponse = SharedBackupConfig;

export const getSharedBackupConfig = async (
  orgName: string,
  spaceName: string,
  groupName: string,
  configName: string,
) => {
  const namespaceName = encodeURIComponent(groupName);

  const url = `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
    spaceName,
  )}/apis/spaces.upbound.io/v1alpha1/namespaces/${namespaceName}/sharedbackupconfigs/${configName}`;

  const res = await api()
    .get<unknown>(url)
    .then(r => r.data);

  return SharedBackupConfigResponse.parse(res);
};

const SharedBackupConfigListResponse = z.object({
  items: SharedBackupConfig.array(),
});

export async function getSharedBackupConfigs(orgName: string, spaceName: string, groupName: string) {
  const namespaceName = encodeURIComponent(groupName);

  const url = `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
    spaceName,
  )}/apis/spaces.upbound.io/v1alpha1/namespaces/${namespaceName}/sharedbackupconfigs`;

  const res = await api()
    .get<unknown>(url)
    .then(r => r.data);

  return SharedBackupConfigListResponse.parse(res);
}

export type CreateSharedBackupConfigOptions = {
  name: string;
  objectStorage: any;
};

export async function createSharedBackupConfig(
  orgName: string,
  spaceName: string,
  groupName: string,
  options: CreateSharedBackupConfigOptions,
) {
  const namespaceName = encodeURIComponent(groupName);

  const { name, objectStorage } = options;

  return api().post(
    `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
      spaceName,
    )}/apis/spaces.upbound.io/v1alpha1/namespaces/${namespaceName}/sharedbackupconfigs`,
    {
      apiVersion: 'spaces.upbound.io/v1alpha1',
      kind: 'SharedBackupConfig',
      metadata: {
        name,
        namespace: namespaceName,
      },
      spec: {
        objectStorage,
      },
    },
  );
}

export type CreateSharedSecretStoreOptions = {
  sharedSecretStoreName: string;
  controlPlaneSelector: {
    names: string[];
  };
  namespaceSelector: {
    names: string[];
  };
  providerName: string;
  providerSpec: any;
};

export function createSharedSecretStore(
  orgName: string,
  spaceName: string,
  groupName: string,
  options: CreateSharedSecretStoreOptions,
): Promise<void> {
  const namespaceName = encodeURIComponent(groupName);

  const { sharedSecretStoreName, controlPlaneSelector, namespaceSelector, providerName, providerSpec } = options;

  return api().post(
    `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
      spaceName,
    )}/apis/spaces.upbound.io/v1alpha1/namespaces/${namespaceName}/sharedsecretstores`,
    {
      apiVersion: 'spaces.upbound.io/v1alpha1',
      kind: 'SharedSecretStore',
      metadata: {
        name: sharedSecretStoreName,
      },
      spec: {
        controlPlaneSelector,
        namespaceSelector,
        provider: {
          [providerName]: providerSpec,
        },
      },
    },
  );
}

export type CreateSharedExternalSecretOptions = {
  sharedExternalSecretName: string;
  controlPlaneSelector: {
    names: string[];
  };
  namespaceSelector: {
    names: string[];
  };
  externalSecretSpec: any;
};

export function createSharedExternalSecret(
  orgName: string,
  spaceName: string,
  groupName: string,
  options: CreateSharedExternalSecretOptions,
): Promise<void> {
  const namespaceName = encodeURIComponent(groupName);

  const { sharedExternalSecretName, controlPlaneSelector, namespaceSelector, externalSecretSpec } = options;
  return api().post(
    `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
      spaceName,
    )}/apis/spaces.upbound.io/v1alpha1/namespaces/${namespaceName}/sharedexternalsecrets`,
    {
      apiVersion: 'spaces.upbound.io/v1alpha1',
      kind: 'SharedExternalSecret',
      metadata: {
        name: sharedExternalSecretName,
      },
      spec: {
        controlPlaneSelector,
        namespaceSelector,
        externalSecretSpec,
      },
    },
  );
}

/** ControlPlaneGroupLabelKey is the key used to identify namespaces as groups. The
 * value will be "true".
 * From: https://github.com/upbound/up-sdk-go/blob/b5577c9fc9d008861a38c327b7e1e58c1c58c2a9/apis/spaces/v1beta1/controlplane_types.go#L40
 */
export const ControlPlaneGroupLabelKey = 'spaces.upbound.io/group';

/** ControlPlaneGroupProtectionKey is the key used to prevent deletion of groups
 * via the Spaces API. Deletion will not be protected if the underlying namespace
 * is deleted.
 * From: https://github.com/upbound/up-sdk-go/blob/b5577c9fc9d008861a38c327b7e1e58c1c58c2a9/apis/spaces/v1beta1/controlplane_types.go#L44
 */
export const ControlPlaneGroupProtectionKey = 'spaces.upbound.io/group-deletion-protection';

export async function createControlPlaneGroup(orgName: string, spaceName: string, groupName: string) {
  return api().post(`/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(spaceName)}/api/v1/namespaces`, {
    metadata: {
      name: groupName,
    },
  });
}

export async function deleteControlPlaneGroup(orgName: string, spaceName: string, groupName: string) {
  return api().delete(
    `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(spaceName)}/api/v1/namespaces/${encodeURIComponent(
      groupName,
    )}`,
  );
}

const DependencyResponse = z.object({
  constraints: z.string(),
  package: z.string(),
  type: z.string(),
});

const PackageResponse = z.object({
  dependencies: z.array(DependencyResponse),
  name: z.string(),
  source: z.string(),
  type: z.string(),
  version: z.string(),
});

const ControlPlanePackagesResponse = z.object({
  kind: z.string(),
  metadata: z.object({ name: z.string() }),
  packages: z.array(PackageResponse),
});

// Control Plane Lock contains list of packages installed on a CP, along with each package's dependencies
export async function getControlPlanePackages(
  orgName: string,
  spaceName: string,
  groupName: string,
  controlPlaneName: string,
) {
  const res = await api()
    .get<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/apis/spaces.upbound.io/v1beta1/namespaces/${encodeURIComponent(
        groupName,
      )}/controlplanes/${controlPlaneName}/k8s/apis/pkg.crossplane.io/v1beta1/locks/lock`,
    )
    .then(r => r.data);

  return ControlPlanePackagesResponse.parse(res).packages;
}

export async function getLegacyControlPlanePackages(orgName: string, controlPlaneName: string) {
  const res = await proxyApi()
    .get<unknown>(`v1/controlPlanes/${orgName}/${controlPlaneName}/k8s/apis/pkg.crossplane.io/v1beta1/locks/lock`)
    .then(r => r.data);

  return ControlPlanePackagesResponse.parse(res).packages;
}

const ControlPlaneCompositionResponse = z
  .object({
    apiVersion: z.string(),
    kind: z.literal('Composition'),
    metadata: z.object({ name: z.string(), creationTimestamp: z.string(), uid: z.string() }).passthrough(),
    spec: z
      .object({ compositeTypeRef: z.object({ apiVersion: z.string(), kind: z.string() }) })
      .passthrough()
      .optional(),
  })
  .passthrough();

export type ControlPlaneCompositionResponse = z.infer<typeof ControlPlaneCompositionResponse>;

export async function getControlPlaneComposition(
  orgName: string,
  spaceName: string,
  groupName: string,
  controlPlaneName: string,
  compositionName: string,
) {
  const res = await api()
    .get<unknown>(
      `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
        spaceName,
      )}/apis/spaces.upbound.io/v1beta1/namespaces/${encodeURIComponent(
        groupName,
      )}/controlplanes/${controlPlaneName}/k8s/apis/apiextensions.crossplane.io/v1/compositions/${compositionName}`,
    )
    .then(r => r.data);

  return ControlPlaneCompositionResponse.parse(res);
}

export async function getLegacyControlPlaneComposition(
  orgName: string,
  controlPlaneName: string,
  compositionName: string,
) {
  const res = await proxyApi()
    .get<unknown>(
      // eslint-disable-next-line max-len
      `v1/controlPlanes/${orgName}/${controlPlaneName}/k8s/apis/apiextensions.crossplane.io/v1/compositions/${compositionName}`,
    )
    .then(r => r.data);

  return ControlPlaneCompositionResponse.parse(res);
}

// Found info about structure from: https://github.com/kubernetes/enhancements/blob/master/keps/sig-api-machinery/3352-aggregated-discovery/README.md
const AggregatedDiscoveryResponse = z.object({
  kind: z.literal('APIGroupDiscoveryList'),
  items: z
    .array(
      z.object({
        versions: z
          .array(
            z.object({
              freshness: z.enum(['Current', 'Stale']),
              resources: z
                .array(
                  z.object({
                    categories: z.array(z.string()).optional(),
                    scope: z.enum(['Cluster', 'Namespaced']),
                    responseKind: z.object({
                      group: z.string(),
                      kind: z.string(),
                    }),
                  }),
                )
                .optional(),
            }),
          )
          .optional(),
      }),
    )
    .optional(),
});

export async function getAggregatedDiscovery(
  orgName: string,
  spaceName: string,
  groupName: string,
  controlPlaneName: string,
) {
  const res = await api().get<unknown>(
    `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
      spaceName,
    )}/apis/spaces.upbound.io/v1beta1/namespaces/${encodeURIComponent(
      groupName,
    )}/controlplanes/${controlPlaneName}/k8s/apis`,
    { headers: { Accept: 'application/json;v=v2beta1;g=apidiscovery.k8s.io;as=APIGroupDiscoveryList' } },
  );

  return AggregatedDiscoveryResponse.parse(res.data);
}

const ConfigMapResponse = z.object({
  kind: z.literal('ConfigMap'),
  data: z.object({
    versions: z.string(),
  }),
});

/** return a sorted list of supported crossplane (UXP) versions */
export async function getSupportedUXPVersions(orgName: string, spaceName: string) {
  const res = await api().get<unknown>(
    `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
      spaceName,
    )}/api/v1/namespaces/upbound-system/configmaps/crossplane-versions-public`,
  );

  const versionsYaml = ConfigMapResponse.parse(res.data).data.versions;

  try {
    const versions = z.string().array().parse(jsyaml.load(versionsYaml));

    return versions.sort((a, b) => semver.compare(b, a));
  } catch (e) {
    console.error('invalid supported versions yaml', e);
    throw e;
  }
}
