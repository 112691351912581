import { getPortalHost } from '@/utils/config';

import { generatePath } from '.';

export const externalPortalLink = (orgName: string, spaceName: string, groupName: string, controlPlaneName: string) => {
  return `https://${orgName}.${getPortalHost()}${portal.url(spaceName, groupName, controlPlaneName)}`;
};

export const portal = {
  url: (spaceName: string, groupName: string, controlPlaneName: string) =>
    generatePath(portal.route, { spaceName, groupName, controlPlaneName }),
  route: '/spaces/:spaceName/groups/:groupName/controlPlanes/:controlPlaneName' as const,
};

export const claim = {
  url: (spaceName: string, groupName: string, controlPlaneName: string, crdId: string) =>
    generatePath(claim.route, { spaceName, groupName, controlPlaneName, crdId }),
  route: '/spaces/:spaceName/groups/:groupName/controlPlanes/:controlPlaneName/definitions/:crdId' as const,
};

export const claimInstance = {
  url: (spaceName: string, groupName: string, controlPlaneName: string, crdId: string, id: string) =>
    generatePath(claimInstance.route, { spaceName, groupName, controlPlaneName, crdId, id }),
  route:
    '/spaces/:spaceName/groups/:groupName/controlPlanes/:controlPlaneName/definitions/:crdId/instances/:id' as const,
};

export const claimCreate = {
  url: (spaceName: string, groupName: string, controlPlaneName: string, crdId: string, path?: string) =>
    generatePath(claimCreate.route, { spaceName, groupName, controlPlaneName, crdId }, path ? { path } : undefined),
  route: '/spaces/:spaceName/groups/:groupName/controlPlanes/:controlPlaneName/definitions/:crdId/create' as const,
};

export const claimEdit = {
  url: (spaceName: string, groupName: string, controlPlaneName: string, crdId: string, id: string, path?: string) =>
    generatePath(claimEdit.route, { spaceName, groupName, controlPlaneName, crdId, id }, path ? { path } : undefined),
  route:
    '/spaces/:spaceName/groups/:groupName/controlPlanes/:controlPlaneName/definitions/:crdId/instances/:id/edit' as const,
};
