import { ClassNames, css } from '@emotion/react';
import styled from '@emotion/styled';
import NextLink, { LinkProps } from 'next/link';
import React from 'react';
import * as styledSystem from 'styled-system';
import { fontAvenir, fontAvenirBold } from 'upbound-frontend-constants';

import { COLORS, shouldForwardProp } from '@/constants/styles';

const baseStyle = css`
  ${fontAvenir};
  font-size: 14px;
  cursor: pointer;
  color: inherit;
  transition: all 0.2s ease-in-out;

  &:visited {
    color: inherit;
  }
`;

type Hover = 'underline' | 'opacity' | 'none';

export interface AnchorProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement>,
    styledSystem.ColorProps,
    styledSystem.LayoutProps,
    styledSystem.SpaceProps,
    styledSystem.PositionProps,
    styledSystem.TypographyProps {
  color?: keyof typeof COLORS;
  bold?: boolean;
  underline?: boolean;
  hover?: Hover;
}

const baseStyledSystem = styledSystem.compose(
  styledSystem.color,
  styledSystem.layout,
  styledSystem.space,
  styledSystem.typography,
  styledSystem.position,
);

const hoverUnderlineStyle = css`
  &:hover {
    text-decoration: underline;
  }
`;

const hoverOpacityStyle = css`
  &:hover {
    opacity: 0.7;
  }
`;

const hoverNoneStyle = css`
  &:hover {
    text-decoration: none;
  }
`;

const hoverStyle = (hover: Hover) => {
  switch (hover) {
    case 'underline': {
      return hoverUnderlineStyle;
    }
    case 'opacity': {
      return hoverOpacityStyle;
    }
    case 'none':
    default: {
      return hoverNoneStyle;
    }
  }
};

const StyledAnchor = styled('a', { shouldForwardProp })<{ otherStyles: string; hover?: Hover }>`
  ${baseStyle}
  ${({ otherStyles }) => otherStyles}
  ${({ hover = 'none' }) => hoverStyle(hover)}
  ${baseStyledSystem}
`;

export const Anchor = React.forwardRef<
  HTMLAnchorElement,
  Omit<AnchorProps, 'css'> & Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'css'>
>(({ children, bold, underline, ...props }, ref) => {
  const fontStyle = bold ? fontAvenirBold : fontAvenir;
  const underlineStyle = underline ? css({ textDecoration: 'underline' }) : '';
  const otherStyles = [fontStyle, underlineStyle];

  return (
    <ClassNames>
      {({ css: classNamesCss }) => (
        <StyledAnchor otherStyles={classNamesCss(otherStyles)} {...props} ref={ref}>
          {children}
        </StyledAnchor>
      )}
    </ClassNames>
  );
});

Anchor.displayName = 'Anchor';

const StyledLink = styled(NextLink, { shouldForwardProp })<{ otherStyles: string; hover?: Hover }>`
  ${baseStyle}
  ${({ otherStyles }) => otherStyles}
  ${({ hover = 'none' }) => hoverStyle(hover)}
  ${baseStyledSystem}
`;

export const Link = React.forwardRef<HTMLAnchorElement, Omit<AnchorProps, 'css'> & LinkProps>(
  ({ children, bold, ...props }, ref) => {
    const fontStyle = bold ? fontAvenirBold : fontAvenir;
    const otherStyles = [fontStyle];

    return (
      <ClassNames>
        {({ css: classNamesCss }) => (
          <StyledLink otherStyles={classNamesCss(otherStyles)} {...props} ref={ref}>
            {children}
          </StyledLink>
        )}
      </ClassNames>
    );
  },
);

Link.displayName = 'Link';
