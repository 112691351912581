import Link from 'next/link';
import { COLORS, SHADES } from 'packages/constants';
import styled from 'styled-components';

export const TabLinkStyled = styled(Link)`
  display: flex;
  outline: none;
  cursor: pointer;
  padding: 10px 0 15px;

  h5,
  h6 {
    color: ${COLORS.Neutral[800]};
  }

  & + & {
    margin-left: 40px;
  }

  &.selected,
  &:hover {
    border-bottom: 1px solid ${COLORS.Purple.Primary};
    h5,
    h6 {
      color: ${COLORS.Purple.Primary};
    }
  }

  h5,
  h6 {
    &:hover {
      color: ${COLORS.Purple.Primary};
    }
  }
`;

export const TabListStyled = styled.div`
  display: flex;
`;

export const TabPanelStyled = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: ${SHADES.Neutral[50]};
  overflow: hidden;
`;

export const TabsStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 0;
  align-self: stretch;
`;
