import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { z } from 'zod';

import { Space, SpaceRegion, SpaceType, useGetSpacesNavQuery } from '@/generated/upbound-graphql';
import { ControlPlaneGroup } from '@/services/spacesApi.service';
import { getSpaceGroupsQuery } from '@/services/spacesQueries';

import { useGetCurrentAccount } from '../graphql/reactiveVars';
import { isAxiosError } from './axios';
import { getDefaultDevMCPSpace } from './config';
import { capitalize } from './strings';

const typePriority = [SpaceType.Legacy, SpaceType.Managed, SpaceType.Connected];
const DEFAULT_DEV_MCP_SPACE_NAME = getDefaultDevMCPSpace();

export const useGetDefaultGroupName = (
  account: string,
  spaceName?: string,
  spaceType?: SpaceType,
): {
  loading: boolean;
  groupName?: string;
  error?: Error;
} => {
  const { data: groups, isLoading, error } = useQuery(getSpaceGroupsQuery(account, spaceName, spaceType));

  const defaultGroup: ControlPlaneGroup | null = useMemo(() => {
    const dGroup = groups?.find(group => group.metadata.name === 'default');
    return dGroup || groups?.[0] || null;
  }, [groups]);

  if (isLoading) {
    return { loading: true };
  }

  if (defaultGroup) {
    return {
      loading: false,
      groupName: defaultGroup.metadata.name,
      error: error || undefined,
    };
  }

  return {
    loading: false,
    error: error || undefined,
  };
};

export const useGetDefaultSpaceAndGroupName = (): {
  loading: boolean;
  spaceName?: string;
  groupName?: string;
  spaceType?: SpaceType;
} => {
  const currentAccount = useGetCurrentAccount();
  const { data, loading: isSpacesLoading } = useGetSpacesNavQuery({
    variables: {
      account: currentAccount.id,
    },
  });

  const spaces = useMemo(
    () =>
      (data?.account?.spaces || [])
        .filter((space): space is Space => space.__typename === 'Space')
        .filter(space => {
          const isLimitedTier = ['free', 'team'].includes(currentAccount.organization.tier);

          if (isLimitedTier) {
            return space.name === DEFAULT_DEV_MCP_SPACE_NAME || null;
          }
          return true;
        })
        .sort((a, b) => typePriority.indexOf(a.type) - typePriority.indexOf(b.type)),
    [data, currentAccount],
  );
  const defaultSpace: Space | null = useMemo(() => {
    return spaces?.[0] || null;
  }, [spaces]);

  const { loading: isGroupsLoading, groupName } = useGetDefaultGroupName(
    currentAccount.id,
    defaultSpace?.name || '',
    defaultSpace?.type || SpaceType.Legacy,
  );

  const loading = isSpacesLoading || isGroupsLoading;

  if (loading) {
    return { loading };
  }

  if (defaultSpace && groupName) {
    return {
      loading: false,
      spaceName: defaultSpace.name,
      groupName,
      spaceType: defaultSpace.type,
    };
  }

  if (defaultSpace) {
    return {
      loading: false,
      spaceName: defaultSpace.name,
      spaceType: defaultSpace.type,
    };
  }

  return {
    loading: false,
  };
};

export const formatRegion = (region?: SpaceRegion) => {
  if (!region) {
    return '';
  }
  const [area, direction, index] = region.split('_', 3);
  return `${area.toLocaleUpperCase()}-${capitalize(direction.toLocaleLowerCase())}-${index}`;
};

const ErrorStatus = z.object({
  kind: z.literal('Status'),
  details: z.object({
    causes: z.array(
      z.object({
        message: z.string(),
      }),
    ),
  }),
});

// There is no a direct way to check if the space struggles with connection issues.
// The common root cause for this is that the space has not been detached via CLI.
export const isSpaceUnreachable = (error?: Error) => {
  if (!error || !isAxiosError(error)) {
    return false;
  }
  const result = ErrorStatus.safeParse(error.response?.data);

  // success means the error is a status error response
  if (result.success) {
    // to check if the error is a detached space error we check if the error message contains the following string
    return result.data.details.causes.some(cause => cause.message.includes('no agents listening for messages'));
  }
  return false;
};
