import styled from '@emotion/styled';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { shouldForwardProp } from 'packages/constants';
import * as styledSystem from 'styled-system';

interface SpanIconProps
  extends styledSystem.PaddingProps,
    styledSystem.MarginProps,
    styledSystem.PositionProps,
    styledSystem.TypographyProps {}

const baseStyledSystem = styledSystem.compose(
  styledSystem.flexbox,
  styledSystem.display,
  styledSystem.layout,
  styledSystem.position,
  styledSystem.padding,
  styledSystem.space,
  styledSystem.typography,
);

type Speed = 'slow' | 'fast';

const StyledSpanIcon = styled('span', { shouldForwardProp })<SpanIconProps>`
  font-size: 1em;
  width: 1em;
  ${baseStyledSystem};
`;

export type IconProps = Omit<
  SpanIconProps & {
    className?: string;
    speed?: Speed;
  } & Pick<FontAwesomeIconProps, 'icon' | 'color' | 'spin'>,
  'css'
>;

const Icon: React.FC<
  IconProps & {
    onClick?: (e: React.MouseEvent) => void;
  }
> = ({ icon, color, spin, speed, ...props }) => {
  const style: any = {
    maxHeight: '100%',
    maxWidth: '100%',
  };

  if (speed !== undefined) {
    style.animationDuration = speed === 'slow' ? '1000ms' : '500ms';
  }

  return (
    <StyledSpanIcon {...props}>
      <FontAwesomeIcon icon={icon} color={color} spin={spin} style={style} />
    </StyledSpanIcon>
  );
};

export { Icon };
