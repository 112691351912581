import { flexRender, HeaderGroup, Row, SortDirection } from '@tanstack/react-table';
import Link from 'next/link';
import { COLORS } from 'packages/constants';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { twMerge } from 'tailwind-merge';
import { COLORS as SHARED_COLORS } from 'upbound-frontend-constants';
import {
  Cell,
  EmptyResultsContainer,
  faArrowDown,
  faArrowUp,
  Icon,
  Paragraph,
  TableHeader,
  TableRow,
} from 'upbound-frontend-elements';

type ColumnMeta = {
  textAlign: 'left' | 'center' | 'right';
};

export const TableCell: React.FC<{
  content: string;
  title?: string;
  className?: string;
  href?: string;
}> = ({ content, title, className, href }) => {
  const baseClassName = twMerge(
    // underline with dashed
    className,
    'text-ellipsis overflow-hidden whitespace-nowrap',
  );

  // TODO: seperate this into a new component once we have more complex parameters
  if (href) {
    return (
      <Paragraph className={baseClassName}>
        <Link href={href} title={title} className="underline hover:no-underline">
          {content}
        </Link>
      </Paragraph>
    );
  }

  return (
    <Paragraph className={baseClassName}>
      {content ? (
        <span className={title ? 'underline decoration-dashed hover:no-underline' : ''} title={title || content}>
          {content}
        </span>
      ) : (
        <span>&mdash;</span>
      )}
    </Paragraph>
  );
};

export const TData: React.FC<{ cell: Cell; className?: string }> = ({ cell, className }) => {
  return (
    <td
      style={{
        textAlign: (cell.column.columnDef.meta as ColumnMeta)?.textAlign ?? 'left',
        width: cell.column.columnDef.size,
        minWidth: cell.column.columnDef.minSize,
        maxWidth: cell.column.columnDef.maxSize,
      }}
      className={className}
    >
      {flexRender(cell.column.columnDef.cell, cell.getContext())}
    </td>
  );
};

export const TableSortIcon: React.FC<{ sortOrder: SortDirection | false }> = ({ sortOrder }) => {
  if (!sortOrder) {
    return null;
  }

  return (
    <Icon icon={sortOrder === 'asc' ? faArrowUp : faArrowDown} ml="5px" color={COLORS.Purple[600]} fontSize="12px" />
  );
};

export const HeaderSpan = styled('span')<{ isSorted: boolean }>`
  color: ${props => (props.isSorted ? SHARED_COLORS.Purple[600] : SHARED_COLORS.Neutral[600])};
  &:hover {
    cursor: pointer;
  }
`;

const StickyTableHead = styled('thead')<{ top?: number }>`
  tr {
    background-color: white;
    position: sticky;
    z-index: 49;
    top: ${props => props.top}px;
  }
`;

const THead: React.FC<
  {
    sticky: boolean;
    top?: number;
  } & PropsWithChildren
> = ({ sticky = false, children, top = 60 }) => {
  if (sticky) {
    return <StickyTableHead top={top}>{children}</StickyTableHead>;
  }

  return <thead>{children}</thead>;
};

export const ReactTableHeader: React.FC<
  React.PropsWithChildren & { headerGroups: HeaderGroup<any>[]; sticky?: boolean; top?: number }
> = ({ headerGroups, sticky = false, top, children }) => {
  if (children) {
    return <tbody>{children}</tbody>;
  }
  return (
    <THead sticky={sticky} top={top}>
      {headerGroups.map(headerGroup => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map(header => (
            <TableHeader
              key={header.id}
              style={{
                textAlign:
                  (
                    header.column.columnDef.meta as {
                      textAlign?: 'left' | 'right' | 'center';
                    }
                  )?.textAlign ?? 'left',
                minWidth: header.column.columnDef.minSize,
              }}
            >
              <HeaderSpan onClick={header.column.getToggleSortingHandler()} isSorted={!!header.column.getIsSorted()}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </HeaderSpan>
              {<TableSortIcon sortOrder={header.column.getIsSorted()} />}
            </TableHeader>
          ))}
        </tr>
      ))}
    </THead>
  );
};

export const ReactTableBody: React.FC<
  React.PropsWithChildren & {
    tableRows: Row<any>[];
    emptyResultsMsg: string;
    // TODO: remove emptyColSpan prop and calculate it based on the number of columns
    emptyColSpan?: number;
    className?: string;
    isLoading?: boolean;
    loadingColumns?: string[];
    loadingRows?: string[];
  }
> = ({
  children,
  tableRows,
  emptyResultsMsg,
  emptyColSpan = 4,
  className,
  isLoading = false,
  loadingColumns = [],
  loadingRows = Array.from({ length: 10 }),
}) => {
  if (isLoading && !!loadingColumns.length) {
    return (
      <tbody className={className}>
        {loadingRows.map((_, index) => (
          <TableRow key={`skeleton-${index}`}>
            {loadingColumns.map(columnId => {
              return (
                <td key={columnId} className="p-[12px]">
                  <span className="animate-pulse rounded-md bg-neutral-shades-75 h-[16px] w-full inline-block" />
                </td>
              );
            })}
          </TableRow>
        ))}
      </tbody>
    );
  }

  if (!tableRows.length) {
    return (
      <tbody className={className}>
        <tr>
          <td colSpan={emptyColSpan}>
            <EmptyResultsContainer>{emptyResultsMsg || 'No results'}</EmptyResultsContainer>
          </td>
        </tr>
      </tbody>
    );
  }

  if (children) {
    return <tbody className={className}>{children}</tbody>;
  }

  return (
    <tbody className={className}>
      {tableRows.map(row => (
        <TableRow key={row.id}>
          {row.getVisibleCells().map(cell => (
            <TData key={cell.id} cell={cell} />
          ))}
        </TableRow>
      ))}
    </tbody>
  );
};
