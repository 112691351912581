import Image from 'next/image';
import { twMerge } from 'tailwind-merge';

import awsLogo from '@/assets/aws.svg';
import googleLogo from '@/assets/gcp.svg';
import { Space, SpaceProvider } from '@/generated/upbound-graphql';

const getProviderLogo = (provider: SpaceProvider) => {
  switch (provider) {
    case SpaceProvider.Aws:
      return awsLogo;
    case SpaceProvider.Gcp:
    case SpaceProvider.Unknown:
    default:
      return googleLogo;
  }
};

export const SpaceIcon: React.FC<{
  space: Space | undefined;
  className?: string;
}> = ({ space, className }) => {
  const firstLetter = (space?.name?.[0] || '').toUpperCase();

  const buttonClassName =
    'flex justify-center items-center w-5 rounded-lg bg-neutral-600 text-white font-bold text-xs aspect-square';

  if (!space || !space.provider) {
    return <div className={twMerge(buttonClassName, className)}>{firstLetter}</div>;
  }

  const providerLogoClassName = 'bg-neutral-0 border-2 border-solid border-neutral-400';

  return (
    <div className={twMerge(buttonClassName, providerLogoClassName, className)}>
      <Image className="p-1" alt={space.name} src={getProviderLogo(space.provider)} />
    </div>
  );
};

export const GroupIcon: React.FC<{
  name: string;
  className?: string;
}> = ({ name, className }) => {
  const firstLetter = (name[0] || '').toUpperCase();

  const buttonClassName =
    'flex justify-center items-center w-5 rounded-lg bg-neutral-600 text-white font-bold text-xs aspect-square';

  return <div className={twMerge(buttonClassName, className)}>{firstLetter}</div>;
};
