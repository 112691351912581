import { ClassNames, css } from '@emotion/react';
import styled from '@emotion/styled';
import { LinkProps } from 'next/link';
import React from 'react';
import * as styledSystem from 'styled-system';
import { COLORS as SHARED_COLORS, fontAvenir, fontAvenirBold } from 'upbound-frontend-constants';

import { LoadingSpinner } from '@/components/LoadingSpinner';
import { MQ } from '@/constants/styledTheme';
import { COLORS, shouldForwardProp } from '@/constants/styles';
import { Flex } from '@/elements/Div';

import { AnchorProps, Link } from './Anchor';

const baseStyledSystem = styledSystem.compose(
  styledSystem.color,
  styledSystem.layout,
  styledSystem.space,
  styledSystem.typography,
  styledSystem.alignItems,
  styledSystem.justifyContent,
  styledSystem.display,
  styledSystem.position,
);

const baseStyle = css`
  background-color: ${COLORS.white};
  border-radius: 20px;
  border-style: solid;
  border-color: ${SHARED_COLORS.Purple.Primary};
  border-width: 1px;
  box-sizing: border-box;
  cursor: pointer;
  font-size: 14px;
  line-height: 20px;
  outline: none;
  overflow: hidden;
  padding: 0 20px;
  text-align: center;
  text-overflow: ellipsis;
  transition: all 0.2s ease-in-out;
  transition: background-color 0.1 ease-out;
  white-space: nowrap;
  width: auto;

  &:disabled {
    cursor: not-allowed;
  }
`;

const irisFill = css`
  background-color: ${COLORS.iris};
  color: ${COLORS.white};
  fill: ${COLORS.white};

  &:visited {
    color: ${COLORS.white};
    fill: ${COLORS.white};
  }

  &:disabled {
    background-color: ${COLORS.fadedIris};
    border-color: ${COLORS.fadedIris};
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${COLORS.blueberry};
    border-color: ${COLORS.blueberry};
  }
`;

const irisBoldFill = css`
  ${irisFill};
  ${fontAvenirBold};
`;

const irisOutline = css`
  background-color: ${COLORS.white};
  color: ${COLORS.iris};
  fill: ${COLORS.iris};

  &:visited {
    color: ${COLORS.iris};
    fill: ${COLORS.iris};
  }

  &:disabled {
    background-color: ${COLORS.fadedIris};
    border-color: ${COLORS.fadedIris};
    color: ${COLORS.white};
    fill: ${COLORS.white};
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${COLORS.iris};
    border-color: ${COLORS.iris};
    color: ${COLORS.white};
    fill: ${COLORS.white};
  }
`;

const paleGreyOutline = css`
  border-color: ${COLORS.paleBlue};
  color: ${COLORS.blueGrey};
  fill: ${COLORS.blueGrey};

  &:visited {
    color: ${COLORS.blueGrey};
    fill: ${COLORS.blueGrey};
  }

  &:disabled {
    color: ${COLORS.cloudyBlue};
    fill: ${COLORS.cloudyBlue};
    border-color: ${COLORS.paleGrey};
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${COLORS.paleGrey};
  }
`;

const paleBoldGreyOutline = css`
  ${paleGreyOutline}
  border-width: 2px;
  color: ${COLORS.fillBlackBlack};
  fill: ${COLORS.fillBlackBlack};
  ${fontAvenirBold}

  &:visited {
    color: ${COLORS.fillBlackBlack};
    fill: ${COLORS.fillBlackBlack};
  }
`;

const noOutline = css`
  border: none;
  display: inline-block;
  padding: 0;
  border-radius: 0;
  border-color: transparent;

  &:disabled {
    opacity: 0.5;
  }

  &:active:not(:disabled) {
    transform: none;
  }
`;

const noStyle = css`
  width: auto;
  height: unset;
  border-color: transparent;
  background-color: transparent;
  border: none;
  display: inline-block;
  padding: 0;
  border-radius: 0;
  color: ${COLORS.slate};
  fill: ${COLORS.slate};

  &:visited {
    color: ${COLORS.slate};
    fill: ${COLORS.slate};
  }

  &:disabled {
    opacity: 0.5;
  }

  &:active:not(:disabled) {
    transform: none;
  }
`;

const aquaGreenFill = css`
  background-color: ${COLORS.aquaGreen};
  border-color: ${COLORS.aquaGreen};
  border-width: 2px;
  color: ${COLORS.white};
  fill: ${COLORS.white};

  &:visited {
    color: ${COLORS.white};
    fill: ${COLORS.white};
  }

  &:hover:not(:disabled) {
    background-color: ${COLORS.aquaGreenDark};
    border-color: ${COLORS.aquaGreenDark};
  }
`;

const aquaMarineFill = css`
  background-color: ${COLORS.aquaMarine};
  border-color: transparent;
  border-width: 2px;
  color: ${COLORS.white};
  fill: ${COLORS.white};

  &:visited {
    color: ${COLORS.white};
    fill: ${COLORS.white};
  }

  &:hover:not(:disabled) {
    border-color: transparent;
    background-color: ${COLORS.aquarius};
    color: ${COLORS.white};
    fill: ${COLORS.white};
  }
`;

const cornflowerFill = css`
  background-color: ${COLORS.cornflower};
  border-color: ${COLORS.cornflower};
  border-width: 2px;
  color: ${COLORS.white};
  fill: ${COLORS.white};

  &:visited {
    color: ${COLORS.white};
    fill: ${COLORS.white};
  }

  &:hover:not(:disabled) {
    background-color: ${COLORS.bMedPurple};
    border-color: ${COLORS.bMedPurple};
    color: ${COLORS.white};
    fill: ${COLORS.white};
  }
`;

const blackOutline = css`
  background-color: transparent;
  border-color: ${COLORS.fillBlackBlack};
  border-width: 2px;
  color: ${COLORS.fillBlackBlack};
  fill: ${COLORS.fillBlackBlack};

  &:visited {
    color: ${COLORS.fillBlackBlack};
    fill: ${COLORS.fillBlackBlack};
  }

  &:hover:not(:disabled) {
    border-color: ${COLORS.fillBlackBlack};
    background-color: ${COLORS.fillBlackBlack};
    color: ${COLORS.white};
    fill: ${COLORS.white};
  }

  &:disabled {
    border-color: ${COLORS.disabledBlackOutlineButton};
    color: ${COLORS.disabledBlackOutlineButton};
    fill: ${COLORS.disabledBlackOutlineButton};
  }
`;

const whiteOutline = css`
  background-color: transparent;
  border-color: ${COLORS.white};
  border-width: 2px;
  color: ${COLORS.white};
  fill: ${COLORS.white};

  &:visited {
    color: ${COLORS.white};
    fill: ${COLORS.white};
  }

  &:hover:not(:disabled) {
    border-color: ${COLORS.white};
    background-color: ${COLORS.white};
    color: ${COLORS.fillBlackBlack};
    fill: ${COLORS.fillBlackBlack};
  }
`;

const purpleFill = css`
  background-color: ${SHARED_COLORS.Purple.Primary};
  border-color: ${SHARED_COLORS.Purple.Primary};
  color: ${COLORS.white};
  fill: ${COLORS.white};

  &:visited {
    border-color: 2px solid ${SHARED_COLORS.Purple[600]};
  }

  &:disabled {
    background-color: ${SHARED_COLORS.Purple[200]};
    border-color: ${SHARED_COLORS.Purple[200]};
  }

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background-color: ${SHARED_COLORS.Purple[600]};
    border-color: ${SHARED_COLORS.Purple[600]};
  }
`;

const purpleBoldFill = css`
  ${purpleFill};
  ${fontAvenirBold}
`;

const btnTypes = {
  purpleFill,
  purpleBoldFill,
  irisFill,
  irisBoldFill,
  irisOutline,
  paleGreyOutline,
  paleBoldGreyOutline,
  noOutline,
  noStyle,
  aquaGreenFill,
  aquaMarineFill,
  whiteOutline,
  cornflowerFill,
  blackOutline,
};

const sizes = {
  extraSmall: css({ padding: '4px 15px' }),
  small: css({ padding: '8px 20px' }),
  medium: css({ padding: '8px 20px' }),
  large: css({ padding: '8px 45px' }),
};

const StyledButton = styled('button', { shouldForwardProp })<{ otherStyles: string }>`
  ${baseStyle}
  ${props => `${props.otherStyles}`}
  ${baseStyledSystem}
`;

export type BtnTypes =
  | 'purpleFill'
  | 'purpleBoldFill'
  | 'irisFill'
  | 'irisBoldFill'
  | 'irisOutline'
  | 'paleGreyOutline'
  | 'paleBoldGreyOutline'
  | 'noOutline'
  | 'noStyle'
  | 'aquaGreenFill'
  | 'aquaMarineFill'
  | 'cornflowerFill'
  | 'blackOutline'
  | 'whiteOutline';

type ButtonProps = Omit<
  styledSystem.ColorProps &
    styledSystem.LayoutProps &
    styledSystem.SpaceProps &
    styledSystem.PositionProps &
    styledSystem.AlignItemsProps &
    styledSystem.JustifyContentProps &
    styledSystem.DisplayProps &
    styledSystem.TypographyProps,
  'size'
> & {
  btnType?: BtnTypes;
  color?: keyof typeof COLORS;
  full?: boolean;
  size?: 'extraSmall' | 'small' | 'medium' | 'large';
  loading?: boolean;
  loadingInline?: boolean;
  hasShadow?: boolean;
  mobile?: boolean;
  bold?: boolean;
  underline?: boolean;
};

/**
 * @deprecated
 * @see
 * @see Use `import { Button } from 'upbound-frontend-elements' instead`
 */
export const Button = React.forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>, 'css'>
>(({ btnType, children, full, size, loading, loadingInline, hasShadow, mobile, bold, underline, ...props }, ref) => {
  const btnStyle = btnType ? btnTypes[btnType] : '';
  const fontStyle = bold ? fontAvenirBold : fontAvenir;
  const sizeStyle = size ? sizes[size] : sizes.medium;
  const fullStyle = full ? css({ width: '100%' }) : '';
  const boxShadow = hasShadow ? css({ boxShadow: '0 0 17px 0 rgba(17, 29, 61, 0.15)' }) : '';
  const underlineStyle = underline ? css({ textDecoration: 'underline' }) : '';
  const stretchStyle = mobile
    ? css`
        width: 100%;

        ${MQ.m} {
          width: auto;
        }
      `
    : '';

  const otherStyles = [btnStyle, fontStyle, sizeStyle, fullStyle, boxShadow, stretchStyle, underlineStyle];

  return (
    <ClassNames>
      {({ css: classNamesCss }) => (
        <StyledButton otherStyles={classNamesCss(otherStyles)} {...props} ref={ref}>
          {!!loading && !loadingInline && <LoadingSpinner color="white" inButton={true} size="s" visible={true} />}
          {!!loadingInline && !loading && (
            <Flex justifyContent="center" alignItems="center">
              <LoadingSpinner
                color="white"
                inButton={true}
                size="s"
                visible={true}
                css={css`
                  margin-left: 0;
                  margin-right: 0;
                `}
              />
              <Flex justifyContent="center" alignItems="center" ml="7px">
                {children}
              </Flex>
            </Flex>
          )}
          {!loading && !loadingInline && children}
        </StyledButton>
      )}
    </ClassNames>
  );
});

Button.displayName = 'Button';

const StyledAnchor = styled('a', { shouldForwardProp })<{ otherStyles: string }>`
  ${baseStyle}
  ${({ otherStyles }) => otherStyles}
  ${baseStyledSystem}
`;

export const AnchorButton = React.forwardRef<
  HTMLAnchorElement,
  Omit<
    ButtonProps &
      Omit<AnchorProps, 'size'> &
      React.ButtonHTMLAttributes<HTMLButtonElement> &
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
    'css'
  >
>(({ btnType, children, full, size, hasShadow, bold, ...props }, ref) => {
  const btnStyle = btnType ? btnTypes[btnType] : '';
  const fontStyle = bold ? fontAvenirBold : fontAvenir;
  const sizeStyle = size ? sizes[size] : sizes.medium;
  const fullStyle = full ? css({ width: '100%' }) : '';
  const boxShadow = hasShadow ? css({ boxShadow: '0 0 17px 0 rgba(17, 29, 61, 0.15)' }) : '';

  const otherStyles = [
    btnStyle,
    fontStyle,
    sizeStyle,
    fullStyle,
    boxShadow,
    css({ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }),
  ];

  return (
    <ClassNames>
      {({ css: classNamesCss }) => (
        <StyledAnchor hover="none" otherStyles={classNamesCss(otherStyles)} {...props} ref={ref}>
          {children}
        </StyledAnchor>
      )}
    </ClassNames>
  );
});

AnchorButton.displayName = 'AnchorButton';

const StyledLink = styled(Link, { shouldForwardProp })<{ otherStyles: string }>`
  ${baseStyle}
  ${({ otherStyles }) => otherStyles}
  ${baseStyledSystem}
`;

export const LinkButton = React.forwardRef<
  HTMLAnchorElement,
  Omit<
    ButtonProps &
      LinkProps &
      Omit<AnchorProps, 'size'> &
      React.ButtonHTMLAttributes<HTMLButtonElement> &
      React.AnchorHTMLAttributes<HTMLAnchorElement>,
    'css'
  >
>(({ btnType, children, full, size, hasShadow, bold, ...props }, ref) => {
  const btnStyle = btnType ? btnTypes[btnType] : '';
  const fontStyle = bold ? fontAvenirBold : fontAvenir;
  const sizeStyle = size ? sizes[size] : sizes.medium;
  const fullStyle = full ? css({ width: '100%' }) : '';
  const boxShadow = hasShadow ? css({ boxShadow: '0 0 17px 0 rgba(17, 29, 61, 0.15)' }) : '';

  const otherStyles = [
    btnStyle,
    fontStyle,
    sizeStyle,
    fullStyle,
    boxShadow,
    css({ display: 'inline-flex', alignItems: 'center', textDecoration: 'none' }),
  ];

  return (
    <ClassNames>
      {({ css: classNamesCss }) => (
        <StyledLink hover="none" otherStyles={classNamesCss(otherStyles)} {...props} ref={ref}>
          {children}
        </StyledLink>
      )}
    </ClassNames>
  );
});

LinkButton.displayName = 'LinkButton';
