/* eslint-disable @typescript-eslint/prefer-literal-enum-member */
import { css, SerializedStyles } from '@emotion/react';
import styled from '@emotion/styled';
import { COLORS, createTextFont, SHADES, shouldForwardProp, TextSize, TextWeight } from 'packages/constants';
import React from 'react';
import * as styledSystem from 'styled-system';

import { BaseProps } from '../index.types';

const whiteSpace = styledSystem.style({
  prop: 'whiteSpace',
  cssProperty: 'white-space',
});

const baseStyledSystem = styledSystem.compose(
  styledSystem.flexbox,
  styledSystem.layout,
  styledSystem.display,
  styledSystem.position,
  styledSystem.padding,
  styledSystem.space,
  whiteSpace,
);

enum ButtonSizeType {
  Large = TextSize.Size4,
  Medium = TextSize.Size6,
  Small = TextSize.Size7,
}

export enum ButtonType {
  Primary = 'Primary',
  Secondary = 'Secondary',
  Transparent = 'Transparent',
  TransparentOutline = 'TransparentOutline',
}

export interface CustomButtonProps {
  btnSize?: keyof typeof ButtonSizeType;
  btnType?: keyof typeof ButtonType;
  btnActive?: boolean;
  noWrap?: boolean;
}

export interface ButtonProps
  extends BaseProps,
    styledSystem.PaddingProps,
    styledSystem.MarginProps,
    styledSystem.SizeProps,
    CustomButtonProps {}

const getRadius = ({ btnSize }: Required<Pick<CustomButtonProps, 'btnSize'>>): string => {
  switch (btnSize) {
    case 'Large':
      return '10px';
    case 'Medium':
      return '7px';
    case 'Small':
    default:
      return '6px';
  }
};

const getPadding = ({ btnSize }: Required<Pick<CustomButtonProps, 'btnSize'>>): string => {
  switch (btnSize) {
    case 'Large':
      return '9px 19px';
    case 'Medium':
      return '6px 13px';
    case 'Small':
    default:
      return '5px 11px';
  }
};

const textStyles = ({ btnSize = 'Medium' }: CustomButtonProps) => {
  const buttonWeight = TextWeight.Heavy;
  const buttonSize = ButtonSizeType[btnSize];

  return createTextFont({
    weight: buttonWeight,
    size: TextSize[buttonSize],
  });
};

export const PrimaryBackgroundColor = COLORS.Purple.Primary;
export const PrimaryBorderColor = COLORS.Purple.Primary;
export const PrimaryColor = COLORS.Neutral[0];
export const PrimaryActiveBackgroundColor = SHADES.Purple[50];

export const SecondaryBackgroundColor = COLORS.Neutral[0];
export const SecondaryBorderColor = COLORS.Neutral[100];
export const SecondaryColor = COLORS.Neutral[1000];
export const SecondaryActiveBackgroundColor = SHADES.Neutral[25];

const buttonStyles = ({
  btnSize = 'Medium',
  btnType = ButtonType.Primary,
  btnActive = false,
  noWrap = false,
}: CustomButtonProps): SerializedStyles => css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-radius: ${getRadius({ btnSize })};
  padding: ${getPadding({ btnSize })};
  outline: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  ${noWrap &&
  css`
    white-space: nowrap;
  `}

  ${btnType === ButtonType.Primary &&
  css`
    background-color: ${PrimaryBackgroundColor};
    border-color: ${PrimaryBorderColor};
    color: ${PrimaryColor};
    border-width: 2px;

    ${btnActive &&
    css`
      background-color: ${PrimaryActiveBackgroundColor};
    `}

    &:hover {
      background-color: ${COLORS.Purple[400]};
      border-color: ${COLORS.Purple[400]};
    }

    &:focus,
    &:active {
      background-color: ${COLORS.Purple.Primary};
      border-color: ${COLORS.Purple.Primary};
      box-shadow:
        0 0 0 2px ${COLORS.Neutral[0]},
        0 0 0 4px ${COLORS.Purple[600]};
    }

    &:disabled {
      background-color: ${COLORS.Purple[200]};
      border-color: ${COLORS.Purple[200]};
      color: ${COLORS.Neutral[0]};
      cursor: not-allowed;
    }
  `}

  ${btnType === ButtonType.Secondary &&
  css`
    background-color: ${SecondaryBackgroundColor};
    border-color: ${SecondaryBorderColor};
    color: ${SecondaryColor};
    border-width: 2px;

    ${btnActive &&
    css`
      background-color: ${SecondaryActiveBackgroundColor};
    `}

    &:hover {
      background-color: ${COLORS.Neutral[0]};
      border-color: ${COLORS.Neutral[200]};
    }

    &:focus,
    &:active {
      background-color: ${COLORS.Neutral[0]};
      border-color: ${COLORS.Purple[600]};
    }

    &:disabled {
      background-color: ${SHADES.Neutral[25]};
      border-color: ${SHADES.Neutral[50]};
      color: ${COLORS.Neutral[400]};
      cursor: not-allowed;
    }
  `}

  ${btnType === ButtonType.Transparent &&
  css`
    background-color: transparent;
    border: none;
    color: ${COLORS.Neutral[100]};
    border-width: 2px;

    ${btnActive &&
    css`
      background-color: ${COLORS.Neutral[700]};
    `}

    &:hover {
      background-color: ${COLORS.Neutral[700]};
    }

    &:focus,
    &:active {
      background-color: ${COLORS.Neutral[700]};
    }

    &:disabled {
      background-color: ${COLORS.Neutral[800]};
      color: ${COLORS.Neutral[400]};
      cursor: not-allowed;
    }
  `}

  ${btnType === ButtonType.TransparentOutline &&
  css`
    background-color: transparent;
    border-color: ${COLORS.Neutral[600]};
    color: ${COLORS.Neutral[100]};
    border-width: 2px;

    ${btnActive &&
    css`
      background-color: ${COLORS.Neutral[700]};
    `}

    &:hover {
      background-color: ${COLORS.Neutral[700]};
    }

    &:focus,
    &:active {
      background-color: ${COLORS.Neutral[700]};
    }

    &:disabled {
      background-color: ${COLORS.Neutral[800]};
      color: ${COLORS.Neutral[400]};
      cursor: not-allowed;
    }
  `}
`;
/**
 * @default
 * btnSize: ButtonSizeType.Medium
 * btnType: ButtonType.Primary
 * btnActive: false
 */
const Button = styled('button', { shouldForwardProp })<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>>`
  ${textStyles}
  ${buttonStyles}
  ${baseStyledSystem}
`;

Button.displayName = 'Button';

const ButtonLink = styled('a', { shouldForwardProp })<ButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>>`
  ${textStyles}
  ${buttonStyles}
  ${baseStyledSystem}
`;

export { Button, ButtonLink };
