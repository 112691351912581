import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  PaginationState,
  useReactTable,
} from '@tanstack/react-table';
import { useMemo, useState } from 'react';

import { TableData, TableHookOptions } from './types';

export const useTableHook = <T>(options: TableHookOptions<T>): ReturnType<typeof useReactTable<T>> & TableData<T> => {
  const table = useReactTable({
    // Row Model represents list of all rows to be rendered, as well as HOW they should be rendered
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    ...options,
  });

  // TODO: Consider moving this to the consumers and return `table` directly
  // Maintain object identity to avoid run-away re-renders
  const res = useMemo(
    () => ({
      ...table,
      headerGroups: table.getHeaderGroups(),
      tableRows: table.getRowModel().rows,
    }),
    [table, table.getRowModel().rows, table.getHeaderGroups()],
  );

  return res;
};

export const usePagination = ({ pageSize, pageIndex }: PaginationState) =>
  useState<PaginationState>({
    pageSize,
    pageIndex,
  });

export { createColumnHelper, getPaginationRowModel, flexRender };
