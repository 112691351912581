import {
  AccountFragment,
  ControlPlanePermission,
  CurrentUserFragment,
  GetCurrentUserProfileQuery,
  OrgAccount,
  OrganizationPermission,
  TeamMember,
  TeamMemberPermission,
  UserAccount,
} from '@/generated/upbound-graphql';

export function isUpboundAdmin(allAccounts: Array<AccountFragment> | undefined) {
  return !!allAccounts?.some(account => {
    // // Uncomment to check the admin site
    // return (
    //   (isOrgAccount(account) && account.organization.name === 'upbound') ||
    //   (isUserAccount(account) && account.user.email.endsWith('upbound.io'))
    // );

    return isOrgAccount(account) && account.organization.name === 'upbound';
  });
}

export function isOrgAccount(currentAccount: AccountFragment | undefined | null): currentAccount is OrgAccount {
  return currentAccount?.__typename === 'OrgAccount';
}

export function isUserAccount(currentAccount: AccountFragment | undefined | null): currentAccount is UserAccount {
  return currentAccount?.__typename === 'UserAccount';
}

export function isOrgAdmin(currentAccount: AccountFragment | undefined | null) {
  return isOrgAccount(currentAccount) && currentAccount.organization.role === OrganizationPermission.Admin;
}

export function isTeamOwner(user: GetCurrentUserProfileQuery['currentUser'] | undefined | null, members: TeamMember[]) {
  return !!members.find(
    member => user && user.id.toString() === member.user.id && member.permission === TeamMemberPermission.Owner,
  );
}

export function isTeamAdmin(
  currentAccount: CurrentUserFragment | undefined | null,
  teamMembers: Array<TeamMember>,
): boolean {
  if (!currentAccount) {
    return false;
  }
  return !!teamMembers.find(
    member => currentAccount.id === member.user.id && member.permission === TeamMemberPermission.Owner,
  );
}

export function hasCpEditPerms(
  info:
    | {
        permission: ControlPlanePermission;
        controlPlane: { isViewOnly?: boolean | null };
      }
    | undefined
    | null,
) {
  if (!info) {
    return;
  }

  const {
    permission,
    controlPlane: { isViewOnly },
  } = info;

  return !isViewOnly && (permission === ControlPlanePermission.Owner || permission === ControlPlanePermission.Editor);
}
