import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import ReactCopyToClipboard from 'react-copy-to-clipboard';

import { COLORS, TIMING } from '@/constants/styles';
import { Flex } from '@/elements/Div';
import { InlineIcon } from '@/elements/Icon';
import { Paragraph } from '@/elements/Paragraph';

enum SvgHighlightMethods {
  Fill = 'fill',
  Stroke = 'stroke',
}

const fadeAnimation = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.95;
  }
`;

const CopyIconContainer = styled(Flex)<{ svgHighlightMethod: SvgHighlightMethods[keyof SvgHighlightMethods] }>`
  position: relative;
  cursor: pointer;

  &:hover {
    svg {
      ${props => `${props.svgHighlightMethod}: ${COLORS.iris}`};
    }
  }
`;

const CopiedTooltip = styled(Flex)`
  background-color: ${COLORS.slate};
  opacity: 0.95;
  padding: 5px 15px;
  border-radius: 5px;
  position: absolute;
  top: 100%;
  left: 50%;
  animation: ${fadeAnimation} ${TIMING.fast()};
  transform: translateX(-50%);
  margin-top: 10px;
  z-index: 1;

  p {
    white-space: nowrap;
  }
`;

export const CopyToClipboard: React.FC<
  React.PropsWithChildren<{
    command: string;
    onClick?: () => void;
    svgHighlightMethod?: SvgHighlightMethods[keyof SvgHighlightMethods];
    disabled?: boolean;
  }>
> = ({ command, onClick, children, disabled, svgHighlightMethod = SvgHighlightMethods.Stroke }) => {
  const [copied, toggleCopied] = useState(false);

  useEffect(() => {
    let delayTimer: ReturnType<typeof setTimeout>;

    if (copied) {
      delayTimer = setTimeout(() => toggleCopied(false), 2500);
    }

    return () => {
      clearTimeout(delayTimer);
    };
  }, [copied]);

  return (
    <ReactCopyToClipboard
      text={command}
      onCopy={() => {
        if (disabled) {
          return;
        }
        toggleCopied(true);
        if (onClick) {
          onClick();
        }
      }}
    >
      <CopyIconContainer svgHighlightMethod={svgHighlightMethod}>
        {!children ? <InlineIcon name="copy" color="slate" width={16} height={16} /> : children}
        {!!copied && (
          <CopiedTooltip>
            <Paragraph color="white" size="small" pt="1px">
              Copied to clipboard
            </Paragraph>
          </CopiedTooltip>
        )}
      </CopyIconContainer>
    </ReactCopyToClipboard>
  );
};
