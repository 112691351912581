import faviconIco from './dev-favicon.ico';
import favicon16Png from './dev-favicon-16.png';
import favicon32Png from './dev-favicon-32.png';
import favicon96Png from './dev-favicon-96.png';
import favicon120Png from './dev-favicon-120.png';
import favicon128Png from './dev-favicon-128.png';
import favicon152Png from './dev-favicon-152.png';
import favicon180Png from './dev-favicon-180.png';
import favicon192Png from './dev-favicon-192.png';
import favicon196Png from './dev-favicon-196.png';
import favicon270Png from './dev-favicon-270.png';

export const favicon = faviconIco;
export const favicon16 = favicon16Png;
export const favicon32 = favicon32Png;
export const favicon96 = favicon96Png;
export const favicon120 = favicon120Png;
export const favicon128 = favicon128Png;
export const favicon152 = favicon152Png;
export const favicon180 = favicon180Png;
export const favicon192 = favicon192Png;
export const favicon196 = favicon196Png;
export const favicon270 = favicon270Png;
