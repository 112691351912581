import z from 'zod';

import { SharedTelemetryConfig } from './spacesApi/sharedTelemetryConfigApi.service';

// The idea is to add unions once we introduce more group-scoped resource types
export type GroupScopedResource = z.infer<typeof SharedTelemetryConfig>;

export const Condition = z.object({
  lastTransitionTime: z.string(),
  type: z.string(),
  status: z.string(),
  reason: z.string(),
  message: z.string().optional(),
});

export const ControlPlaneSelector = z.object({
  names: z.string().array().optional(),
  labelSelectors: z
    .array(
      z.object({
        matchLabels: z.record(z.string()).optional(),
      }),
    )
    .optional(),
});
