import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { useGetAllAccountsLazyQuery } from '@/generated/upbound-graphql';
import { useCurrentAccount } from '@/graphql/reactiveVars';
import { app, cloud } from '@/routes';

import { accountsSelectOrg } from '../constants/links';
import { externalConsoleLink } from '../routes/console';
import { LoadingSpinner, LoadingSpinnerWrapper } from './LoadingSpinner';

/**
 * Handle forced redirects like showing the trial expired page
 * @param config is a string optionally provided by pages to indicate to the ReRouter what "section"
 *        the current page is in.
 */
export const ReRouter: React.FC<React.PropsWithChildren<{ config: string | undefined }>> = ({ children, config }) => {
  const reRouteTo = useReRouteTo(config);
  const router = useRouter();
  const [currentAccount] = useCurrentAccount();
  const path = router.asPath;
  const searchParams = path.includes('?') ? `?${new URLSearchParams(path.split('?')[1])}` : '';

  if (router.route !== '/_error') {
    if (reRouteTo === undefined) {
      return (
        <LoadingSpinnerWrapper>
          <LoadingSpinner visible={true} size="xl" />
        </LoadingSpinnerWrapper>
      );
    }

    if (reRouteTo === true) {
      if (!currentAccount) {
        return null;
      }
      router.replace(cloud.spacesIndex.url(currentAccount!.id) + searchParams);
      return null;
    }

    if (reRouteTo) {
      router.replace(reRouteTo + searchParams);
      return null;
    }
  }

  return <>{children}</>;
};

/// Calculate a redirect to force if needed
/// Returns a boolean or a string or undefined:
/// - if it is undefined is indicates that we do not yet know and things are still loading.
/// - if it is a boolean it indicates a redirect is needed or not where true means redirect to the account in general.
/// - if it is a url string it indicates a redirect to that path is needed.
export function useReRouteTo(route: string | undefined): string | boolean | undefined {
  const isPortal = window.location.hostname.match(/^[^.]+\.portal/);
  const isError = route === 'error';
  const isRoot = route === 'root';
  const isOrgOutOfCapacity = route === 'orgOutOfCapacity';

  const { org, loading: orgLoading } = useSelectedOrg(isRoot);

  if (isPortal || isError) {
    return false;
  }

  if (isRoot) {
    return accountsSelectOrg.url();
  }

  if (orgLoading) {
    return undefined;
  }

  // Force org selection if needed
  if (!org) {
    return accountsSelectOrg.url();
  }

  // Block access due to "floodGate"/"allowAccess"
  if (!org.organization.allowAccess) {
    if (isOrgOutOfCapacity) {
      return false;
    }
    return `${externalConsoleLink()}${app.orgOutOfCapacity.url(org.id)}`;
  } else if (isOrgOutOfCapacity) {
    return app.org.url(org.id);
  }

  return false;
}

// Determine if we need to select an org
// If the currentAccount is an org we don't need to select an org.
// Otherwise verifies the account in the url is an org if there is one and selects it.
// Otherwise we need to select an org
// NOTE: This will update currentAccount when it changes in the apollo cache or the url account changes.
function useSelectedOrg(disabled: boolean) {
  const router = useRouter();
  const account = router.query.orgName as string | undefined;
  const [currentAccount, setCurrentAccount] = useCurrentAccount();
  const [getAccountsData, { data, called }] = useGetAllAccountsLazyQuery();
  const org = !disabled && currentAccount?.__typename === 'OrgAccount' ? currentAccount : undefined;
  const [toReturn, setToReturn] = useState({ org, loading: !org });
  const foundAccount = account && data?.accounts.find(a => a.__typename === 'OrgAccount' && a.id === account);

  useEffect(() => {
    if (!disabled && account) {
      if (foundAccount) {
        if (foundAccount === currentAccount) {
          setToReturn({
            org,
            loading: false,
          });
        } else {
          setCurrentAccount(foundAccount);
        }
      } else if (!called) {
        getAccountsData();
        setToReturn({ org, loading: true });
        return;
      } else if (data) {
        setToReturn({ org, loading: false });
      }
    } else {
      setToReturn({ org, loading: false });
    }
  }, [account, called, currentAccount, data, disabled, foundAccount, getAccountsData, setCurrentAccount, org]);

  // If url account does not equal selected account we are loading and it will
  // be handled in the useEffect above
  if (account && (account !== currentAccount?.id || toReturn.org?.id !== account)) {
    return { org: undefined, loading: true };
  }

  return toReturn;
}
