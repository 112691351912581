import { useEffect, useRef, useState } from 'react';

export function useExpandable() {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (!isExpanded) {
      if (contentHeight !== 0) {
        setContentHeight(0);
        return;
      }

      return;
    }

    if (!contentRef || !contentRef.current || !contentRef.current.scrollHeight) {
      return;
    }

    setContentHeight(contentRef.current.scrollHeight);
  }, [isExpanded, contentRef]);

  return { isExpanded, setExpanded, contentRef, contentHeight };
}

export const useOutsideClick = (cb: Function) => {
  const ref = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    const clickHandler = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
      }
    };

    document.addEventListener('click', clickHandler);

    return () => {
      document.removeEventListener('click', clickHandler);
    };
  });

  return ref;
};
