export * as app from './app';
export * as cloud from './console';
export * as external from '@/constants/links';
export * as devPortal from './devPortal';

// FIXME: Temporarily using old routes and therefore needed generatePath

/**
 * Returns a path with params interpolated.
 *
 * @see https://reactrouter.com/utils/generate-path
 */
export function generatePath<Path extends string>(
  originalPath: Path,
  params: {
    [key in PathParam<Path>]: string | null;
  } = {} as any,
  searchParams: {
    [searchValue: string]: string | null;
  } = {} as any,
): string {
  let path: string = originalPath;
  if (path.endsWith('*') && path !== '*' && !path.endsWith('/*')) {
    warning(
      false,
      `Route path "${path}" will be treated as if it were ` +
        `"${path.replace(/\*$/, '/*')}" because the \`*\` character must ` +
        `always follow a \`/\` in the pattern. To get rid of this warning, ` +
        `please change the route path to "${path.replace(/\*$/, '/*')}".`,
    );
    path = path.replace(/\*$/, '/*') as Path;
  }

  // ensure `/` is added at the beginning if the path is absolute
  const prefix = path.startsWith('/') ? '/' : '';

  const segments = path
    .split(/\/+/)
    .map((segment, index, array) => {
      const isLastSegment = index === array.length - 1;

      // only apply the splat if it's the last segment
      if (isLastSegment && segment === '*') {
        const star = '*' as PathParam<Path>;
        const starParam = params[star];

        // Apply the splat
        return starParam;
      }

      const keyMatch = segment.match(/^:(\w+)(\??)$/);
      if (keyMatch) {
        const [, key, optional] = keyMatch;
        const param = params[key as PathParam<Path>];

        if (optional === '?') {
          return param == null ? '' : param;
        }

        if (param == null) {
          invariant(false, `Missing ":${key}" param`);
        }

        return param;
      }

      // Remove any optional markers from optional static segments
      return segment.replace(/\?$/g, '');
    })
    // Remove empty segments
    .filter(segment => !!segment);

  const url = prefix + segments.join('/');

  // append query strings
  if (Object.keys(searchParams).length) {
    const searchP = new URLSearchParams();

    Object.entries(searchParams)
      .filter(([, value]) => value !== null && typeof value !== 'undefined')
      .forEach(([key, value]) => searchP.append(key, value as string));

    return url + '?' + searchP.toString();
  }

  return url;
}

// Recursive helper for finding path parameters in the absence of wildcards
type _PathParam<Path extends string> =
  // split path into individual path segments
  Path extends `${infer L}/${infer R}`
    ? _PathParam<L> | _PathParam<R>
    : // find params after `:`
    Path extends `:${infer Param}`
    ? Param extends `${infer Optional}?`
      ? Optional
      : Param
    : // otherwise, there aren't any params present
      never;

/**
 * Examples:
 * "/a/b/*" -> "*"
 * ":a" -> "a"
 * "/a/:b" -> "b"
 * "/a/blahblahblah:b" -> "b"
 * "/:a/:b" -> "a" | "b"
 * "/:a/b/:c/*" -> "a" | "c" | "*"
 */
type PathParam<Path extends string> =
  // check if path is just a wildcard
  Path extends '*' | '/*'
    ? '*'
    : // look for wildcard at the end of the path
    Path extends `${infer Rest}/*`
    ? '*' | _PathParam<Rest>
    : // look for params in the absence of wildcards
      _PathParam<Path>;

function invariant(value: any, message?: string) {
  if (value === false || value === null || typeof value === 'undefined') {
    throw new Error(message);
  }
}

function warning(cond: any, message: string) {
  if (!cond) {
    // eslint-disable-next-line no-console
    if (typeof console !== 'undefined') {
      console.warn(message);
    }

    try {
      // Welcome to debugging history!
      //
      // This error is thrown as a convenience so you can more easily
      // find the source for a warning that appears in the console by
      // enabling "pause on exceptions" in your JavaScript debugger.
      throw new Error(message);
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
}
