/** The `OrganizationPermission` of an `Organization` or `OrgMember` */
export enum OrganizationPermission {
  /** `MEMBER` `OrganizationPermission` */
  Member = 'MEMBER',
  /** `OWNER` `OrganizationPermission` */
  Admin = 'OWNER',
}

/** Common properties for all OpenAPI types */
type K8sOpenApiBase = {
  type: 'object' | 'array' | 'number' | 'integer' | 'string' | 'boolean';
  title?: string;
  // MUST support, at a minimum, markdown syntax as described by [CommonMark 0.27](http://spec.commonmark.org/0.27/).
  // Tooling MAY choose to ignore some CommonMark features to address security concerns.
  description?: string;
  nullable?: boolean; // Allows sending a null value for the defined schema. Default value is false.
  enum?: any[]; // Should have at least one element and the elements should be unique. Can be of any type including NULL
  // Additional external documentation for this schema.
  externalDocs?: {
    description?: string;
    url: string;
  };

  // TODO:
  // allOf - Inline or referenced schema MUST be of a Schema Object and not a standard JSON Schema.
  // oneOf - Inline or referenced schema MUST be of a Schema Object and not a standard JSON Schema.
  // anyOf - Inline or referenced schema MUST be of a Schema Object and not a standard JSON Schema.
  // not - Inline or referenced schema MUST be of a Schema Object and not a standard JSON Schema.

  // default?: any; // The default value represents what would be assumed by the consumer of the input as the value of
  //                   the schema if one is not provided. Unlike JSON Schema, the value MUST conform to the defined type
  //                   for the Schema Object defined at the same level. For example, if type is string, then default can
  //                   be "foo" but cannot be 1.
  // example?: any; // A free-form property to include an example of an instance for this schema. To represent examples
  //                   that cannot be naturally represented in JSON or YAML, a string value can be used to contain the
  //                   example with escaping where necessary.
};

/** An OpenAPI spec of type 'object' */
export type K8sOpenApiObject = Omit<K8sOpenApiBase, 'type'> & {
  type: 'object';
  required?: string[];
  properties?: { [propName: string]: K8sOpenAPISchema }; // Mutually exclusive with `additionalProperties`
  // TODO: The additionalProperties seems to indicate that `additionalProperties` it could be `true` but if I try on a
  // real k8s cluster it refuses
  additionalProperties?: K8sOpenAPISchema; // Mutually exclusive with `properties`
  maxProperties?: number;
  minProperties?: number;
  patternProperties?: string;
};

export type K8sOpenApiPrimitiveString = Omit<K8sOpenApiBase, 'type'> & {
  type: 'string';
  pattern?: string;
  maxLength?: number;
  minLength?: number;
  format?:
    | 'byte'
    | 'binary'
    | 'date' // As defined by full-date - http://xml2rfc.ietf.org/public/rfc/html/rfc3339.html#anchor14
    | 'date-time' // As defined by `date-time` - http://xml2rfc.ietf.org/public/rfc/html/rfc3339.html#anchor14
    | 'password'
    | 'email' // https://datatracker.ietf.org/doc/html/rfc5322#section-3.4.1
    | 'hostname' // https://datatracker.ietf.org/doc/html/rfc1034#section-3.1
    | 'ipv4' // https://datatracker.ietf.org/doc/html/rfc2673#section-3.2
    | 'ipv6' // https://datatracker.ietf.org/doc/html/rfc2373#section-2.2
    | 'uri' // https://datatracker.ietf.org/doc/html/rfc3986
    | 'uriref' // https://datatracker.ietf.org/doc/html/rfc3986
    | string;
};

export type K8sOpenApiPrimitiveNumeric = Omit<K8sOpenApiBase, 'type'> & {
  multipleOf?: number;
  maximum?: number;
  exclusiveMaximum?: boolean;
  minimum?: number;
  exclusiveMinimum?: boolean;
};

export type K8sOpenApiPrimitiveNumber = K8sOpenApiPrimitiveNumeric & {
  type: 'number';
  format?: 'float' | 'double' | string;
};

export type K8sOpenApiPrimitiveInteger = K8sOpenApiPrimitiveNumeric & {
  type: 'integer';
  format?: 'int32' | 'int64' | string;
};

export type K8sOpenApiPrimitiveBoolean = Omit<K8sOpenApiBase, 'type'> & { type: 'boolean' };

/** Primitive (not object or array) OpenAPI spec types */
export type K8sOpenApiPrimitive =
  | K8sOpenApiPrimitiveString
  | K8sOpenApiPrimitiveNumber
  | K8sOpenApiPrimitiveInteger
  | K8sOpenApiPrimitiveBoolean;

/** The Array type of OpenAPI */
export type K8sOpenApiArray = Omit<K8sOpenApiBase, 'type'> & {
  type: 'array';
  items: K8sOpenAPISchema;
  maxItems?: number;
  minItems?: number;
  uniqueItems?: boolean;
};

/** The spec type of OpenAPI V3 for Kubernetes */
export type K8sOpenAPISchema = K8sOpenApiObject | K8sOpenApiArray | K8sOpenApiPrimitive;
