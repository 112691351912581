import { z } from 'zod';

import { CpId } from '../pages/[orgName]/portal/index.layout';

/** Default polling interval to use for fetching data via GraphQL or REST API */
export const DefaultPollingInterval = 10 * 1000;

/** An annotation key used to indicate (within "metadata.annotations") that a resource has been paused */
export const PausedAnnotationKey = 'crossplane.io/paused';

/**
 * PINNING FEATURE
 */

const cpIdSchema = z.string().refine((value): value is CpId => value.split(':').length === 4, {
  message: "Invalid CpId format, expected format 'orgName:spaceName:groupName:cpName'",
});

export const pinnedServiceSchema = z.object({ serviceName: z.string(), index: z.number().optional() });

export type PinnedService = z.infer<typeof pinnedServiceSchema>;

export const pinnedServicesSchema = z.record(cpIdSchema, z.array(pinnedServiceSchema));

export type PinnedServices = z.infer<typeof pinnedServicesSchema>;

export const PinnedServicesKey = 'pinnedServices';
