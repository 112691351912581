import { queryOptions, skipToken, useQueries, useQuery } from '@tanstack/react-query';

import { getApiGroup, getApiResourceList, K8sVersionRegexp } from '@/services/spacesApi/features';

const fiveMinutesInMilliseconds = 5 * 60 * 1000;

function apiDiscoveryGroupOptions(orgName: string, spaceName: string | undefined, groupName: string) {
  return queryOptions({
    queryKey: ['spaceApiDiscoveryGroup', orgName, spaceName, groupName],
    queryFn: !!orgName && !!spaceName ? () => getApiGroup(orgName, spaceName, groupName) : skipToken,
    staleTime: fiveMinutesInMilliseconds,
    refetchInterval: fiveMinutesInMilliseconds,
  });
}

function apiDiscoveryAPIResourceListOptions(
  orgName: string,
  spaceName: string | undefined,
  groupName: string,
  version: string,
) {
  return queryOptions({
    queryKey: ['spaceApiDiscoveryResourceList', orgName, spaceName, groupName, version],
    queryFn: orgName && spaceName ? () => getApiResourceList(orgName, spaceName, groupName, version) : skipToken,
    staleTime: fiveMinutesInMilliseconds,
    refetchInterval: fiveMinutesInMilliseconds,
  });
}

export const Features = {
  backup: { group: 'spaces.upbound.io', resource: 'backupschedules' },
  secrets: { group: 'spaces.upbound.io', resource: 'sharedexternalsecrets' },
  orb: { group: 'authorization.spaces.upbound.io', resource: 'objectrolebindings' },
  observability: { group: 'observability.spaces.upbound.io', resource: 'sharedtelemetryconfigs' },
  queryApi: { group: 'query.spaces.upbound.io', resource: 'queries', version: 'v1alpha2' },
} as const;

export function useSpacesApiFeatureEnabled(
  orgName: string,
  spaceName: string | undefined,
  feature: { group: string; resource: string; version?: string },
): { enabled: false } | { enabled: true; preview: boolean } | undefined {
  const { data, isLoading } = useQuery(apiDiscoveryGroupOptions(orgName, spaceName, feature.group));
  const results = useQueries({
    queries:
      data?.versions
        ?.map(v => v.version)
        .filter(v => !feature.version || v === feature.version)
        .map(v => apiDiscoveryAPIResourceListOptions(orgName, spaceName, feature.group, v)) ?? [],
  });

  if (!spaceName) {
    return { enabled: false };
  }

  const isDone = !isLoading && results.every(r => !r.isLoading);

  if (!isDone) {
    return undefined;
  }

  const res = results.find(l => l.data?.resources.some(r => r.name === feature.resource));
  if (!res) {
    return { enabled: false };
  }

  const ver = res?.data?.groupVersion?.split('/')[1].match(K8sVersionRegexp);

  return { enabled: true, preview: ver?.[2] === 'alpha' };
}
