import { css, SerializedStyles } from '@emotion/react';

import { COLORS, SHADES } from './_colors';
import { fontAvenir, fontAvenirBold } from './_fonts';

export enum TextWeight {
  Heavy = 'Heavy',
  Normal = 'Normal',
}

export enum TextSize {
  /**
   * @see 32px
   */
  Size1 = 'Size1',
  /**
   * @see 28px
   */
  Size2 = 'Size2',
  /**
   * @see 24px
   */
  Size3 = 'Size3',
  /**
   * @see 20px
   */
  Size4 = 'Size4',
  /**
   * @see 16px
   */
  Size5 = 'Size5',
  /**
   * @see 14px
   */
  Size6 = 'Size6',
  /**
   * @see 12px
   */
  Size7 = 'Size7',
  /**
   * @see 10px
   */
  Size8 = 'Size8',
}

type AllValuesOf<T> = T extends any ? T[keyof T] : never;
// nested type extractor based on "typeof COLORS extends any ? typeof COLORS[keyof typeof COLORS] : never;"
export type TextColors = AllValuesOf<AllValuesOf<typeof COLORS>> | AllValuesOf<AllValuesOf<typeof SHADES>>;

export interface TextProps {
  color?: TextColors;
  weight?: TextWeight;
  size?: TextSize;
}

const constructStyleWeight = ({ weight }: TextProps): SerializedStyles => {
  switch (weight) {
    case TextWeight.Heavy:
      return fontAvenirBold;
    case TextWeight.Normal:
    default:
      return fontAvenir;
  }
};

const constructStyleSize = ({ weight, size }: TextProps): SerializedStyles => {
  if (weight === TextWeight.Heavy) {
    switch (size) {
      case TextSize.Size1:
        return css`
          font-size: 32px;
        `;
      case TextSize.Size2:
        return css`
          font-size: 28px;
        `;
      case TextSize.Size3:
        return css`
          font-size: 24px;
        `;
      case TextSize.Size4:
        return css`
          font-size: 20px;
        `;
      case TextSize.Size5:
        return css`
          font-size: 16px;
        `;
      case TextSize.Size6:
        return css`
          font-size: 14px;
        `;
      case TextSize.Size7:
        return css`
          font-size: 12px;
        `;
      case TextSize.Size8:
      default:
        return css`
          font-size: 10px;
        `;
    }
  }
  // TextWeight.Normal
  switch (size) {
    case TextSize.Size1:
      return css`
        font-size: 24px;
      `;
    case TextSize.Size2:
      return css`
        font-size: 20px;
      `;
    case TextSize.Size3:
      return css`
        font-size: 16px;
      `;
    case TextSize.Size4:
      return css`
        font-size: 14px;
      `;
    case TextSize.Size5:
      return css`
        font-size: 12px;
      `;
    case TextSize.Size6:
    default:
      return css`
        font-size: 10px;
      `;
  }
};

export const createTextFont = <T extends TextProps>(props: T): SerializedStyles => {
  const { weight, size } = props;

  return css`
    line-height: 1.5;
    ${constructStyleWeight({ weight })};
    ${constructStyleSize({ weight, size })};
  `;
};

export const createTextStyles = <T extends TextProps>(props: T): SerializedStyles => {
  const { color } = props;

  return css`
    ${createTextFont(props)}
    color: ${color || COLORS.Neutral[1000]};
  `;
};
