import { z } from 'zod';

import { Condition, ControlPlaneSelector } from '../genericApiTypes';
import { api } from '../shared';

const Exporters = z.object({}).passthrough().optional();
export type SharedTelemetryConfigExporters = z.infer<typeof Exporters>;

const SharedTelemetryConfigProvisioningFailure = z.object({
  conditions: Condition.array().optional(),
  controlPlane: z.string().optional(),
});
export type SharedTelemetryConfigProvisioningFailure = z.infer<typeof SharedTelemetryConfigProvisioningFailure>;

export const SharedTelemetryConfig = z.object({
  apiVersion: z.literal('observability.spaces.upbound.io/v1alpha1'),
  kind: z.literal('SharedTelemetryConfig'),
  metadata: z
    .object({
      name: z.string(),
      namespace: z.string(),
      creationTimestamp: z.string().optional(),
    })
    .passthrough(),
  spec: z
    .object({
      controlPlaneSelector: ControlPlaneSelector,
      exportPipeline: z
        .object({
          metrics: z.string().array().optional(),
          traces: z.string().array().optional(),
          logs: z.string().array().optional(),
        })
        .passthrough(),
      exporters: Exporters,
    })
    .passthrough(),
  status: z
    .object({
      conditions: Condition.array().optional(),
      failed: SharedTelemetryConfigProvisioningFailure.array().optional(),
      provisioned: z.string().array().optional(),
      selectedControlPlanes: z.string().array().optional(),
    })
    .passthrough(),
});
export type SharedTelemetryConfig = z.infer<typeof SharedTelemetryConfig>;

const SharedTelemetryConfigResponse = SharedTelemetryConfig;
export const SharedTelemetryConfigListResponse = z.object({
  apiVersion: z.string(),
  items: SharedTelemetryConfig.array(),
  kind: z.string(),
  metadata: z.object({
    continue: z.string().optional(),
    resourceVersion: z.string().optional(),
  }),
});

export async function getSharedTelemetryConfigs(orgName: string, spaceName: string, groupName: string) {
  const url = `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
    spaceName,
  )}/apis/observability.spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(groupName)}/sharedtelemetryconfigs`;

  const res = await api()
    .get<unknown>(url)
    .then(r => r.data);

  return SharedTelemetryConfigListResponse.parse(res);
}

export async function getSharedTelemetryConfig(
  orgName: string,
  spaceName: string,
  groupName: string,
  sharedTelemetryConfigName: string,
) {
  const url = `/org/${encodeURIComponent(orgName)}/space/${encodeURIComponent(
    spaceName,
  )}/apis/observability.spaces.upbound.io/v1alpha1/namespaces/${encodeURIComponent(
    groupName,
  )}/sharedtelemetryconfigs/${encodeURIComponent(sharedTelemetryConfigName)}`;

  const res = await api()
    .get<unknown>(url)
    .then(r => r.data);

  return SharedTelemetryConfigResponse.parse(res);
}
