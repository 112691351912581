import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useQuery } from '@tanstack/react-query';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { MouseEvent, PropsWithChildren, useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { COLORS as SHARED_COLORS, fontAvenirBold } from 'upbound-frontend-constants';
import {
  faBriefcase,
  faCaretDown,
  faCaretUp,
  faCircleCheck,
  faCircleExclamation,
  faExternalLink,
  faPlusCircle,
  faQuestionCircle,
  faSlashForward,
  Header,
  Icon,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Span,
  useToast,
} from 'upbound-frontend-elements';

import arrowRightUp from '@/assets/icons/arrow-up-right.svg';
import ubcLogo from '@/assets/ubc-logo.svg';
import { AccountPopover } from '@/components/AccountPopover';
import { Spinner } from '@/components/LoadingSpinner/LoadingSpinner';
import { GroupIcon, SpaceIcon } from '@/components/Spaces/SpaceIcon';
import { MQ } from '@/constants/styledTheme';
import { COLORS, TIMING } from '@/constants/styles';
import { Anchor } from '@/elements/Anchor';
import { Button } from '@/elements/Button';
import { Flex } from '@/elements/Div';
import { IconName, InlineIcon } from '@/elements/Icon';
import { NavLink, NavLinkProps } from '@/elements/NavLink';
import { Paragraph } from '@/elements/Paragraph';
import { Tooltip as OldToolTip } from '@/elements/Tooltip';
import {
  Space,
  SpaceConnectionStatus,
  SpaceType,
  useGetOrgEnforcementInfoQuery,
  useGetSpacesNavQuery,
} from '@/generated/upbound-graphql';
import { enforcementLimitNotMet } from '@/graphql';
import { useCurrentAccount, useGetCurrentAccount } from '@/graphql/reactiveVars';
import { cloud, external } from '@/routes';
import { externalPortalLink, portalDashboard } from '@/routes/portal';
import { getSpaceGroupsQuery, selfSubjectAccessReviewQuery } from '@/services/spacesQueries';
import { isOrgAdmin } from '@/utils/accounts';
import { DefaultPollingInterval } from '@/utils/consts';
import { displayGenericEnforcementToast, useRouterParams } from '@/utils/hooks';
import { formatRegion } from '@/utils/spaces';

import { externalConsoleLink } from '../routes/console';
import { getDefaultDevMCPSpace } from '../utils/config';
import { Features, useSpacesApiFeatureEnabled } from '../utils/spaceFeatures';

const linkActiveStyle = css`
  --horizontalPadding: 10px;

  /* much shorter workaround instead of creating styled component with exposed props to prevent it from adding to DOM */
  &[data-has-name='true'] {
    --horizontalPadding: 15px;
  }

  padding-left: var(--horizontalPadding);
  padding-right: var(--horizontalPadding);
  height: 100%;
  border-radius: 8px;
  background: rgba(255, 255, 255, 0);
  transition: background ${TIMING.fast()} ease-in-out;
  white-space: nowrap;

  &.active,
  &:hover {
    background: ${SHARED_COLORS.Neutral[800]};
  }
`;

export const StyledLogoWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-right: 5px;
`;

export const StyledLogo = styled(Flex)`
  width: 36px;
  height: 36px;
  background-image: url(${ubcLogo.src});
  background-position: center;
  background-size: 36px;
  background-repeat: no-repeat;
`;

const StyledLink = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  position: relative;

  &:not(:first-of-type) {
    margin-top: 20px;

    ${MQ.m} {
      margin-top: 30px;
    }
  }
`;

const StyledAnchor = styled(Anchor)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  position: relative;

  &:not(:first-of-type) {
    margin-top: 20px;

    ${MQ.m} {
      margin-top: 30px;
    }
  }
`;

const ActiveStyledLink = styled(StyledLink)`
  ${linkActiveStyle}
`;

const ActiveStyledAnchor = styled(StyledAnchor)`
  ${linkActiveStyle}
  flex-direction: row;
`;

const ActiveStyledButton = styled(Button)`
  ${linkActiveStyle}
`;

export const NavContainer = styled(Flex)`
  position: relative;
  flex-direction: row;
  width: 100%;
  height: 100%;
  justify-content: space-between;
`;

export const NavLinks = styled('ul')`
  display: flex;
  flex-direction: row;
  margin-left: 7px;
  align-items: stretch;
  height: 100%;
  padding: 7px 0;

  p {
    ${fontAvenirBold};
    margin: 0 0 0 8px;
    line-height: 25px;
  }
`;

const NavIcon = styled(InlineIcon)<{ textItem?: boolean }>`
  height: 100%;
  max-height: ${({ textItem }) => (textItem ? '16' : '18')}px;
  aspect-ratio: 1 / 1;
  width: 100%;

  svg {
    height: 100%;
    max-height: 21px;
    width: 100%;
  }
`;

export const NavItem = styled('li')`
  list-style: none;
  padding: 0;
  max-height: 36px;

  &:not(:last-of-type) {
    margin-right: 5px;
  }
`;

export const MainNavLink: React.FC<
  NavLinkProps & {
    className?: string;
    iconName?: IconName;
    name?: string;
    subtitle?: string;
    id?: string;
    tooltip?: string;
    external?: boolean;
  }
> = ({ className, iconName, name, subtitle, href, tooltip, external: isExternal, ...props }) => {
  const tooltipName = `tooltip-${encodeURIComponent(name || iconName || '')}`;

  return (
    <NavItem data-for={tooltipName} data-tip={tooltip}>
      <ActiveStyledLink data-has-name={!!name} className={className} href={href} role="menuitem" {...props}>
        {!!iconName && <NavIcon name={iconName} textItem />}
        {!!name && (
          <Span className="hidden lg:block" textSize="Size6" textWeight="Heavy" ml="2" color={SHARED_COLORS.Neutral[0]}>
            {name}
          </Span>
        )}
        {!!subtitle && (
          <Span className="hidden lg:block" textSize="Size7" textWeight="Heavy" ml="2" color={SHARED_COLORS.Neutral[0]}>
            {subtitle}
          </Span>
        )}
      </ActiveStyledLink>
      {!!tooltip && (
        <OldToolTip
          id={tooltipName}
          place="bottom"
          effect="solid"
          getContent={dataTip => (
            <>
              {dataTip}
              {!!isExternal && (
                <Image style={{ marginLeft: '5px', display: 'inline-block' }} src={arrowRightUp} alt="arrow" />
              )}
            </>
          )}
        />
      )}
    </NavItem>
  );
};

export const MainNavAnchor: React.FC<{
  className?: string;
  iconName?: IconName;
  name?: string;
  subtitle?: string;
  href: string;
  id?: string;
  tooltip?: string;
  target?: string;
  external?: boolean;
}> = ({ className, iconName, name, subtitle, href, tooltip, external: isExternal, ...props }) => {
  const tooltipName = `tooltip-${encodeURIComponent(name || iconName || '')}`;

  return (
    <NavItem data-for={tooltipName} data-tip={tooltip}>
      <ActiveStyledAnchor data-has-name={!!name} className={className} href={href} {...props}>
        {!!iconName && <NavIcon name={iconName} />}
        {!!name && (
          <Span className="hidden lg:block" textSize="Size6" textWeight="Heavy" ml="2" color={SHARED_COLORS.Neutral[0]}>
            {name}
          </Span>
        )}
        {!!subtitle && (
          <Span className="hidden lg:block" textSize="Size7" textWeight="Heavy" ml="2" color={SHARED_COLORS.Neutral[0]}>
            {subtitle}
          </Span>
        )}
      </ActiveStyledAnchor>
      {!!tooltip && (
        <OldToolTip
          id={tooltipName}
          place="bottom"
          effect="solid"
          getContent={dataTip => (
            <>
              {dataTip}
              {!!isExternal && (
                <Image style={{ marginLeft: '5px', display: 'inline-block' }} src={arrowRightUp} alt="arrow" />
              )}
            </>
          )}
        />
      )}
    </NavItem>
  );
};

export const MainNavButton: React.FC<{
  className?: string;
  iconName?: IconName;
  name?: string;
  subtitle?: string;
  id?: string;
  tooltip?: string;
  target?: string;
  external?: boolean;
  onClick?: () => void;
  color?: COLORS;
}> = ({
  className,
  iconName,
  name,
  subtitle,
  onClick,
  tooltip,
  external: isExternal,
  color = 'lightBlueGrey',
  ...props
}) => {
  const tooltipName = `tooltip-${encodeURIComponent(name || iconName || '')}`;

  return (
    <NavItem data-for={tooltipName} data-tip={tooltip}>
      <ActiveStyledButton
        display="inline-flex"
        alignItems="center"
        btnType="noStyle"
        data-has-name={!!name}
        className={className}
        onClick={onClick}
        {...props}
      >
        {!!iconName && <NavIcon name={iconName} />}
        {!!name && (
          <Paragraph size="small" lineHeight="18px" mt="8px" color={color}>
            {name}
          </Paragraph>
        )}
        {!!subtitle && (
          <Paragraph size="small" color={color}>
            {subtitle}
          </Paragraph>
        )}
      </ActiveStyledButton>

      {!!tooltip && (
        <OldToolTip
          id={tooltipName}
          place="bottom"
          effect="solid"
          getContent={dataTip => (
            <>
              {dataTip}
              {!!isExternal && (
                <Image style={{ marginLeft: '5px', display: 'inline-block' }} src={arrowRightUp} alt="arrow" />
              )}
            </>
          )}
        />
      )}
    </NavItem>
  );
};

const AdminDropdownItem: React.FC<{ title: string; href: string }> = ({ title, href }) => {
  return (
    <Link
      className="block text-neutral-0 text-sm px-2.5 py-1.5 hover:bg-neutral-shades-850 rounded-md transition-colors"
      href={href}
      target="_blank"
    >
      <div className="flex flex-row justify-between">
        {title} <Icon icon={faExternalLink} color={SHARED_COLORS.Neutral[500]} />
      </div>
    </Link>
  );
};

export const MainNavAdministrationDropdown: React.FC<PropsWithChildren> = () => {
  const router = useRouter();
  const account = useGetCurrentAccount();
  const [showAdministrationDropdown, setShowAdministrationDropdown] = useState(false);
  const administrationDropdownIcon = showAdministrationDropdown ? faCaretUp : faCaretDown;

  const showBg = router.asPath.startsWith(cloud.administrationIndex.url(account.id)) || showAdministrationDropdown;
  const dlClassName = 'border-b border-solid border-neutral-shades-850 last:border-b-0';
  const dtClassName = 'text-xs text-neutral-400 font-bold mb-1 ml-2.5';

  return (
    <>
      <Popover open={showAdministrationDropdown} onOpenChange={setShowAdministrationDropdown}>
        <PopoverTrigger asChild>
          <NavItem>
            <button
              id="space-dropdown-button"
              className={twMerge(
                'flex flex-row text-neutral-0 center items-center whitespace-nowrap justify-center h-full  rounded-lg font-bold text-sm py-2 pl-[15px] pr-[15px] hover:bg-neutral-800',
                showBg && 'bg-neutral-800',
              )}
            >
              <Icon icon={faBriefcase} className="lg:mr-2 !text-base" />
              <span className="hidden lg:block">Administration</span>
              <Icon icon={administrationDropdownIcon} className="lg:ml-2 !text-md hidden lg:block" />
            </button>
          </NavItem>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          className="bg-neutral-800 p-2.5 rounded-lg mt-[5px] min-w-[210px] text-neutral-0 focus-visible:outline-none relative !z-[100] overflow-y-auto max-h-[80vh] font-normal"
        >
          <dl className={dlClassName}>
            <dt className={dtClassName}>User Management</dt>
            <dd>
              <AdminDropdownItem
                title="All Users"
                href={external.accountsOrgMembers.url(account.id, window.location.origin)}
              />
            </dd>
            <dd>
              <AdminDropdownItem
                title="Team Manager"
                href={external.accountsTeams.url(account.id, window.location.origin)}
              />
            </dd>
            <dd>
              <AdminDropdownItem
                title="Robots"
                href={external.accountsRobots.url(account.id, window.location.origin)}
              />
            </dd>
          </dl>
        </PopoverContent>
      </Popover>
    </>
  );
};

const spaceTypeMap: { [Property in SpaceType]: string } = {
  [SpaceType.Legacy]: 'Legacy Space',
  [SpaceType.Connected]: 'Connected Space',
  [SpaceType.Managed]: 'Cloud Space',
};

const SpaceStatus: React.FC<{
  status?: SpaceConnectionStatus | null;
  type: SpaceType;
}> = ({ status, type }) => {
  switch (status) {
    case SpaceConnectionStatus.Connected:
      return (
        <div className="flex flex-row items-center text-neutral-shades-150 text-xs font-normal">
          <Icon icon={faCircleCheck} className="mr-1 !font-xs !text-green-400" />
          {spaceTypeMap[type]}
        </div>
      );
    case SpaceConnectionStatus.Unreachable:
      return (
        <div className="flex flex-row items-center text-neutral-shades-150 text-xs font-normal">
          <Icon icon={faCircleExclamation} className="mr-1 !font-xs !text-red-400" />
          {spaceTypeMap[type]}
        </div>
      );
    default:
      return (
        <div className="flex flex-row items-center text-neutral-shades-150 text-xs font-normal">
          <Icon icon={faQuestionCircle} className="mr-1 !font-xs !text-neutral-400" />
          {spaceTypeMap[type]}
        </div>
      );
  }
};

const SpaceTitleBar: React.FC<{
  space: Space;
}> = ({ space }) => {
  if (space.type === SpaceType.Connected) {
    return <>{space.name}</>;
  }

  return (
    <>
      {!!space.provider ? `Upbound ${space.provider}` : space.name}
      {!!space.region && ' ' + formatRegion(space.region)}
    </>
  );
};

const SpaceDropdownLink: React.FC<{
  space: Space;
  active: boolean;
  onSpaceClick: (space: Space) => void;
}> = ({ space, active, onSpaceClick }) => {
  const currentAccount = useGetCurrentAccount();
  const { toast } = useToast();

  const isLimitedTier = ['free', 'team'].includes(currentAccount.organization.tier);
  const isDevMcpSpace = getDefaultDevMCPSpace() === space.name;

  const spaceDisabled = isLimitedTier && !isDevMcpSpace;

  const handleSpaceClick = (e: MouseEvent) => {
    if (spaceDisabled) {
      e.preventDefault();

      displayGenericEnforcementToast(toast, 'FeatureDisabled');

      return;
    }
    onSpaceClick(space);
  };

  return (
    <Link
      key={space.id}
      href={cloud.spaceView.url(currentAccount.id, space.name)}
      onClick={handleSpaceClick}
      className={twMerge(
        'hover:cursor-pointer flex flex-row hover:bg-neutral-shades-850 rounded-lg px-2 py-2 mt-2 items-center',
        active && 'bg-neutral-shades-850',
        spaceDisabled && 'opacity-50 hover:cursor-not-allowed',
      )}
    >
      <SpaceIcon space={space} className="mr-2 w-8" />
      <div className="flex-grow">
        <Paragraph className="!text-neutral-0">
          <SpaceTitleBar space={space} />
        </Paragraph>
        <SpaceStatus status={space.status.status} type={space.type} />
      </div>
    </Link>
  );
};

const MainNavSpacesDropdown: React.FC<PropsWithChildren> = () => {
  const { toast } = useToast();
  const { orgName, spaceName, groupName } = useRouterParams([
    {
      name: 'orgName',
      disableThrowOnMissing: true,
    },
    {
      name: 'spaceName',
      disableThrowOnMissing: true,
    },
    {
      name: 'groupName',
      disableThrowOnMissing: true,
    },
  ]);
  const account = useGetCurrentAccount();
  const currentAccount = useGetCurrentAccount();

  const [showSpacesDropdownPopover, setShowSpacesDropdownPopover] = useState(false);
  const [showGroupsDropdownPopover, setShowGroupsDropdownPopover] = useState(false);
  const spaceDropdownIcon = showSpacesDropdownPopover ? faCaretUp : faCaretDown;
  const groupDropdownIcon = showGroupsDropdownPopover ? faCaretUp : faCaretDown;

  const { data: spacesData, loading: isSpacesLoading } = useGetSpacesNavQuery({
    variables: {
      account: account.id,
    },
    pollInterval: DefaultPollingInterval,
    skip: !spaceName,
  });

  const spaces = spacesData?.account?.spaces.filter((space): space is Space => space.__typename === 'Space') ?? [];

  const currentSpace = spaces.find(space => space.name === spaceName);

  const connectedSpaces = spaces.filter(space => space.type === SpaceType.Connected);
  const cloudSpaces = spaces.filter(space => space.type !== SpaceType.Connected);

  const currentSpaceDisplayName = useMemo(() => {
    if (!currentSpace || currentSpace.type === SpaceType.Connected || !currentSpace.provider || !currentSpace.region) {
      return spaceName;
    }

    return `Upbound ${currentSpace.provider} ${formatRegion(currentSpace.region)}`;
  }, [currentSpace]);

  const { data: canCreateGroupIam } = useQuery({
    ...selfSubjectAccessReviewQuery(orgName, spaceName, { type: 'namespaces', verb: 'create' }),
    enabled: currentSpace && currentSpace.type !== SpaceType.Legacy,
  });

  const { data: enforcementData } = useGetOrgEnforcementInfoQuery({ variables: { id: orgName }, skip: !orgName });

  const hasPermsToCreateGroup = currentSpace && canCreateGroupIam;
  const tierAllowsGroupCreation =
    hasPermsToCreateGroup && enforcementLimitNotMet(enforcementData, 'controlPlaneGroups');

  const { data: groups, isLoading: isGroupsLoading } = useQuery(
    getSpaceGroupsQuery(orgName, spaceName, currentSpace?.type),
  );

  const currentGroup = groupName ? groups?.find(group => group.metadata.name === groupName) : undefined;

  const spaceLoading = isSpacesLoading && !spacesData;
  const groupDataLoading = isGroupsLoading && !groups;

  const onButtonClick = () => {
    setShowSpacesDropdownPopover(!showSpacesDropdownPopover);
  };

  if (!spaceName) {
    return null;
  }

  const isLimitedTier = ['free', 'team'].includes(currentAccount.organization.tier);
  const isDevMcpSpace = getDefaultDevMCPSpace() === spaceName;

  const spaceDisabled = isLimitedTier && !isDevMcpSpace;

  const onSpaceClick = (_space: Space) => {
    setShowSpacesDropdownPopover(false);
  };

  const onGroupClick = (e: MouseEvent) => {
    if (spaceDisabled) {
      e.preventDefault();

      return;
    }
    setShowGroupsDropdownPopover(!showGroupsDropdownPopover);
  };

  return (
    <>
      <Popover open={showSpacesDropdownPopover} onOpenChange={setShowSpacesDropdownPopover}>
        <PopoverTrigger disabled={!spaces?.length} asChild>
          <NavItem>
            <button
              onClick={onButtonClick}
              id="space-dropdown-button"
              className={twMerge(
                'flex flex-row items-center whitespace-nowrap justify-center h-full text-neutral-0 bg-neutral-800 rounded-lg font-bold py-2 px-4 gap-2',
                spaceDisabled && 'opacity-50',
              )}
            >
              {isSpacesLoading ? (
                <Spinner className="!w-5" />
              ) : (
                <SpaceIcon space={currentSpace} className="rounded-md" />
              )}
              <span className={twMerge('text-sm', !currentSpaceDisplayName && 'opacity-50')}>
                {currentSpaceDisplayName || spaceName}
              </span>
              {!!currentSpace && <Icon icon={spaceDropdownIcon} />}
            </button>
          </NavItem>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          sideOffset={-1}
          className="bg-neutral-800 p-2.5 rounded-lg mt-[5px] min-w-[300px] text-neutral-0 focus-visible:outline-none relative !z-[100] overflow-y-auto max-h-[80vh]"
        >
          <div className="flex flex-row justify-between items-start px-2">
            <div>
              <Header type="h6" className="!text-neutral-0">
                Spaces
              </Header>
            </div>
            {!isLimitedTier && (
              <Link
                href={cloud.attachSpace.url(account.id)}
                className="!text-neutral-0 text-xs hover:underline font-normal ml-4"
              >
                <Icon icon={faPlusCircle} className="mr-1" />
                Attach Space
              </Link>
            )}
          </div>
          {spaceLoading ? (
            <Spinner />
          ) : (
            <>
              {cloudSpaces.map(space => (
                <SpaceDropdownLink
                  key={space.name}
                  space={space}
                  active={space.name === spaceName}
                  onSpaceClick={onSpaceClick}
                />
              ))}
              {!!connectedSpaces &&
                connectedSpaces
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(space => (
                    <SpaceDropdownLink
                      key={space.name}
                      space={space}
                      active={space.name === spaceName}
                      onSpaceClick={onSpaceClick}
                    />
                  ))}
            </>
          )}
        </PopoverContent>
      </Popover>

      <Icon icon={faSlashForward} className="text-neutral-500 mx-1 justify-center flex items-center" />
      <Popover open={showGroupsDropdownPopover} onOpenChange={setShowGroupsDropdownPopover}>
        <PopoverTrigger disabled={!currentGroup}>
          <NavItem>
            <button
              onClick={onGroupClick}
              id="group-dropdown-button"
              className={twMerge(
                'flex flex-row items-center whitespace-nowrap justify-center h-full text-neutral-0 bg-neutral-800 rounded-lg font-bold py-2 px-4 gap-2',
                spaceDisabled && 'opacity-50 hover:cursor-not-allowed',
              )}
            >
              {!currentGroup && (
                <>
                  {isGroupsLoading || isSpacesLoading ? (
                    <>
                      <Spinner className="opacity-50" />
                      <span className="text-sm opacity-50">Loading Groups</span>
                    </>
                  ) : (
                    <span className="text-sm opacity-50">No Groups Found</span>
                  )}
                </>
              )}
              {!!currentGroup && (
                <>
                  <span className="text-sm">{currentGroup.metadata.name}</span>
                  <Icon icon={groupDropdownIcon} />
                </>
              )}
            </button>
          </NavItem>
        </PopoverTrigger>
        <PopoverContent
          align="start"
          sideOffset={-1}
          className="bg-neutral-800 p-2.5 rounded-lg mt-[5px] min-w-[300px] text-neutral-0 focus-visible:outline-none relative !z-[100] overflow-y-auto max-h-[80vh]"
          // Note: To keep tooltip below from auto-opening. See: https://github.com/radix-ui/primitives/issues/2248
          onOpenAutoFocus={event => event.preventDefault()}
        >
          <div className="flex flex-row justify-between items-start px-2">
            <div>
              <Header type="h6" className="!text-neutral-0">
                Groups
              </Header>
            </div>
            {!!currentSpace && (
              <Link
                href={cloud.spaceGroupCreate.url(account.id, currentSpace.name)}
                className="!text-neutral-0 text-xs font-normal ml-4 hover:underline"
                onClick={e => {
                  if (!hasPermsToCreateGroup) {
                    e.preventDefault();
                    toast({
                      title: 'Not Allowed',
                      description: 'Insufficient permissions to create a Control Plane Group',
                    });
                    return;
                  }

                  if (!tierAllowsGroupCreation) {
                    e.preventDefault();
                    displayGenericEnforcementToast(toast, 'LimitReached');
                    return;
                  }
                }}
              >
                <Icon icon={faPlusCircle} className="mr-1" />
                Create Group
              </Link>
            )}
          </div>
          {groupDataLoading ? (
            <Spinner />
          ) : (
            groups?.map(group => (
              <Link
                key={group.metadata.uid}
                href={cloud.spaceGroupView.url(account.id, spaceName, group.metadata.name)}
                onClick={() => setShowGroupsDropdownPopover(false)}
                className={twMerge(
                  'flex flex-row hover:bg-neutral-shades-850 rounded-lg px-2 py-2 my-2 items-center',
                  group.metadata.name === groupName && 'bg-neutral-shades-850',
                )}
              >
                <GroupIcon name={group.metadata.name} className="mr-2 w-8" />
                <div>
                  <Paragraph className="!text-neutral-0">{group.metadata.name}</Paragraph>
                </div>
              </Link>
            ))
          )}
        </PopoverContent>
      </Popover>
    </>
  );
};

export const LogoLink: React.FC<{ hideRoutes?: boolean; isPortal?: boolean }> = ({ hideRoutes, isPortal }) => {
  const { orgName, spaceName, groupName } = useRouterParams([
    {
      name: 'orgName',
      disableThrowOnMissing: true,
    },
    {
      name: 'spaceName',
      disableThrowOnMissing: true,
    },
    {
      name: 'groupName',
      disableThrowOnMissing: true,
    },
  ]);

  if (isPortal) {
    return (
      <StyledLogoWrapper>
        {!!hideRoutes ? (
          <StyledLogo title="Upbound Portal" id="upbound_logo-cloud" />
        ) : (
          <NavLink href={portalDashboard.url(orgName)}>
            <StyledLogo title="Upbound Portal" id="upbound_logo-cloud" />
          </NavLink>
        )}
      </StyledLogoWrapper>
    );
  }

  if (orgName && spaceName && groupName && !hideRoutes) {
    return (
      <StyledLogoWrapper>
        <NavLink href={cloud.spaceGroupView.url(orgName, spaceName, groupName)}>
          <StyledLogo title="Upbound Cloud" id="upbound_logo-cloud" />
        </NavLink>
      </StyledLogoWrapper>
    );
  }

  return (
    <StyledLogoWrapper>
      {!!hideRoutes ? (
        <StyledLogo title="Upbound Cloud" id="upbound_logo-cloud" />
      ) : (
        <NavLink href={cloud.spacesIndex.url(orgName)}>
          <StyledLogo title="Upbound Cloud" id="upbound_logo-cloud" />
        </NavLink>
      )}
    </StyledLogoWrapper>
  );
};

export const MainNav: React.FC<{ hideRoutes?: boolean }> = ({ hideRoutes }) => {
  const url = new URL(window.location.href);
  const { orgName, spaceName, groupName } = useRouterParams([
    {
      name: 'orgName',
      disableThrowOnMissing: true,
    },
    {
      name: 'spaceName',
      disableThrowOnMissing: true,
    },
    {
      name: 'groupName',
      disableThrowOnMissing: true,
    },
  ]);
  const isConsole = url.hostname.startsWith('console.');
  const isPortal = url.hostname.startsWith('portal.');

  const [account] = useCurrentAccount();
  const queryApiAvailable = useSpacesApiFeatureEnabled(orgName, spaceName, Features.queryApi);

  if (!orgName) {
    return null;
  }

  const showDropdown = !!spaceName;
  const showAdmin = isOrgAdmin(account);

  const showPortal = !hideRoutes && (queryApiAvailable?.enabled === true || isPortal);

  const consoleLink =
    orgName && spaceName && groupName
      ? cloud.spaceGroupView.url(orgName, spaceName, groupName)
      : cloud.spacesIndex.url(orgName);

  const navConsoleLink = isPortal ? `${externalConsoleLink()}${consoleLink}` : consoleLink;

  return (
    <NavContainer>
      <Flex>
        <LogoLink hideRoutes={hideRoutes} isPortal={isPortal} />
        {!hideRoutes && (
          <NavLinks id="navigation_main-cloud">
            <MainNavLink
              iconName="grid"
              href={navConsoleLink}
              name="Console"
              className={twMerge(isConsole && '!bg-neutral-800')}
            />
            {!!showDropdown && !!isConsole && <MainNavSpacesDropdown />}
          </NavLinks>
        )}
      </Flex>
      <Flex>
        <NavLinks>
          {!!showPortal && (
            <MainNavLink
              iconName="sideBar"
              href={externalPortalLink(orgName)}
              name="Consumer Portal"
              className={twMerge(isPortal && '!bg-neutral-800')}
            />
          )}
          {!!showAdmin && <MainNavAdministrationDropdown />}
          <MainNavAnchor
            iconName="circleQuestion"
            href={external.docsHome.url()}
            id="docs-and-support-link"
            target="_blank"
            name="Help & Support"
            external={true}
          />
        </NavLinks>
        <Flex marginLeft="30px">
          <AccountPopover />
        </Flex>
      </Flex>
    </NavContainer>
  );
};
