import { gql } from '@apollo/client';

export const typeDefs = gql`
  extend interface BaseUser {
    name: String!
  }

  extend type CurrentUser {
    name: String!
  }

  extend type User {
    name: String!
  }

  type FunctionSpec {
    package: String!
  }

  type XgqlFunction implements Node & KubernetesResource {
    "An opaque identifier that is unique across all types."
    id: ID!
    "The underlying Kubernetes API version of this resource."
    apiVersion: String!
    "The underlying Kubernetes API kind of this resource."
    kind: String!
    "Metadata that is common to all Kubernetes API resources."
    metadata: ObjectMeta!
    "The desired state of this resource."
    spec: FunctionSpec!
    "An unstructured JSON representation of the underlying Kubernetes resource."
    unstructured: JSON!
    "Events pertaining to this resource."
    events: EventConnection! @goField(forceResolver: true)
  }

  type CompositionRevision implements Node & KubernetesResource {
    "An opaque identifier that is unique across all types."
    id: ID!
    "The underlying Kubernetes API version of this resource."
    apiVersion: String!
    "The underlying Kubernetes API kind of this resource."
    kind: String!
    "Metadata that is common to all Kubernetes API resources."
    metadata: ObjectMeta!
    "An unstructured JSON representation of the underlying Kubernetes resource."
    unstructured: JSON!
    "Events pertaining to this resource."
    events: EventConnection! @goField(forceResolver: true)
  }

  type FunctionRevision implements Node & KubernetesResource {
    "An opaque identifier that is unique across all types."
    id: ID!
    "The underlying Kubernetes API version of this resource."
    apiVersion: String!
    "The underlying Kubernetes API kind of this resource."
    kind: String!
    "Metadata that is common to all Kubernetes API resources."
    metadata: ObjectMeta!
    "An unstructured JSON representation of the underlying Kubernetes resource."
    unstructured: JSON!
    "Events pertaining to this resource."
    events: EventConnection! @goField(forceResolver: true)
  }
`;
