import { COLORS } from '@/constants/styles';

import { EventType } from '../generated/upbound-graphql';

export function getRandomInteger(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined;
}

export function sortArray<T>({ arr, key }: { arr?: T[]; key: keyof T }) {
  if (!arr) {
    return [];
  }

  return [...arr].sort((a, b) => {
    const aKey = a[key];
    const bKey = b[key];

    if (typeof aKey === 'undefined') {
      return -1;
    }

    if (typeof aKey === 'string' && typeof bKey === 'string') {
      return aKey.localeCompare(bKey);
    }

    if (typeof aKey === 'number' && typeof bKey === 'number') {
      return aKey - bKey;
    }

    return 0;
  });
}

export function shuffleArray<T = any[]>(a: T[]) {
  const array = [...a];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

export function format<V extends string, T extends Array<string>>(unformattedValue: V, params: T) {
  const variables = unformattedValue.match(/\$\{\d+\}/g);

  if (variables) {
    const formattedValue = variables.reduce((temporaryValue, variable) => {
      const [stringVariableIndex] = variable.match(/\d+/) || [];

      if (stringVariableIndex) {
        const variableIndex = parseInt(stringVariableIndex, 10);

        if (variableIndex < params.length) {
          const variableValue = params[variableIndex];
          const formattedTemporaryValue = temporaryValue.replace(variable, variableValue);
          return formattedTemporaryValue;
        }
      }

      return temporaryValue;
    }, unformattedValue);

    if (formattedValue !== unformattedValue) {
      return formattedValue;
    }
  }

  return unformattedValue;
}

export function debounce<F extends (...params: any[]) => void>(fn: F, delay: number) {
  let timeoutID: number;

  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutID);
    timeoutID = window.setTimeout(() => fn.apply(this, args), delay);
  } as F;
}

export function firstDefined(...params: Array<any | undefined>) {
  return params.find(p => !!p);
}

export function handleResourceStatus(status?: boolean | null) {
  if (typeof status === 'undefined' || status === null) {
    return 3;
  }

  return status ? 1 : 0;
}

export const getBorderColorIndicatorBasedOnType = (type?: EventType | null): string => {
  switch (type) {
    case EventType.Normal:
      return COLORS.fern;
    case EventType.Warning:
      return COLORS.burntSienna;
    default:
      return COLORS.lightPeriwinkle;
  }
};
