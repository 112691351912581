import { FilterFn, Row } from '@tanstack/react-table';
import { isDateValid } from 'packages/constants';

export const filterColumnByDateRange: FilterFn<any> = (
  row: Row<any>,
  columnId: string,
  filterValue: { startDate: Date | null; endDate: Date | null },
) => {
  const { startDate, endDate } = filterValue;

  if (!startDate || !isDateValid(startDate) || !endDate || !isDateValid(endDate)) {
    return true;
  }

  const date = new Date(row.getValue(columnId));
  return date >= startDate && date <= endDate;
};

export const filterColumnByArrayValues: FilterFn<any> = (row: Row<any>, columnId: string, arrayOfValues: string[]) => {
  if (arrayOfValues.length) {
    const rowValue = row.getValue(columnId) as string;
    return arrayOfValues.filter(val => val.toLowerCase() === rowValue?.toLowerCase()).length > 0;
  }

  return true;
};

// Sort by secondary column if primary columns are equal
export const secondarySortByColumn = (
  secondaryColumnId: string,
): ((rowA: Row<any>, rowB: Row<any>, columnId: string) => number) => {
  const sortFunc = (rowA: Row<any>, rowB: Row<any>, columnId: string): number => {
    const rowAPrimaryColumnValue = rowA.getValue(columnId) as string;
    const rowBPrimaryColumnValue = rowB.getValue(columnId) as string;

    const rowASecondaryColumnValue = rowA.getValue(secondaryColumnId) as string;
    const rowBSecondaryColumnValue = rowB.getValue(secondaryColumnId) as string;

    if (rowAPrimaryColumnValue === rowBPrimaryColumnValue) {
      return rowASecondaryColumnValue > rowBSecondaryColumnValue ? 1 : -1;
    }
    return rowAPrimaryColumnValue > rowBPrimaryColumnValue ? 1 : -1;
  };

  return sortFunc;
};
