import { getPortalHost } from '../utils/config';
import { generatePath } from '.';

export const externalPortalLink = (orgName: string) => {
  return `https://${getPortalHost()}${portalDashboard.url(orgName)}`;
};

export const portalDashboard = {
  url: (orgName: string) => generatePath(portalDashboard.route, { orgName }),
  route: '/:orgName' as const,
};

export const portalDashboardControlPlane = {
  url: (cpId: `${string}:${string}:${string}:${string}`) => {
    const [orgName] = cpId.split(':');

    return generatePath(portalDashboardControlPlane.route, { orgName, cpId });
  },
  route: '/:orgName/:cpId' as const,
};

export const portalDashboardControlPlaneServiceResources = {
  url: (cpId: `${string}:${string}:${string}:${string}`, xrdName: string) => {
    const [orgName] = cpId.split(':');

    return generatePath(portalDashboardControlPlaneServiceResources.route, { orgName, cpId, xrdName });
  },
  route: '/:orgName/:cpId/services/:xrdName' as const,
};

export const portalDashboardControlPlaneServiceClaim = {
  url: (cpId: `${string}:${string}:${string}:${string}`, xrdName: string, xrcId: `${string}:${string}`) => {
    const [orgName] = cpId.split(':');

    return generatePath(portalDashboardControlPlaneServiceClaim.route, { orgName, cpId, xrdName, xrcId });
  },
  route: '/:orgName/:cpId/services/:xrdName/claims/:xrcId' as const,
};

export const portalDashboardControlPlaneServiceClaimCreate = {
  url: (cpId: `${string}:${string}:${string}:${string}`, xrdName: string) => {
    const [orgName] = cpId.split(':');

    return generatePath(portalDashboardControlPlaneServiceClaimCreate.route, { orgName, cpId, xrdName });
  },
  route: '/:orgName/:cpId/services/:xrdName/claims/create' as const,
};

export const portalDashboardControlPlaneServiceClaimEvents = {
  url: (cpId: `${string}:${string}:${string}:${string}`, xrdName: string, xrcId: `${string}:${string}`) => {
    const [orgName] = cpId.split(':');

    return generatePath(portalDashboardControlPlaneServiceClaimEvents.route, { orgName, cpId, xrdName, xrcId });
  },
  route: '/:orgName/:cpId/services/:xrdName/claims/:xrcId/events' as const,
};

export const portalDashboardControlPlaneServiceClaimSettings = {
  url: (cpId: `${string}:${string}:${string}:${string}`, xrdName: string, xrcId: `${string}:${string}`) => {
    const [orgName] = cpId.split(':');

    return generatePath(portalDashboardControlPlaneServiceClaimSettings.route, { orgName, cpId, xrdName, xrcId });
  },
  route: '/:orgName/:cpId/services/:xrdName/claims/:xrcId/settings' as const,
};

export const portalDashboardControlPlaneServiceClaimDetails = {
  url: (cpId: `${string}:${string}:${string}:${string}`, xrdName: string, xrcId: `${string}:${string}`) => {
    const [orgName] = cpId.split(':');

    return generatePath(portalDashboardControlPlaneServiceClaimDetails.route, { orgName, cpId, xrdName, xrcId });
  },
  route: '/:orgName/:cpId/services/:xrdName/claims/:xrcId/details' as const,
};
