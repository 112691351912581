/* eslint-disable @typescript-eslint/prefer-literal-enum-member */
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { shouldForwardProp } from 'packages/constants';
import React, { HTMLAttributes } from 'react';
import * as styledSystem from 'styled-system';

import {
  Button,
  ButtonType,
  PrimaryActiveBackgroundColor,
  PrimaryBorderColor,
  PrimaryColor,
  SecondaryActiveBackgroundColor,
  SecondaryBorderColor,
  SecondaryColor,
} from '../Button/Button';

const baseStyledSystem = styledSystem.compose(styledSystem.display, styledSystem.position, styledSystem.space);

export enum ButtonGroupDirection {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

export interface CustomButtonGroupProps {
  btnDirection?: keyof typeof ButtonGroupDirection;
  btnType?: keyof typeof ButtonType;
}

interface ButtonGroupProps
  extends Omit<HTMLAttributes<HTMLElement>, 'color'>,
    styledSystem.DisplayProps,
    styledSystem.SpaceProps,
    styledSystem.PositionProps,
    CustomButtonGroupProps {}

/**
 * @default
 */
const StyledButtonGroup = styled('div', { shouldForwardProp })<ButtonGroupProps>`
  ${baseStyledSystem}

  display: flex;
  flex-direction: row;

  & > ${Button}:hover {
    z-index: 2;
  }

  & > ${Button}:focus, & > ${Button}:active, & > ${Button}:hover {
    ${({ btnType }) => {
      if (btnType === ButtonType.Primary) {
        return css`
          background-color: ${PrimaryActiveBackgroundColor} !important;
          border-color: ${PrimaryBorderColor} !important;
          color: ${PrimaryColor} !important;
        `;
      }

      return css`
        background-color: ${SecondaryActiveBackgroundColor} !important;
        border-color: ${SecondaryBorderColor} !important;
        color: ${SecondaryColor} !important;
      `;
    }}
  }

  ${({ btnDirection }) => {
    if (btnDirection === ButtonGroupDirection.Vertical) {
      return css`
        flex-direction: column;

        & > ${Button} {
          &:not(:last-child) {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
          }

          &:not(:first-of-type) {
            border-top-left-radius: 0;
            border-top-right-radius: 0;

            margin-top: -2px;
          }
        }
      `;
    }

    return css`
      flex-direction: row;

      & > ${Button} {
        &:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }

        &:not(:first-of-type) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;

          margin-left: -2px;
        }
      }
    `;
  }}
`;

type ButtonGroupChild = React.ReactElement<any, typeof Button>;

const ButtonGroup: React.FC<
  ButtonGroupProps & {
    children: ButtonGroupChild | ButtonGroupChild[];
  }
> = ({ children, btnDirection = ButtonGroupDirection.Horizontal, btnType = 'Primary', ...rest }) => {
  return (
    <StyledButtonGroup btnDirection={btnDirection} btnType={btnType} {...rest}>
      {children}
    </StyledButtonGroup>
  );
};

ButtonGroup.displayName = 'ButtonGroup';

export { ButtonGroup };
