import axios, { AxiosInstance } from 'axios';
import { ZodError } from 'zod';

import { isAxiosError } from '@/utils/axios';
import { getApiServer, getBaseDomain } from '@/utils/config';
import { isEnforcementError } from '@/utils/hooks';
import { log } from '@/utils/log';

let apiInstance: AxiosInstance;
let proxyApiInstance: AxiosInstance;

export const proxyApi = () => {
  if (!proxyApiInstance) {
    // We don't have a local proxy for the mcp-router proxy api, so we need to
    // manually adjust the base domain to point to Dev if on local
    const baseDomain = getBaseDomain().replace('local.', '');

    // We don't intend to rely on the mcp-router proxy api for long term,
    // so rather than create a new, configurable env variable for the proxy domain
    // we've decided to construct the URL using the existing baseDomain var
    const BASE_URL = `https://proxy.${baseDomain}`;

    proxyApiInstance = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
  }

  return proxyApiInstance;
};

export const api = () => {
  if (!apiInstance) {
    const BASE_URL = getApiServer();

    apiInstance = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
      },
    });
  }

  return apiInstance;
};

// No point in retrying if zod validation fails
function isZodError(error: any) {
  return error instanceof ZodError;
}

/** Retry handler for TanStack query that allows retry  */
export function shouldRetry(failureCount: number, error: Error) {
  if (isZodError(error)) {
    // Logging enabled for dev env
    log(error.message);
    return false;
  }

  const non500 = isAxiosError(error) ? !error.response?.status || error.response.status < 500 : true;
  const enforcementError = isEnforcementError(error);
  const retryable = !enforcementError && non500;

  if (!retryable) {
    return false;
  }
  return failureCount <= 3;
}
