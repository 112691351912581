import styled from '@emotion/styled';
import React from 'react';

import { COLORS } from '@/constants/styles';
import { BaseProps, Flex } from '@/elements/Div';
import { PrismCode } from '@/elements/PrismCode';

import { CopyToClipboard } from './CopyToClipboard';

interface CodeBlockProps {
  command: string;
  language: string;
}

const Command = styled(Flex)`
  padding: 7px 0 7px 7px;
  background-color: ${COLORS.paleGrey};
  flex: 1;
  min-height: 52px;
  position: relative;
  max-width: 100%;

  pre {
    overflow-y: auto;
    padding-right: 76px;
    flex: 1;
  }

  pre[class*='language-'] {
    font-size: 14px;
    background-color: ${COLORS.paleGrey};
  }
`;

export const CodeBlock: React.FC<Omit<BaseProps & CodeBlockProps, 'css'>> = ({
  command,
  language,
  overflow,
  ...props
}) => {
  return (
    <Flex position="relative" height="100%" overflow={overflow}>
      <Command {...props}>
        <PrismCode code={command} language={language} />
        <div className="mr-5 flex">
          <CopyToClipboard command={command} />
        </div>
      </Command>
    </Flex>
  );
};
