import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';
import { fontAvenir, fontAvenirBold } from 'upbound-frontend-constants';

import { MediaStyle } from '@/constants/styledTheme';
import { COLORS } from '@/constants/styles';

export const globalStyle = css`
  /* http://meyerweb.com/eric/tools/css/reset/
  v2.0 | 20110126
  License: none (public domain)
  */

  /* prettier-ignore */
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }

  /* prettier-ignore */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body {
    line-height: 1;
    ${fontAvenirBold};
  }

  body.overflow-hidden {
    overflow-y: hidden;
  }

  ol,
  ul {
    list-style: none;
  }

  blockquote,
  q {
    quotes: none;
  }

  blockquote:before,
  blockquote:after,
  q:before,
  q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
  }

  body {
    height: 100%;
  }

  #__next {
    height: 100%;
  }

  p {
    margin: 0;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  b {
    ${fontAvenirBold};
  }

  .Toastify {
    display: flex;
    justify-content: center;

    .Toastify__toast-container {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: inherit;
    }
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container,
  .react-datepicker__input-container input {
    display: block;
    width: 100%;
  }

  html > body > div#hs-eu-cookie-confirmation.hs-cookie-notification-position-bottom {
    box-shadow: none;
    border-top-width: 0;
    z-index: 2147483647 !important;

    &,
    & > div#hs-eu-cookie-confirmation-inner {
      background-color: ${COLORS.slate};
    }

    & > div#hs-eu-cookie-confirmation-inner {
      padding: 30px 40px;

      & > p#hs-eu-cookie-disclaimer,
      & > div#hs-eu-policy-wording > p,
      & > div#hs-eu-policy-wording > p > span {
        ${fontAvenir}
        color: ${COLORS.white};
        font-size: 14px !important;
        line-height: 24px !important;
      }

      & > div#hs-en-cookie-confirmation-buttons-area > div#hs-eu-cookie-confirmation-button-group > a {
        box-sizing: border-box !important;
        font-size: 14px !important;
        line-height: 34px !important;
        padding: 0 20px !important;
        border-color: transparent !important;
        border-style: solid !important;
        border-width: 1px !important;
        border-radius: 20px !important;
        cursor: pointer;
        outline: none;
        overflow: hidden;
        text-align: center;
        text-overflow: ellipsis;
        transition: all 0.2s ease-in-out;
        transition: background-color 0.1 ease-out;
        white-space: nowrap;
        width: auto;

        &#hs-eu-confirmation-button {
          background-color: ${COLORS.aquaMarine} !important;
          color: ${COLORS.white} !important;
          fill: ${COLORS.white} !important;

          &:hover {
            border-color: transparent !important;
            background-color: ${COLORS.aquarius} !important;
            color: ${COLORS.white} !important;
            fill: ${COLORS.white} !important;
          }
        }

        &#hs-eu-decline-button {
          border-color: ${COLORS.white} !important;
          color: ${COLORS.white} !important;
          fill: ${COLORS.white} !important;

          &:hover {
            border-color: ${COLORS.white} !important;
            background-color: ${COLORS.white} !important;
            color: ${COLORS.fillBlackBlack} !important;
            fill: ${COLORS.fillBlackBlack} !important;
          }
        }
      }
    }
  }

  .is-logged-in .cky-btn-revisit-wrapper {
    display: none !important;
  }

  ${MediaStyle};
`;

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    &-body {
      text-align: center;
      ${fontAvenir};
      font-size: 14px;
      padding: 0 20px;

      button {
        display: inline;
        margin-left: 20px;
        line-height: 0;
        padding: 13px 18px;
      }
    }

    &--success {
      background-color: ${COLORS.slate};
      border-radius: 5px;
    }

    &.dismissable {
      cursor: default;
      align-items: center;

      .Toastify__toast-body {
        padding: 0 12px;
      }
    }
  }
`;
