
      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Account": [
      "OrgAccount",
      "UserAccount"
    ],
    "BaseUser": [
      "CurrentUser",
      "User"
    ],
    "ConditionedStatus": [
      "CompositeResourceClaimStatus",
      "CompositeResourceDefinitionStatus",
      "CompositeResourceStatus",
      "CompositionStatus",
      "ConfigurationRevisionStatus",
      "ConfigurationStatus",
      "CustomResourceDefinitionStatus",
      "ManagedResourceStatus",
      "ProviderConfigStatus",
      "ProviderRevisionStatus",
      "ProviderStatus"
    ],
    "ConfigurationOrProvider": [
      "Configuration",
      "Provider"
    ],
    "CreateControlPlaneResponse": [
      "AccountControlPlane",
      "ControlPlaneLimitReached"
    ],
    "DetailsResource": [
      "CompositeResource",
      "CompositeResourceClaim",
      "ManagedResource"
    ],
    "KubernetesResource": [
      "CompositeResource",
      "CompositeResourceClaim",
      "CompositeResourceDefinition",
      "Composition",
      "CompositionRevision",
      "ConfigMap",
      "Configuration",
      "ConfigurationRevision",
      "CustomResourceDefinition",
      "FunctionRevision",
      "GenericResource",
      "ManagedResource",
      "Provider",
      "ProviderConfig",
      "ProviderRevision",
      "Secret",
      "XgqlFunction"
    ],
    "ManagedResourceDefinition": [
      "CustomResourceDefinition"
    ],
    "McpResource": [
      "McpClaim",
      "McpComposite",
      "McpManagedResource"
    ],
    "Node": [
      "CompositeResource",
      "CompositeResourceClaim",
      "CompositeResourceDefinition",
      "Composition",
      "CompositionRevision",
      "ConfigMap",
      "Configuration",
      "ConfigurationRevision",
      "CustomResourceDefinition",
      "Event",
      "FunctionRevision",
      "GenericResource",
      "ManagedResource",
      "Provider",
      "ProviderConfig",
      "ProviderRevision",
      "Secret",
      "XgqlFunction"
    ],
    "ProviderConfigDefinition": [
      "CustomResourceDefinition"
    ],
    "RegistryResource": [
      "RegistryCRD",
      "RegistryXRD"
    ],
    "TierLimit": [
      "TierLimitAtMost"
    ]
  }
};
      export default result;
    