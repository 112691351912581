import getConfig from 'next/config';

let savedUserInfo: Record<string, unknown> | (() => void);

const Intercom = (command: string, options?: Record<string, unknown> | (() => void)) => {
  const enabled = (getConfig().publicRuntimeConfig.INTERCOM_ENABLED ?? '') === 'true';
  const intercom_app_id = getConfig().publicRuntimeConfig.INTERCOM_APP_ID;
  if (enabled) {
    const intercom = (window as any).Intercom;
    if (intercom_app_id && intercom) {
      switch (command) {
        case 'onShow':
        case 'onHide':
          intercom(command, options);
          break;
        case 'shutdown':
          intercom(command);
          break;
        default:
          intercom(command, {
            app_id: intercom_app_id,
            ...(options || {}),
          });
      }
    }
  }
};

export function IntercomUpdate(options?: Record<string, unknown> | (() => void)) {
  Intercom('update', options);
}

export function IntercomSetUser(userInfo: Record<string, unknown> | (() => void)) {
  IntercomUpdate(userInfo);
}

// TODO: Should only allow boot to be called once
export function IntercomBoot() {
  Intercom('boot', savedUserInfo);
}

export function IntercomOpen() {
  Intercom('show');
}

export function IntercomShutdown() {
  Intercom('shutdown');
}
