import { ApolloClient } from '@apollo/client';
import { queryOptions, skipToken } from '@tanstack/react-query';

import {
  ControlPlaneStatus,
  GetSpaceGroupControlPlanesDocument,
  GetSpaceGroupControlPlanesQuery,
  GetSpaceGroupControlPlanesQueryVariables,
  SpaceType,
} from '@/generated/upbound-graphql';
import { isAxiosError } from '@/utils/axios';
import { DefaultPollingInterval } from '@/utils/consts';

import { shouldRetry } from './shared';
import {
  BackupJobsResponse,
  ControlPlaneGroup,
  getAggregatedDiscovery,
  getAPISpec,
  getBackupJob,
  getBackupJobs,
  getControlPlane,
  getControlPlaneComposition,
  getControlPlaneGroup,
  getControlPlaneGroups,
  getControlPlanePackages,
  getLegacyControlPlaneComposition,
  getLegacyControlPlanePackages,
  getSharedBackupConfig,
  getSharedBackupConfigs,
  getSharedBackupSchedule,
  getSharedBackupSchedules,
  getSharedExternalSecret,
  getSharedExternalSecrets,
  getSharedSecretStore,
  getSharedSecretStores,
  getSpace,
  getSpaceGroupControlPlanes,
  getSpaceVersion,
  SpaceGroupControlPlane,
} from './spacesApi.service';
import {
  getControlPlaneAccessManagement,
  getGroupAccessManagement,
  selfSubjectAccessReview,
  SelfSubjectAccessReviewPayload,
} from './spacesApi/accessManagement.service';
import { getSharedTelemetryConfig, getSharedTelemetryConfigs } from './spacesApi/sharedTelemetryConfigApi.service';

export function getSpaceVersionQuery(orgName: string, spaceName: string) {
  return queryOptions({
    queryKey: ['spaces', 'version', 'get', orgName, spaceName],
    queryFn: () => getSpaceVersion(orgName, spaceName),
    staleTime: 2 * 60 * 1000,
    refetchInterval: 2 * 60 * 1000,
    refetchOnMount: false,
    retryOnMount: false,
    retry: shouldRetry,
  });
}

export function statusFromSpaceGroupControlPlane(cp: SpaceGroupControlPlane): ControlPlaneStatus {
  if (cp.metadata.deletionTimestamp) {
    return ControlPlaneStatus.Deleting;
  }

  // Check for ready
  const readyStatus = cp.status?.conditions?.find(c => c.type === 'Ready');
  if (readyStatus && readyStatus.status === 'True') {
    return ControlPlaneStatus.Ready;
  }

  // If we don't have a ready status or if it is false with a reason of 'Creating' then we are "Provisioning", otherwise
  // we are "Updating".
  return !readyStatus || readyStatus.reason === 'Creating'
    ? ControlPlaneStatus.Provisioning
    : ControlPlaneStatus.Updating;
}

export function getSpaceGroupControlPlanesQuery(orgName: string, spaceName: string, groupName: string) {
  // TODO: Convert from old to new model instead of the other way around.
  return queryOptions({
    queryKey: ['spaceGroupControlPlane', 'list', orgName, spaceName, groupName],
    queryFn: () => getSpaceGroupControlPlanes(orgName, spaceName, groupName),
    staleTime: 60 * 1000,
    refetchInterval: DefaultPollingInterval,
  });
}

export function getLegacySpaceGroupControlPlanesQuery<T>(
  orgName: string,
  spaceName: string,
  groupName: string,
  apolloClient: ApolloClient<T>,
) {
  // TODO: Convert from old to new model instead of the other way around.
  return queryOptions({
    queryKey: ['legacySpaceGroupControlPlane', 'list', orgName, spaceName, groupName],
    queryFn: () =>
      apolloClient
        .query<GetSpaceGroupControlPlanesQuery, GetSpaceGroupControlPlanesQueryVariables>({
          query: GetSpaceGroupControlPlanesDocument,
          variables: { groupId: `${orgName}:${spaceName}:${groupName}` },
        })
        .then(r => r.data.spaceGroup.controlPlanes),
    staleTime: 60 * 1000,
    refetchInterval: DefaultPollingInterval,
  });
}

export function getSpaceQuery(orgName: string, spaceName: string) {
  return queryOptions({
    queryKey: ['space', orgName, spaceName],
    queryFn: () => getSpace(orgName, spaceName),
    refetchInterval: DefaultPollingInterval,
  });
}

const defaultGroup: ControlPlaneGroup = {
  metadata: { creationTimestamp: '', name: 'default', uid: '' },
};

function groupQuery(orgName: string, spaceName: string, spaceType: SpaceType, groupName: string) {
  return spaceType === SpaceType.Legacy
    ? // Legacy Spaces do not contain groups, so we're forcing a default group
      // The GraphQL Groups query does the same, but this saves a network request
      Promise.resolve(defaultGroup)
    : getControlPlaneGroup(orgName, spaceName, groupName);
}

export function getSpaceGroupQuery(
  orgName: string,
  spaceName: string,
  groupName: string,
  spaceType: SpaceType | undefined,
) {
  return queryOptions({
    queryKey: ['controlPlaneGroup', orgName, spaceName, groupName, spaceType],
    queryFn:
      orgName && spaceName && spaceType && groupName
        ? () => groupQuery(orgName, spaceName, spaceType, groupName)
        : skipToken,
    refetchInterval(query) {
      const {
        state: { error },
      } = query;
      if (error && isAxiosError(error) && error.response?.status === 404) {
        return 0;
      }
      return DefaultPollingInterval;
    },
  });
}

function groupsQuery(orgName: string, spaceName: string, spaceType: SpaceType) {
  return spaceType === SpaceType.Legacy
    ? // Legacy Spaces do not contain groups, so we're forcing a default group
      // The GraphQL Groups query does the same, but this saves a network request

      Promise.resolve([defaultGroup])
    : getControlPlaneGroups(orgName, spaceName || '').then(l => l.items);
}

export function getSpaceGroupsQuery(
  orgName: string | undefined,
  spaceName: string | undefined,
  spaceType: SpaceType | undefined,
) {
  return queryOptions({
    queryKey: ['controlPlaneGroups', orgName, spaceName, spaceType],
    queryFn: orgName && spaceName && spaceType ? () => groupsQuery(orgName, spaceName, spaceType) : skipToken,
    refetchInterval: DefaultPollingInterval,
  });
}

// SECRETS
function sharedSecretStoresQuery(orgName: string, spaceName: string, groupName: string, spaceType: SpaceType) {
  return spaceType === SpaceType.Legacy ? Promise.resolve([]) : getSharedSecretStores(orgName, spaceName, groupName);
}

export function getSharedSecretStoresQuery(
  orgName: string | undefined,
  spaceName: string | undefined,
  groupName: string | undefined,
  spaceType: SpaceType | undefined,
) {
  return queryOptions({
    queryKey: ['sharedSecretStores', orgName, spaceName, groupName, spaceType],
    queryFn:
      orgName && spaceName && groupName && spaceType
        ? () => sharedSecretStoresQuery(orgName, spaceName, groupName, spaceType)
        : skipToken,
    refetchInterval: DefaultPollingInterval,
    retry: shouldRetry,
  });
}

export function getSharedSecretStoreQuery(orgName: string, spaceName: string, groupName: string, storeName: string) {
  return queryOptions({
    queryKey: ['sharedSecretStore', orgName, spaceName, groupName, storeName],
    queryFn: () => getSharedSecretStore(orgName, spaceName, groupName, storeName),
    refetchInterval: DefaultPollingInterval,
    retry: shouldRetry,
  });
}

function sharedSecretExternalSecretsQuery(orgName: string, spaceName: string, groupName: string, spaceType: SpaceType) {
  return spaceType === SpaceType.Legacy
    ? Promise.resolve([])
    : getSharedExternalSecrets(orgName, spaceName, groupName).then(resp => resp.items || []);
}

export function getSharedExternalSecretsQuery(
  orgName: string | undefined,
  spaceName: string | undefined,
  groupName: string | undefined,
  spaceType: SpaceType | undefined,
) {
  return queryOptions({
    queryKey: ['sharedExternalSecrets', orgName, spaceName, groupName, spaceType],
    queryFn:
      orgName && spaceName && groupName && spaceType
        ? () => sharedSecretExternalSecretsQuery(orgName, spaceName, groupName, spaceType)
        : skipToken,
    refetchInterval: DefaultPollingInterval,
    retry: shouldRetry,
  });
}

export function getSharedExternalSecretQuery(
  orgName: string,
  spaceName: string,
  groupName: string,
  secretName: string,
) {
  return queryOptions({
    queryKey: ['sharedExternalSecret', orgName, spaceName, groupName, secretName],
    queryFn: () => getSharedExternalSecret(orgName, spaceName, groupName, secretName),
    refetchInterval: DefaultPollingInterval,
    retry: shouldRetry,
  });
}

// BACKUPS
export function getBackupJobsQuery(
  orgName: string,
  spaceName: string,
  groupName: string,
  backupType: string,
  pageSize: number,
) {
  return queryOptions<BackupJobsResponse>({
    queryKey: ['backupJobs', orgName, spaceName, groupName, backupType, pageSize],
    queryFn: () => getBackupJobs(orgName, spaceName, groupName, pageSize),
    refetchInterval: DefaultPollingInterval,
    retry: shouldRetry,
  });
}

export function getBackupJobQuery(orgName: string, spaceName: string, groupName: string, jobId: string) {
  return queryOptions({
    queryKey: ['backupJob', orgName, spaceName, groupName, jobId],
    queryFn: () => getBackupJob(orgName, spaceName, groupName, jobId),
    refetchInterval: DefaultPollingInterval,
    retry: shouldRetry,
  });
}

export function getSharedBackupSchedulesQuery(orgName: string, spaceName: string, groupName: string) {
  return queryOptions({
    queryKey: ['sharedBackupSchedules', orgName, spaceName, groupName],
    queryFn: () => getSharedBackupSchedules(orgName, spaceName, groupName),
    refetchInterval: DefaultPollingInterval,
    retry: shouldRetry,
  });
}

export function getSharedBackupConfigsQuery(orgName: string, spaceName: string, groupName: string) {
  return queryOptions({
    queryKey: ['sharedBackupConfigs', orgName, spaceName, groupName],
    queryFn: () => getSharedBackupConfigs(orgName, spaceName, groupName),
    refetchInterval: DefaultPollingInterval,
    retry: shouldRetry,
  });
}

// BACKUP DETAILS

export function getSharedBackupScheduleQuery(
  orgName: string,
  spaceName: string,
  groupName: string,
  scheduleId: string,
) {
  return queryOptions({
    queryKey: ['sharedBackupSchedule', orgName, spaceName, groupName, scheduleId],
    queryFn: () => getSharedBackupSchedule(orgName, spaceName, groupName, scheduleId),
    refetchInterval: DefaultPollingInterval,
    retry: shouldRetry,
  });
}

export function getSharedBackupConfigQuery(
  orgName: string,
  spaceName: string,
  groupName: string,
  configId: string,
  spaceType: SpaceType | undefined,
) {
  return queryOptions({
    queryKey: ['sharedBackupConfig', orgName, spaceName, groupName, configId, spaceType],
    queryFn: () => getSharedBackupConfig(orgName, spaceName, groupName, configId),
    refetchInterval: DefaultPollingInterval,
    retry: shouldRetry,
  });
}

export function getControlPlaneAccessManagementQuery(
  orgName: string,
  spaceName: string,
  groupName: string,
  controlPlaneName: string,
) {
  return queryOptions({
    queryKey: ['controlPlaneAccessControl', orgName, spaceName, groupName, controlPlaneName],
    queryFn: () => getControlPlaneAccessManagement(orgName, spaceName, groupName, controlPlaneName),
    refetchInterval: DefaultPollingInterval,
    retry: shouldRetry,
  });
}

export function getGroupAccessManagementQuery(orgName: string, spaceName: string, groupName: string) {
  return queryOptions({
    queryKey: ['groupAccessControl', orgName, spaceName, groupName],
    queryFn: () => getGroupAccessManagement(orgName, spaceName, groupName),
    refetchInterval: DefaultPollingInterval,
  });
}

export function APISpecQuery(orgName: string, spaceName: string) {
  return queryOptions({
    queryKey: ['APISpec', orgName, spaceName],
    queryFn: () => getAPISpec(orgName, spaceName),
    refetchInterval: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: shouldRetry,
  });
}

export function sharedSecretStoresSpecQuery(orgName: string, spaceName: string) {
  return queryOptions({
    ...APISpecQuery(orgName, spaceName),
    select: data => data.components.schemas['io.upbound.spaces.v1alpha1.SharedSecretStore'].properties.spec,
  });
}

export function sharedExternalSecretsSpecQuery(orgName: string, spaceName: string) {
  return queryOptions({
    ...APISpecQuery(orgName, spaceName),
    select: data => data.components.schemas['io.upbound.spaces.v1alpha1.SharedExternalSecret'].properties.spec,
  });
}

export function sharedBackupSpecQuery(orgName: string, spaceName: string) {
  return queryOptions({
    ...APISpecQuery(orgName, spaceName),
    select: data => data.components.schemas['io.upbound.spaces.v1alpha1.SharedBackup'].properties.spec,
  });
}

export function sharedBackupConfigSpecQuery(orgName: string, spaceName: string) {
  return queryOptions({
    ...APISpecQuery(orgName, spaceName),
    select: data => data.components.schemas['io.upbound.spaces.v1alpha1.SharedBackupConfig'].properties.spec,
  });
}

export function sharedBackupSpecScheduleQuery(orgName: string, spaceName: string) {
  return queryOptions({
    ...APISpecQuery(orgName, spaceName),
    select: data => data.components.schemas['io.upbound.spaces.v1alpha1.SharedBackupSchedule'].properties.spec,
  });
}

export function selfSubjectAccessReviewQuery(
  orgName: string,
  spaceName: string,
  payload: SelfSubjectAccessReviewPayload,
) {
  return queryOptions({
    queryKey: ['selfSubjectAccessReview', orgName, spaceName, payload],
    queryFn: () => selfSubjectAccessReview(orgName, spaceName, payload),
    staleTime: 5 * 60 * 1000, // 5 minutes
    retryOnMount: false,
    refetchOnWindowFocus: false,
  });
}

function controlPlanePackagesQuery(
  orgName: string,
  spaceName: string,
  groupName: string,
  controlPlaneName: string,
  spaceType: SpaceType | undefined,
) {
  return spaceType === SpaceType.Legacy
    ? getLegacyControlPlanePackages(orgName, controlPlaneName)
    : getControlPlanePackages(orgName, spaceName, groupName, controlPlaneName);
}

export function getControlPlanePackagesQuery(
  orgName: string,
  spaceName: string,
  groupName: string,
  controlPlaneName: string,
  spaceType: SpaceType | undefined,
) {
  return queryOptions({
    queryKey: ['controlPlanePackages', orgName, spaceName, groupName, controlPlaneName, spaceType],
    queryFn: () => controlPlanePackagesQuery(orgName, spaceName, groupName, controlPlaneName, spaceType),
    refetchInterval: DefaultPollingInterval,
  });
}

function controlPlaneCompositionQuery(
  orgName: string,
  spaceName: string,
  groupName: string,
  controlPlaneName: string,
  compositionName: string,
  isLegacy: boolean = false,
) {
  return isLegacy
    ? getLegacyControlPlaneComposition(orgName, controlPlaneName, compositionName)
    : getControlPlaneComposition(orgName, spaceName, groupName, controlPlaneName, compositionName);
}

export function getControlPlaneCompositionQuery(
  orgName: string,
  spaceName: string,
  groupName: string,
  controlPlaneName: string,
  compositionName: string,
  isLegacy: boolean = false,
) {
  return queryOptions({
    queryKey: ['controlPlaneComposition', orgName, spaceName, groupName, controlPlaneName, compositionName, isLegacy],
    queryFn: () =>
      controlPlaneCompositionQuery(orgName, spaceName, groupName, controlPlaneName, compositionName, isLegacy),
    refetchInterval: DefaultPollingInterval,
  });
}

export function getSharedTelemetryConfigsQuery(orgName: string, spaceName: string, groupName: string) {
  return queryOptions({
    queryKey: ['sharedTelemetryConfigs', orgName, spaceName, groupName],
    queryFn: () => getSharedTelemetryConfigs(orgName, spaceName, groupName),
    refetchInterval: DefaultPollingInterval,
    retry: shouldRetry,
  });
}

export function getSharedTelemetryConfigQuery(
  orgName: string,
  spaceName: string,
  groupName: string,
  sharedTelemetryConfigId: string,
) {
  return queryOptions({
    queryKey: ['sharedTelemetryConfig', orgName, spaceName, groupName, sharedTelemetryConfigId],
    queryFn: () => getSharedTelemetryConfig(orgName, spaceName, groupName, sharedTelemetryConfigId),
    refetchInterval: DefaultPollingInterval,
    retry: shouldRetry,
  });
}

export function getControlPlaneQuery(orgName: string, spaceName: string, groupName: string, cpName: string) {
  return queryOptions({
    queryKey: ['controlPlane', orgName, spaceName, groupName, cpName],
    queryFn: () => getControlPlane(orgName, spaceName, groupName, cpName),
  });
}

export function getAggregatedDiscoveryQuery(orgName: string, spaceName: string, groupName: string, cpName: string) {
  return queryOptions({
    queryKey: ['aggregatedDiscovery', orgName, spaceName, groupName, cpName],
    queryFn: () => getAggregatedDiscovery(orgName, spaceName, groupName, cpName),
    refetchOnMount: false,
    staleTime: 5 * 60 * 1000,
  });
}
