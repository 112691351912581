import * as React from 'react';

import { Button, ButtonProps, ButtonType } from '../Button';
import { faChevronLeft, faChevronRight, faEllipsis, Icon } from '../Icon';
import { cn } from '../utils';

const PaginationContainer = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
);
PaginationContainer.displayName = 'PaginationContainer';

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(
  ({ className, ...props }, ref) => (
    <ul ref={ref} className={cn('flex flex-row items-center gap-1', className)} {...props} />
  ),
);
PaginationContent.displayName = 'PaginationContent';

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn('', className)} {...props} />
));
PaginationItem.displayName = 'PaginationItem';

type PaginationLinkProps = {
  isActive?: boolean;
  disabled?: boolean;
} & ButtonProps;

const PaginationButton = ({
  className,
  isActive,
  disabled,
  btnType = ButtonType.Secondary,
  ...props
}: PaginationLinkProps) => (
  <Button
    disabled={disabled}
    btnType={btnType}
    aria-current={isActive ? 'page' : undefined}
    className={className}
    {...props}
  />
);
PaginationButton.displayName = 'PaginationLink';

const PaginationPrevious = ({ className, ...props }: React.ComponentProps<typeof PaginationButton>) => (
  <PaginationButton aria-label="Go to previous page" className={cn('gap-1 pl-2.5', className)} {...props}>
    <Icon icon={faChevronLeft} />
    <span>Previous</span>
  </PaginationButton>
);
PaginationPrevious.displayName = 'PaginationPrevious';

const PaginationNext = ({ className, ...props }: React.ComponentProps<typeof PaginationButton>) => (
  <PaginationButton aria-label="Go to next page" className={cn('gap-1 pr-2.5', className)} {...props}>
    <span>Next</span>
    <Icon icon={faChevronRight} />
  </PaginationButton>
);
PaginationNext.displayName = 'PaginationNext';

const PaginationEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => (
  <span aria-hidden className={cn('flex h-9 w-9 items-center justify-center', className)} {...props}>
    <Icon className="h-4 w-4" icon={faEllipsis} />
    <span className="sr-only">More pages</span>
  </span>
);
PaginationEllipsis.displayName = 'PaginationEllipsis';

/**
 * onPageChange: function is called when the page is changed by this component
 * currentPage: current page number
 * numberOfPages: total number of pages
 * visiblePages: array of page numbers to be displayed (undefined for ellipsis)
 */
const Pagination: React.FC<{
  onPageChange: (page: number) => void;
  currentPage: number;
  numberOfPages: number;
  visiblePages: (number | undefined)[];
}> = ({ onPageChange, currentPage, visiblePages, numberOfPages }) => {
  return (
    <PaginationContainer className="w-auto mx-0">
      <PaginationContent className="gap-0">
        <PaginationItem>
          <PaginationPrevious
            disabled={currentPage === 1}
            onClick={() => onPageChange(currentPage - 1)}
            className="!rounded-r-none !border-0 ring-inset ring-1 ring-neutral-100"
          />
        </PaginationItem>
        {visiblePages.map((i, idx) => (
          <PaginationItem key={idx}>
            {i === undefined ? (
              <PaginationEllipsis className="!rounded-none !border-0 ring-inset ring-1 ring-neutral-100 -ml-px !h-[33px] w-10 pl-[2px] text-neutral-500" />
            ) : (
              <PaginationButton
                isActive={currentPage === i}
                onClick={() => onPageChange(i)}
                className={cn(
                  '!rounded-none !border-0 ring-inset ring-1 ring-neutral-100 -ml-[1px] min-w-10',
                  currentPage === i && 'ring-purple-500 z-10 isolate',
                )}
              >
                {i}
              </PaginationButton>
            )}
          </PaginationItem>
        ))}
        <PaginationItem>
          <PaginationNext
            disabled={currentPage === numberOfPages || numberOfPages === 0}
            onClick={() => onPageChange(currentPage + 1)}
            className="!rounded-l-none !border-0 ring-inset ring-1 ring-neutral-100 -ml-[1px]"
          />
        </PaginationItem>
      </PaginationContent>
    </PaginationContainer>
  );
};

export { Pagination };
