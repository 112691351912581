import { generatePath } from '.';

export const root = {
  url() {
    return root.route;
  },
  route: '/' as const,
};

export const fiveHundred = {
  url() {
    return fiveHundred.route;
  },
  route: '/500' as const,
};

export const fourOhFour = {
  url() {
    return fourOhFour.route;
  },
  route: '/404' as const,
};

export const orgOutOfCapacity = {
  url(orgName: string) {
    return generatePath(orgOutOfCapacity.route, { orgName });
  },
  route: '/:orgName/outOfCapacity' as const,
};

export const org = {
  url(account: string) {
    return generatePath(org.route, { account });
  },
  route: '/:account' as const,
};

export const adminRequired = {
  url(account: string, spaceName: string) {
    return generatePath(adminRequired.route, { account, spaceName });
  },
  route: '/:account/spaces/:spaceName/adminRequired' as const,
};
