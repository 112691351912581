import { useQuery } from '@tanstack/react-query';
import { faExternalLink, Header, Icon, Paragraph } from 'packages/elements';
import { useEffect, useState } from 'react';

import { docsSpaceAttachDetach } from '@/constants/links';
import { Modal } from '@/elements/Modal';
import { getSpaceVersionQuery } from '@/services/spacesQueries';
import { DefaultPollingInterval } from '@/utils/consts';
import { useRouterParams } from '@/utils/hooks';
import { isSpaceUnreachable } from '@/utils/spaces';

import { CodeBlock } from '../CodeBlock';

export const SpaceGuardModal: React.FC<{ isOpen: boolean; onClose: () => void; spaceName?: string }> = ({
  isOpen,
  onClose,
  spaceName,
}) => {
  if (!spaceName) {
    return null;
  }

  const detachCommand = `up space detach ${spaceName}`;

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <div className="space-y-4">
        <Header type="h4">Space {spaceName} is not reachable</Header>

        <Paragraph>
          The Space <strong className="font-bold">{spaceName}</strong> is not reachable at the moment. If the space is
          being decommissioned it will need to be properly detached.
        </Paragraph>

        <Paragraph>If your Space is accessible via CLI, you can detach it by running the following command:</Paragraph>

        <CodeBlock language="bash" command={detachCommand} />

        <div className="space-y-4 divide-y divide-neutral-shades-75 divide-solid">
          <Paragraph>
            Read more about detaching a Space in the{' '}
            <a target="_blank" className="text-purple-primary" href={`${docsSpaceAttachDetach.url()}/#detach`}>
              Upbound documentation <Icon icon={faExternalLink} className="!w-[0.8em] inline-block" />
            </a>
            .
          </Paragraph>
          <Paragraph className="pt-4">
            If there are any other Spaces attached to this organization, you can switch to them by selecting the desired
            Space from the top left corner.
          </Paragraph>
        </div>
      </div>
    </Modal>
  );
};

/**
 * The reason for this component is to check if the Space is reachable and if not, show a modal with the (descriptive
 * for now) option to detach it. With the root level component we don't have to worry embedding this logic in every
 * page/layout that needs it.
 */
export const SpaceGuard: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [showDetachedSpaceModal, setShowDetachedSpaceModal] = useState(false);
  const { orgName, spaceName } = useRouterParams([
    {
      name: 'orgName',
      disableThrowOnMissing: true,
    },
    {
      name: 'spaceName',
      disableThrowOnMissing: true,
    },
  ] as const);

  useEffect(() => {
    // reset the modal state when the spaceName changes (edge case when the user navigates to a different space and the modal is still open)
    setShowDetachedSpaceModal(false);
  }, [spaceName]);

  useQuery({
    // the version query is pretty lightweight so we can use it to check if the space is reachable
    // until we have a better way to check if the space is reachable
    ...getSpaceVersionQuery(orgName, spaceName),
    // Override queryKey so the settings are independent of other uses of get version.
    queryKey: ['spaceReachable', orgName, spaceName],
    // the query is enabled only if we have the orgName and spaceName (operation is not possible without them because there is no org/space context)
    enabled: !!orgName && !!spaceName,
    refetchInterval: DefaultPollingInterval,
    retryDelay: DefaultPollingInterval * 3,
    // we leverage the retry function to react to error states and make the calls infinte (return true)
    retry: (_failureCount, error) => {
      if (isSpaceUnreachable(error)) {
        setShowDetachedSpaceModal(true);
      }
      return true;
    },
  });

  const onClose = () => setShowDetachedSpaceModal(false);

  return (
    <>
      <SpaceGuardModal isOpen={showDetachedSpaceModal} onClose={onClose} spaceName={spaceName} />
      {children}
    </>
  );
};
