declare global {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Array<T> {
    isLastIndex(index: number): boolean;
  }
}

export const applyArrayIsLastElement = () => {
  Array.prototype.isLastIndex = function (index: number) {
    return this.length - 1 === index;
  };
};
