import React, { useEffect, useRef, useState } from 'react';
import { Popover as TinyPopover, PopoverProps as TinyPopoverProps } from 'react-tiny-popover';

import { TIMING } from '@/constants/timing';
import { useDebounce } from '@/utils/hooks';

const Popover: React.FC<
  Omit<TinyPopoverProps, 'content' | 'children' | 'isOpen' | 'onClickOutside'> & {
    children: [React.ReactNode, React.ReactNode] | React.ReactNode;
    isOpen?: boolean;
    onClickOutside?: (e: MouseEvent) => void;
  }
> = ({ children, containerStyle, ...props }) => {
  const containerRef = useRef<HTMLElement | null>(null);
  const [isOpen, setOpen] = useState(false);
  const isOpenDelayed200ms = useDebounce(isOpen, 200);
  const [linkChild, ...contentChildren] = React.Children.toArray(children);
  useEffect(() => {
    if (containerRef.current !== null) {
      return;
    }

    const containerElement = document.getElementById('pc-item');

    if (containerElement === null) {
      containerRef.current = document.body;
      return;
    }

    containerRef.current = containerElement;
  }, [containerRef.current]);

  if (!React.isValidElement(linkChild)) {
    return null;
  }

  const linkChildWithHandler = React.cloneElement(linkChild, {
    onClick: (event: React.MouseEvent) => {
      event.preventDefault();
      setOpen(true);
      if (!linkChild.props.onClick) {
        return;
      }

      linkChild.props.onClick(event);
    },
  } as React.HTMLAttributes<HTMLAnchorElement>);

  if (typeof window === 'undefined' || containerRef.current === null) {
    return linkChildWithHandler;
  }

  return (
    <TinyPopover
      containerStyle={{
        ...containerStyle,
        zIndex: '200',
        overflow: 'visible',
        opacity: isOpen ? '1' : '0',
        transition: `opacity ${TIMING.normal('string')} ease-in-out`,
      }}
      containerParent={containerRef?.current}
      content={<>{contentChildren}</>}
      isOpen={isOpen || isOpenDelayed200ms}
      // onClickOutside={() => setOpen(false)}
      {...props}
    >
      {linkChildWithHandler}
    </TinyPopover>
  );
};

export { Popover };
