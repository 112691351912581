import styled from '@emotion/styled';
import { COLORS, shouldForwardProp, useExpandable, useOutsideClick } from 'packages/constants';
import React from 'react';
import * as styledSystem from 'styled-system';
import { SHADES } from 'upbound-frontend-constants';
import { faChevronDown, faChevronUp, Icon, Span } from 'upbound-frontend-elements';

import { BaseProps } from '../index.types';

const baseStyledSystem = styledSystem.compose(
  styledSystem.background,
  styledSystem.border,
  styledSystem.flexbox,
  styledSystem.layout,
  styledSystem.overflow,
  styledSystem.position,
  styledSystem.padding,
  styledSystem.space,
);

const DropdownContainer = styled('div')<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${COLORS.Neutral[0]};
  border: 1px solid ${COLORS.Neutral[100]};
  border-radius: 7px;
  position: relative;
  height: 38px;

  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
`;

const DropdownContentContainer = styled('div')`
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 36px;
  background-color: ${COLORS.Neutral[0]};
  border-radius: 7px;
`;

const DropdownContent = styled('div')<{ contentHeight: number }>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
  height: ${({ contentHeight }) => contentHeight}px;
`;

const DropdownItem = styled('div')`
  width: 100%;
  padding: 12px 24px;
  cursor: pointer;

  &:hover {
    background-color: ${SHADES.Neutral[50]};
  }

  span {
    font-size: 10pt;
  }

  &:first-of-type {
    border-top: 1px solid ${COLORS.Neutral[100]};
  }
`;

const DropdownButton = styled('button')`
  text-align: left;
  border: none;
  background-color: ${COLORS.Neutral[0]};
  padding: 8px 24px;
  cursor: pointer;
  border-radius: 7px;

  &:hover {
    background-color: ${SHADES.Neutral[50]};
  }
`;

const DropdownButtonContent = styled('div')<{ disabled: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    color: ${props => (props.disabled ? COLORS.Neutral[300] : COLORS.Neutral[900])};
    font-size: 10pt;
  }
`;

const Dropdown: React.FC<{ children: React.ReactNode[]; className?: string; disabled?: boolean }> = ({
  children,
  className,
  disabled = false,
}) => {
  const { isExpanded, setExpanded, contentRef, contentHeight } = useExpandable();
  const [linkChildren, ...contentChildren] = React.Children.toArray(children);

  const buttonRef = useOutsideClick(() => {
    setExpanded(false);
  });

  return (
    <DropdownContainer className={className} disabled={disabled}>
      <DropdownButton ref={buttonRef} onClick={() => setExpanded(!isExpanded)}>
        <DropdownButtonContent disabled={disabled}>
          <Span>{linkChildren}</Span>
          <Icon
            icon={isExpanded ? faChevronUp : faChevronDown}
            ml="5px"
            color={disabled ? COLORS.Neutral[300] : COLORS.Neutral[900]}
            fontSize="12px"
          />
        </DropdownButtonContent>
      </DropdownButton>
      <DropdownContentContainer className={isExpanded ? 'border border-solid border-neutral-100' : 'border-0'}>
        <DropdownContent ref={contentRef} contentHeight={contentHeight} onClick={() => setExpanded(!isExpanded)}>
          {contentChildren}
        </DropdownContent>
      </DropdownContentContainer>
    </DropdownContainer>
  );
};

const StyledDropdown = styled(Dropdown, { shouldForwardProp })<BaseProps>`
  ${baseStyledSystem}
`;

export { StyledDropdown as Dropdown, DropdownItem };
