var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"d49dbbfe0b130c2fcffd453869aedf4f9594109b"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const SENTRY_ENVIRONMENT = getConfig().publicRuntimeConfig.SENTRY_ENVIRONMENT;
const SENTRY_DSN = getConfig().publicRuntimeConfig.SENTRY_DSN;

Sentry.init({
  dsn: SENTRY_DSN || 'https://bdfff5f810fa41e2a4169dd474e75183@o296152.ingest.sentry.io/1546066',
  // Replay may only be enabled for the client-side
  integrations: [new Sentry.Replay()],
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0.1,

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
  environment: SENTRY_ENVIRONMENT,
});
