import styled from '@emotion/styled';
import { fontAvenir, fontAvenirBold } from 'upbound-frontend-constants';

import { COLORS, TIMING } from '@/constants/styles';
import { Flex } from '@/elements/Div';

/**
 * @deprecated
 * import PopoverPackage from 'packages/elements';
 */
const PopoverContainer = styled(Flex)`
  flex-direction: column;
  border-radius: 10px;
  border: solid 1px ${COLORS.lightPeriwinkle};
  background-color: ${COLORS.white};
  box-shadow: 0 2px 5px 0 rgba(80, 90, 114, 0.2);
  padding: 10px 0;
  margin: 3px 5px 7px;
  align-items: center;
`;

/**
 * @deprecated
 * import PopoverPackage from 'packages/elements';
 */
const PopoverTooltipContainer = styled(PopoverContainer)`
  padding: 10px 20px;
  align-items: flex-start;
`;

/**
 * @deprecated
 * import PopoverPackage from 'packages/elements';
 */
const PopoverRow = styled('li')`
  ${fontAvenir};
  cursor: pointer;
  padding: 7px 15px;
  font-size: 14px;
  list-style: none;
  color: ${COLORS.slate};
  transition: background-color ${TIMING.fastest()} ease-in-out;

  &:hover {
    background-color: ${COLORS.paleGrey};
  }
`;

/**
 * @deprecated
 * import PopoverPackage from 'packages/elements';
 */
const PopoverHeader = styled('li')`
  ${fontAvenirBold};
  padding: 9px 15px;
  font-size: 14px;
  list-style: none;
  color: ${COLORS.slate};
  line-height: 20px;
`;

export { PopoverContainer, PopoverTooltipContainer, PopoverRow, PopoverHeader };
