import { ErrorResponse } from '@apollo/client/link/error';
import { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import { ToastAction, useToast } from 'packages/elements';
import { ParsedUrlQueryInput } from 'querystring';
import { DependencyList, useEffect, useLayoutEffect, useRef, useState } from 'react';

import {
  enforcementCTA,
  enforcementFeatureDisabledMessage,
  enforcementFeatureDisabledTitle,
  enforcementTierLimitMessage,
  enforcementTierLimitTitle,
} from '@/constants/copy';
import { marketingProductPricing } from '@/constants/links';
import { TIMING } from '@/constants/timing';
import { hasLimitReachedError } from '@/graphql';

import { isAxiosError } from './axios';
import { isClient } from './checks';
import { log } from './log';

export const useIsActive = (href?: Pick<LinkProps, 'href'>['href'], exact = false) => {
  const router = useRouter();

  if (href?.toString().startsWith('http') || !href?.toString().startsWith('/')) {
    return false;
  }

  try {
    const url = new URL((href || '') as string, window.location.origin);

    if (exact) {
      return router.asPath === url.pathname;
    }
    return router.asPath.startsWith(url.pathname);
  } catch (e) {
    return false;
  }
};

export function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function useExpandable() {
  const contentRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (!isExpanded) {
      if (contentHeight !== 0) {
        setContentHeight(0);
        return;
      }

      return;
    }

    if (!contentRef || !contentRef.current || !contentRef.current.scrollHeight) {
      return;
    }

    setContentHeight(contentRef.current.scrollHeight);
  }, [isExpanded, contentRef]);

  return { isExpanded, setExpanded, contentRef, contentHeight };
}

export function usePrevious<T>(value: T) {
  const ref = useRef<T>();

  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

function getScrollPosition() {
  if (!isClient) {
    return { x: 0, y: 0 };
  }

  const position = document.body.getBoundingClientRect();

  return { x: position.left, y: position.top };
}

interface Postion {
  x: number;
  y: number;
}

interface EffectCallbackArgs {
  prevPos: Postion;
  currPos: Postion;
}

type EffectCallback = (arg: EffectCallbackArgs) => void | ((arg: EffectCallbackArgs) => void | undefined);

/**
 * This hook can be used to "listen" to the scroll position and do something as a result
 * @param effect Callback to run when hook is triggered
 * @param deps Optional list of dependencies that cause hook to fire
 *
 * Usage example:
 * ```
 * const [isSlimNav, toggleIsSlimNav] = useState(false);
 * useScrollPosition(({ currPos }) => toggleIsSlimNav(currPos.y !== 0), [isSlimNav]);
 * ```
 */
export function useScrollPosition(effect: EffectCallback, deps?: DependencyList | undefined) {
  const position = useRef(getScrollPosition());

  let throttleTimeout: ReturnType<typeof setTimeout> | null = null;

  const callBack = () => {
    const currPos = getScrollPosition();
    effect({ prevPos: position.current, currPos });
    position.current = currPos;
    throttleTimeout = null;
  };

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (throttleTimeout === null) {
        throttleTimeout = setTimeout(callBack, TIMING.fast('number', 'ms'));
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, deps);
}

export function useRedirect(replace = false) {
  const router = useRouter();
  return (url: string, query?: string | null | ParsedUrlQueryInput | undefined) => {
    const redirectObject = { pathname: url, query };
    log('Redirecting', redirectObject);

    if (replace) {
      router.replace(redirectObject);
      return;
    }

    router.push(redirectObject);
  };
}

export function usePortal(id: string) {
  const rootElementRef = useRef<HTMLDivElement>();

  // Create the portal div
  useEffect(() => {
    const rootElem = document.createElement('div');

    rootElementRef.current = rootElem;

    return () => {
      rootElementRef.current = undefined;
      rootElem.remove();
    };
  }, []);

  // Attach the portal div to the DOM
  useEffect(() => {
    if (!rootElementRef.current) {
      return;
    }

    if (!id) {
      document.body.appendChild(rootElementRef.current);
      return;
    }

    const parentElement = document.querySelector(`#${id}`);

    if (!parentElement) {
      document.body.appendChild(rootElementRef.current);
      return;
    }

    parentElement.appendChild(rootElementRef.current);
  }, [id, rootElementRef]);

  return rootElementRef.current;
}

/** useHash is a getter function for the hash from the url.
 * The initialValue arg is an optional argument
 * that if present will be used to set the hash to on initial render
 * */
export const useHash = (initialValue?: string) => {
  const router = useRouter();
  const [hash, setHash] = useState(window.location.hash.slice(1));

  useEffect(() => {
    const onNextJSHashChange = (url: string) => setHash(url.split('#')[1]);
    const onWindowHashChange = (event: HashChangeEvent) => {
      setHash(event.newURL.split('#')[1]);
    };
    router.events.on('hashChangeStart', onNextJSHashChange);
    window.addEventListener('hashchange', onWindowHashChange);
    return () => {
      router.events.off('hashChangeStart', onNextJSHashChange);
      window.removeEventListener('hashchange', onWindowHashChange);
    };
  }, [router.events]);

  useEffect(() => {
    if (initialValue && !window.location.hash) {
      router.push({ ...router, hash: initialValue }, undefined, { shallow: true });
    }
  }, []);

  return hash;
};

interface RouterParamsParamConfig<T extends string = string> {
  name: string;
  test?: (value: string) => value is T;
  disableThrowOnMissing?: boolean;
  defaultValue?: T;
}

type RouterParamsParamNameOrConfig = string | RouterParamsParamConfig;

type RouterParamsExtractString<T> = T extends string ? T : T extends { name: infer N } ? N : never;
type RouterParamsExtractType<T> = T extends string
  ? string
  : T extends { test: (value: any) => value is infer U }
    ? U
    : string;

type RouterParamsExtractParam<T, Name> = T extends (infer U)[]
  ? U extends { name: infer N }
    ? N extends Name
      ? U
      : never
    : U extends string
      ? U extends Name
        ? U
        : never
      : never
  : never;

type RouterParamsReturnType<T extends RouterParamsParamNameOrConfig[]> = {
  [P in RouterParamsExtractString<T[number]>]: RouterParamsExtractType<RouterParamsExtractParam<T, P>>;
};

/**
 * @description
 * When using the typed test functions, make sure to use the `as const` assertion.
 * If you provide an object, you can also provide a test function that will be used to validate the value.
 * If the value is invalid, an error will be thrown.
 * The returned type should be a string (T extends string)
 *
 * @example
 * ```ts
 * const { orgName, spaceType } = useRouterParams([
 *  'orgName',
 * {
 *  name: 'spaceType',
 *  test: (value: string): value is SpaceType => ['connected', 'managed'].includes(value),
 * }] as const)
 * ```
 */
export function useRouterParams<T extends string | RouterParamsParamConfig>(
  params: readonly T[],
): RouterParamsReturnType<T[]> {
  const router = useRouter();

  return params.reduce<RouterParamsReturnType<T[]>>(
    (result, param) => {
      const name = typeof param === 'string' ? param : param.name;

      if (!(name in router.query)) {
        if (typeof param !== 'string' && param.disableThrowOnMissing) {
          if (param.defaultValue) {
            return {
              ...result,
              [name]: param.defaultValue,
            };
          }
          return result;
        }
        throw new Error(`Missing required param: ${name}`);
      }

      const queryValue: string | string[] = router.query[name] ?? '';
      const value: string = decodeURIComponent(Array.isArray(queryValue) ? queryValue.join(', ') : queryValue);

      if (typeof param !== 'string' && param.test && !param.test(value)) {
        throw new Error(`Invalid value for param ${name}`);
      }

      return {
        ...result,
        [name]: value,
      };
    },
    {} as RouterParamsReturnType<T[]>,
  );
}

/** Return enforcement error data given an error or `undefined` if not an enforcement error */
function parseEnforcementError(
  error: Error,
): { message: string; type: 'FeatureDisabled' | 'LimitReached' } | undefined {
  if (hasLimitReachedError((error as unknown as ErrorResponse)?.graphQLErrors)) {
    return {
      message: enforcementTierLimitMessage,
      type: 'LimitReached',
    };
  }

  if (isAxiosError(error)) {
    // Note: Could verify that error.response.data.code is 403
    if (error.response?.data?.reason && ['FeatureDisabled', 'LimitReached'].includes(error.response.data.reason)) {
      const responseMessage = error.response.data.message?.replace(/^admission webhook ".*?" denied the request: /, '');

      return {
        message:
          responseMessage ??
          (error.response.data.reason === 'LimitReached'
            ? enforcementTierLimitMessage
            : enforcementFeatureDisabledMessage),
        type: error.response.data.reason,
      };
    }

    // Legacy Upbound API limit reached error
    if (error.response?.status === 435) {
      return {
        message: error.response.data?.error,
        type: 'LimitReached',
      };
    }
  }

  return undefined;
}

/** Check if an error (Axios Upbound API, Axios Kube-Like or GraphQL) is an tier enforcement error */
export function isEnforcementError(error: Error): boolean {
  return !!parseEnforcementError(error);
}

function parseError(
  error: Error | null,
  message?: string,
): { enforcementError: boolean; description: string; title: string } {
  // If this is a kube-like enforcement error
  const enforcementData = error ? parseEnforcementError(error) : undefined;
  if (enforcementData) {
    return {
      enforcementError: true,
      description: enforcementData.message,
      title: enforcementData.type === 'LimitReached' ? enforcementTierLimitTitle : enforcementFeatureDisabledTitle,
    };
  }

  const defaultErrorTitle = message ?? 'Error';

  // If there is a message we can use it
  if (isAxiosError(error)) {
    const errMessage = error.response?.data.message ?? error.response?.data.error;
    if (message) {
      return {
        enforcementError: false,
        description: errMessage,
        title: error.response?.data.reason ? `${defaultErrorTitle}: ${error.response.data.reason}` : defaultErrorTitle,
      };
    }
  }

  // Default error handling
  return {
    enforcementError: false,
    description: error?.message ?? 'An unknown error has occurred',
    title: defaultErrorTitle,
  };
}

export const EnforcementAction = () => {
  return (
    <ToastAction altText={enforcementCTA} onClick={() => window.open(marketingProductPricing.url(), '_blank')}>
      {enforcementCTA}
    </ToastAction>
  );
};

/** Display an error Toast
 * Handles Axios Upbound API errors, Axios Kube-Like errors and GraphQL errors
 * @param toast The toast function from the useToast hook
 * @param error The error to display (can be null)
 * @param message Optional message to display in the toast if needed (enforcement errors do not use this)
 */
export function displayErrorToast(toast: ReturnType<typeof useToast>['toast'], error: Error | null, message?: string) {
  const { enforcementError, description, title } = parseError(error, message);
  toast({
    title,
    description,
    action: enforcementError ? <EnforcementAction /> : undefined,
  });
}

/** Display a toast when an error is encountered
 * Handles Axios Upbound API errors, Axios Kube-Like errors and GraphQL errors
 * @param error The error to display
 * @param message Optional message to display in the toast if needed (enforcement errors do not use this)
 */
export function useHandleRequestError(error: Error | null, message?: string) {
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      displayErrorToast(toast, error, message);
    }
  }, [error]);
}

export function displayGenericEnforcementToast(
  toast: ReturnType<typeof useToast>['toast'],
  type: 'FeatureDisabled' | 'LimitReached',
) {
  toast({
    title: type === 'LimitReached' ? enforcementTierLimitTitle : enforcementFeatureDisabledTitle,
    description: type === 'LimitReached' ? enforcementTierLimitMessage : enforcementFeatureDisabledMessage,
    action: <EnforcementAction />,
  });
}
