import { FieldProps } from 'formik';
import { Props as SelectProps } from 'react-select';

import { Scalars } from '@/generated/upbound-graphql';

import { ControlPlanePermission } from '../generated/upbound-graphql';

export interface ConfigurationTemplate {
  id: Scalars['ID'];
  name: string;
  imageUri: string;
  repo: string;
  description?: string;
  policy?: string;
  fre?: boolean;
  featured?: boolean;
}

export interface TValue {
  label: string;
  value: string;
}

export type SelectFieldProps = FieldProps &
  Omit<SelectProps, 'options'> & {
    options?: TValue[];
  };

export interface RepositoryValues {
  repoName: string;
  isPublic: boolean;
}

export interface CreateControlPlaneValues {
  autoDeletion: boolean;
  configurationId: string;
  description: string;
  name: string;
}

export interface EditControlPlaneValues {
  controlPlaneRole: ControlPlanePermission;
}

export interface CreateCpVals {
  autoDeletion: boolean;
  description: string;
  name: string;
}

export interface AxiosOptions {
  verbose?: boolean;
}

export enum SentryEnvironments {
  Local = 'local development',
  Development = 'dev',
  Staging = 'staging',
  Testing = 'jest test',
  Production = 'production',
}

export interface AppWindowValues {
  __UBC_CONFIG: {
    SENTRY_DSN?: string;
    SENTRY_ENVIRONMENT?: SentryEnvironments;
    BUILD_VERSION?: string;
  };
}

export enum GraphQLErrorCodes {
  CONFLICT = 'CONFLICT', // 409
  FORBIDDEN = 'FORBIDDEN', // 403
  LIMIT_REACHED = 'LIMIT_REACHED', // 435
  MISSING_DEPS = 'MISSING_DEPS', // Custom
  NOT_FOUND_ERROR = 'NOT_FOUND_ERROR', // 404
  UNAUTHENTICATED = 'UNAUTHENTICATED', // 401
  UNPROCESSABLE_ENTITY = 'UNPROCESSABLE_ENTITY', // 422
  INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR', // 500
  RETRYABLE_ERROR = 'RETRYABLE_ERROR', // 503
}
