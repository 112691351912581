import styled from '@emotion/styled';
import React from 'react';
import * as styledSystem from 'styled-system';

import { COLORS, shouldForwardProp } from '@/constants/styles';

interface ImgProps
  extends React.ImgHTMLAttributes<HTMLImageElement>,
    styledSystem.ColorProps,
    styledSystem.LayoutProps,
    styledSystem.SpaceProps,
    styledSystem.TypographyProps {
  color?: COLORS;
  height?: string | number;
  width?: string | number;
}

const baseStyledSystem = styledSystem.compose(
  styledSystem.color,
  styledSystem.layout,
  styledSystem.space,
  styledSystem.typography,
);

export const ImageTag = styled('img', { shouldForwardProp })<ImgProps>`
  ${baseStyledSystem}
`;

export const Img: React.FC<Omit<ImgProps, 'css'>> = ({ alt, ...props }) => {
  return <ImageTag alt={alt || ' '} {...props} />;
};
