import React, { forwardRef } from 'react';
import { twMerge } from "tailwind-merge";

const Panel = forwardRef<HTMLDivElement, React.PropsWithChildren<{ className?: string }>>(
  ({ children, className }, ref) => {
    const baseClassName = `
      p-5
      rounded-md bg-neutral-0 border border-solid border-neutral-100
    `;

    return (
      <div
        ref={ref}
        className={twMerge(baseClassName, className)}
      >
        {children}
      </div>
    );
  }
);

Panel.displayName = 'Panel';

export { Panel };
