import { twMerge } from 'tailwind-merge';

import { faCheckSquareReg, faSquareReg, Icon } from '../../Icon';
import { Label } from '../Label/Label';

export type CheckboxProps = React.PropsWithChildren<{
  id: string;
  checked: boolean;
  onChange: () => void;
  className?: string;
  disabled?: boolean;
  labelClassName?: string;
  checkboxSize?: number;
}>;

export const Checkbox: React.FC<CheckboxProps> = ({
  id,
  checked,
  onChange,
  children,
  className,
  labelClassName,
  disabled = false,
  checkboxSize,
}) => {
  const baseClassName = 'user-select-none flex flex-row items-center';
  const calculatedLabelClassName = twMerge('-ml-[1em] pl-[1em] cursor-pointer', labelClassName);

  return (
    <div className={twMerge(baseClassName, className)}>
      <input type="checkbox" hidden={true} id={id} checked={checked} onChange={disabled ? () => {} : onChange} />
      <Label htmlFor={id} className={calculatedLabelClassName}>
        {checked ? (
          <Icon
            icon={faCheckSquareReg}
            className={twMerge('mr-1.5 !text-purple-600', disabled && '!text-neutral-300')}
            fontSize={checkboxSize}
          />
        ) : (
          <Icon
            icon={faSquareReg}
            className={twMerge('mr-1.5 !text-neutral-600', disabled && '!text-neutral-300')}
            fontSize={checkboxSize}
          />
        )}
        {children}
      </Label>
    </div>
  );
};

// TODO: make checkbox 25px
