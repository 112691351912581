import { useRouter } from 'next/router';
import React from 'react';
import { useCookies } from 'react-cookie';

import { useRouterParams } from '@/utils/hooks';
import { Features, useSpacesApiFeatureEnabled } from '@/utils/spaceFeatures';

import { LoadingSpinner, LoadingSpinnerWrapper } from '../LoadingSpinner';

const legacyCookie = 'useLegacyConsole';

const Spinner: React.FC = () => {
  return (
    <LoadingSpinnerWrapper>
      <LoadingSpinner visible={true} size="xl" />
    </LoadingSpinnerWrapper>
  );
};

/**
 * The reason for this component is to check if the Space supports Query API.
 * If not, the user will be redirected to the Legacy Console app (which is just a subdirectory).
 */
export const LegacyGuard: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const router = useRouter();
  const hostname = window.location.hostname;
  const path = router.asPath;

  const [cookies, setCookie, removeCookie] = useCookies([legacyCookie]);

  const isVercelPreview = !!hostname.match(/.vercel./);
  const isLegacySubdomain = !!hostname.match(/^legacy./);
  const hasLegacyCookie = cookies.useLegacyConsole === true;
  const isOnLegacyConsole = isVercelPreview ? hasLegacyCookie : isLegacySubdomain;

  const { orgName, spaceName } = useRouterParams([
    {
      name: 'orgName',
      disableThrowOnMissing: true,
    },
    {
      name: 'spaceName',
      disableThrowOnMissing: true,
    },
  ] as const);

  const queryApiFeature = useSpacesApiFeatureEnabled(orgName, spaceName, Features.queryApi);

  if (!orgName || !spaceName || queryApiFeature === undefined) {
    return <>{children}</>;
  }

  if (queryApiFeature.enabled === true) {
    if (isOnLegacyConsole) {
      // If QueryAPI is enabled and user is on Legacy Console, redirect to New Console
      if (isVercelPreview) {
        removeCookie(legacyCookie, { path: '/' });
        router.reload();
      } else {
        window.location.replace(`https://${hostname.replace('legacy.', '')}${path}`);
      }

      return <Spinner />;
    }
  } else if (!isOnLegacyConsole) {
    // If QueryAPI is not enabled and user is on New Console, redirect to Legacy Console
    if (isVercelPreview) {
      setCookie(legacyCookie, true, { path: '/' });
      router.reload();
    } else {
      window.location.replace(`https://legacy.${hostname}${path}`);
    }

    return <Spinner />;
  }

  return <>{children}</>;
};
