import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';

export type NavLinkProps = React.PropsWithChildren<LinkProps & React.AnchorHTMLAttributes<HTMLAnchorElement>>;

export const NavLink = ({ children, className, ...props }: NavLinkProps) => {
  const router = useRouter();
  return (
    <Link className={[className, router.asPath.startsWith(props.href) ? 'active' : ''].join(' ')} {...props}>
      {children}
    </Link>
  );
};
