import styled from '@emotion/styled';
import React from 'react';
import { fontAvenirBold } from 'upbound-frontend-constants';

import * as Avatars from '@/assets/avatars';
import { COLORS } from '@/constants/styles';
import { Flex } from '@/elements/Div';
import { Span } from '@/elements/Span';
import { Organization } from '@/generated/upbound-graphql';

const imageArray = [
  Avatars.AvatarBowlsBlue.src,
  Avatars.AvatarBowlsOrange.src,
  Avatars.AvatarBowlsStrawberry.src,
  Avatars.AvatarBowlsTeal.src,
  Avatars.AvatarBowlsViolet.src,
  Avatars.AvatarDotBlue.src,
  Avatars.AvatarDotOrange.src,
  Avatars.AvatarDotStrawberry.src,
  Avatars.AvatarDotTeal.src,
  Avatars.AvatarDotViolet.src,
  Avatars.AvatarMarriedBlue.src,
  Avatars.AvatarMarriedOrange.src,
  Avatars.AvatarMarriedStrawberry.src,
  Avatars.AvatarMarriedTeal.src,
  Avatars.AvatarMarriedViolet.src,
  Avatars.AvatarRadarBlue.src,
  Avatars.AvatarRadarOrange.src,
  Avatars.AvatarRadarStrawberry.src,
  Avatars.AvatarRadarTeal.src,
  Avatars.AvatarRadarViolet.src,
  Avatars.AvatarSailBlue.src,
  Avatars.AvatarSailOrange.src,
  Avatars.AvatarSailStrawberry.src,
  Avatars.AvatarSailTeal.src,
  Avatars.AvatarSailViolet.src,
  Avatars.AvatarSpaceBlue.src,
  Avatars.AvatarSpaceOrange.src,
  Avatars.AvatarSpaceStrawberry.src,
  Avatars.AvatarSpaceTeal.src,
  Avatars.AvatarSpaceViolet.src,
];

const StyledAvatarPlaceholder = styled(Flex)<{ size: number }>`
  background-position: center;
  background-size: ${({ size }) => size}px;
  background-repeat: no-repeat;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  padding-top: 2px;
`;

const propCheck = (name1: string | undefined, name2: string | undefined, last = false) => {
  if (name1) {
    return name1;
  } else if (name2) {
    return name2.split(' ')[last ? 1 : 0];
  } else {
    return '';
  }
};

const getAvatarComponent = (val: string, resourceArray: string[]) => {
  let charNum = val.charCodeAt(0) - 96;
  let index = 0;

  if (charNum < 0) {
    charNum *= -1;
  }

  while (charNum || index > resourceArray.length) {
    index += charNum % 10;
    charNum = Math.floor(charNum / 10);

    if (!charNum && index > resourceArray.length) {
      charNum = index - resourceArray.length;
      index = 0;
    }
  }

  if (index >= resourceArray.length) {
    index -= resourceArray.length;
  }

  return resourceArray[index];
};

const StyledAvatarPlaceholderContainer = styled(Flex)`
  align-self: center;
  flex: 0 0 auto;
`;

export const DisplayNameSpan = styled(Span)`
  ${fontAvenirBold};
  font-size: 14px;
`;

export const DisplayOrgSpan = styled(Span)`
  font-size: 12px;
  line-height: 12px;
  margin-top: 1px;
`;

export const AvatarPlaceholder: React.FC<
  React.PropsWithChildren<{
    altText?: string;
    memberName?: string | null;
    organization?: Pick<Organization, 'id' | 'displayName'> | null;
    size?: 20 | 22 | 24 | 30 | 32 | 48 | 72 | 92;
    type?: 'OrgAccount' | 'UserAccount' | 'orgMember' | 'teamMember';
    displayNameColor?: COLORS;
    orgNameColor?: COLORS;
  }>
> = ({ altText = '', memberName = null, organization = null, size = 48, type = 'UserAccount', children }) => {
  let valueToHash = ' ';
  const orgName: string =
    type === 'OrgAccount' && organization !== null ? propCheck(organization.displayName, undefined) : '';

  if (type === 'OrgAccount' && organization !== null && orgName !== null) {
    valueToHash = orgName.slice(0, 1).toUpperCase();
  } else if (memberName !== null) {
    valueToHash = memberName.slice(0, 1).toUpperCase();
  }

  const avatarImage = getAvatarComponent(valueToHash.toLowerCase(), imageArray);

  return (
    <StyledAvatarPlaceholderContainer aria-label={altText}>
      <StyledAvatarPlaceholder size={size} backgroundImage={`url(${avatarImage})`} />
      <Flex flexDirection="column" paddingLeft="8px" alignSelf="center">
        {children}
      </Flex>
    </StyledAvatarPlaceholderContainer>
  );
};
