export function capitalize(input: string) {
  if (!input) {
    return input;
  }

  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function pluralize(s?: string) {
  if (s) {
    return `${s}s`;
  }

  return;
}

export function sentenceCase(input: string) {
  if (!input) {
    return input;
  }

  const result = input.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

export function formatNumber(numText: string) {
  return numText.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}
