import { makeVar, useReactiveVar } from '@apollo/client';

import { AccountFragment, CurrentUserFragment, OrgAccount } from '../generated/upbound-graphql';

//#region allAccounts

const allAccountsRV = makeVar<AccountFragment[] | undefined>(undefined);

export function setAllAccountsRV(value: AccountFragment[] | undefined) {
  return allAccountsRV(value);
}

export function useAllAccounts(): [AccountFragment[] | undefined, (value: AccountFragment[] | undefined) => void] {
  return [useReactiveVar(allAccountsRV), setAllAccountsRV];
}

//#endregion

//#region currentAccount

const currentAccountRV = makeVar<AccountFragment | undefined>(undefined);

export function setCurrentAccountRV(value: AccountFragment | undefined) {
  currentAccountRV(value);
}

/**
 * A hook to get a tuple of the current account and a setter for the current account.
 * Note: Please use `useGetCurrentAccount` if not needing to set and in a context where a current account is always set.
 * @returns A tuple with the current account (could be undefined) and a setter.
 */
export function useCurrentAccount(): [AccountFragment | undefined, (value: AccountFragment | undefined) => void] {
  return [useReactiveVar(currentAccountRV), setCurrentAccountRV];
}

/**
 * A hook to retrieve the current `OrgAccount`. Note: Only use in a context where a current account is always set.
 * @returns The current `OrgAccount`
 * @throws if the account is not set or if it isn't an `OrgAccount`
 */
export function useGetCurrentAccount(): Extract<AccountFragment, { __typename?: 'OrgAccount' }> {
  const account = useReactiveVar(currentAccountRV);
  if (!account) {
    throw new Error('current account not set');
  }
  if (account.__typename !== 'OrgAccount') {
    throw new Error('current account must be an org');
  }
  return account;
}

//#endregion

//#region orgAccounts

const orgAccountsRV = makeVar<Array<OrgAccount> | undefined>(undefined);

export function setOrgAccountsRV(value: Array<OrgAccount> | undefined) {
  return orgAccountsRV(value);
}

export function useOrgAccounts(): [Array<OrgAccount> | undefined, (value: Array<OrgAccount> | undefined) => void] {
  return [useReactiveVar(orgAccountsRV), setOrgAccountsRV];
}

//#endregion

//#region currentUser

const currentUserRV = makeVar<CurrentUserFragment | undefined>(undefined);

export function setCurrentUserRV(value: CurrentUserFragment | undefined) {
  return currentUserRV(value);
}

export function useCurrentUserRaw(): [CurrentUserFragment | undefined, (value: CurrentUserFragment) => void] {
  return [useReactiveVar(currentUserRV), currentUserRV];
}

export function useCurrentUser(): CurrentUserFragment {
  const user = useReactiveVar(currentUserRV);
  if (!user) {
    throw new Error('CurrentUser was not set');
  }
  return user;
}

//#endregion

//#region loggedIn

const loggedInRV = makeVar<boolean | undefined>(undefined);

export function setLoggedInRV(value: boolean) {
  return loggedInRV(value);
}

export function useLoggedIn(): [boolean | undefined, (value: boolean) => void] {
  return [useReactiveVar(loggedInRV), setLoggedInRV];
}

//#endregion

//#region authHasError

const authHasErrorRV = makeVar<boolean>(false);

export function setAuthHasErrorRV(value: boolean) {
  return authHasErrorRV(value);
}

export function useAuthHasErrorRV(): [boolean, (value: boolean) => void] {
  return [useReactiveVar(authHasErrorRV), setAuthHasErrorRV];
}

//#endregion

const authReturnToRV = makeVar<string | undefined | null>(undefined);

export function getAccountsReturnToRV() {
  return authReturnToRV();
}

export function setAccountsReturnToRV(value: string | undefined | null) {
  return authReturnToRV(value);
}

export function useAccountsReturnToRV(): [string | undefined | null, (value: string | undefined | null) => void] {
  return [useReactiveVar(authReturnToRV), setAccountsReturnToRV];
}

const locutusEnabledRV = makeVar<boolean | undefined>(undefined);

export function getLocutusEnabledRV() {
  if (locutusEnabledRV() === undefined) {
    locutusEnabledRV(localStorage.getItem('locutusEnabled') === 'true' || false);
  }
  return locutusEnabledRV() ?? false;
}

export function setLocutusEnabledRV(value: boolean) {
  localStorage.setItem('locutusEnabled', value ? 'true' : 'false');
  return locutusEnabledRV(value);
}

export function useLocutusEnabledRV(): [boolean | undefined, (value: boolean) => void] {
  if (locutusEnabledRV() === undefined) {
    locutusEnabledRV(localStorage.getItem('locutusEnabled') === 'true' || false);
  }
  return [useReactiveVar(locutusEnabledRV), setLocutusEnabledRV];
}
