import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { OrganizationPermission, SpaceType, useGetSpacesNavQuery } from '@/generated/upbound-graphql';
import { useCurrentAccount } from '@/graphql/reactiveVars';
import { app, cloud } from '@/routes';
import { useSpaceVersionSatisfiesRange } from '@/services/spacesApi/features';
import { useRouterParams } from '@/utils/hooks';

import { LoadingSpinner, LoadingSpinnerWrapper } from '../LoadingSpinner';

export const AdminGuard: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
  const [currentAccount] = useCurrentAccount();
  const router = useRouter();
  const { orgName, spaceName } = useRouterParams([
    { name: 'orgName', disableThrowOnMissing: true },
    { name: 'spaceName', disableThrowOnMissing: true },
  ]);

  const isAdmin =
    currentAccount &&
    currentAccount.__typename === 'OrgAccount' &&
    currentAccount.organization.role === OrganizationPermission.Admin;

  const isSpacesPage = !!orgName && !!spaceName;

  const { data: spacesList } = useGetSpacesNavQuery({ variables: { account: orgName ?? '' }, skip: !isSpacesPage });
  const space = spacesList?.account.spaces.find(s => s.name === spaceName);
  const isLegacySpace = space?.type === SpaceType.Legacy;

  const nonAdminSupportCheckEnabled = isSpacesPage && !!space && !isLegacySpace;
  const supportsNonAdmin = useSpaceVersionSatisfiesRange(orgName, spaceName, '> 1.3', nonAdminSupportCheckEnabled);
  const loading = !currentAccount || !spacesList || (nonAdminSupportCheckEnabled && supportsNonAdmin === undefined);
  const needsAdmin = (isLegacySpace || !supportsNonAdmin) && !isAdmin;
  const spaceViewPath = !!orgName && !!spaceName ? cloud.spaceView.url(orgName, spaceName) : undefined;
  const adminRequiredPath = !!orgName && !!spaceName ? app.adminRequired.url(orgName, spaceName) : undefined;
  const isAdminRequiredPath = router.asPath === adminRequiredPath;

  useEffect(() => {
    if (isSpacesPage && !loading) {
      if (needsAdmin && !isAdminRequiredPath && adminRequiredPath) {
        router.replace(adminRequiredPath);
      } else if (!needsAdmin && isAdminRequiredPath && spaceViewPath) {
        router.replace(spaceViewPath);
      }
    }
  }, [isSpacesPage, loading, needsAdmin, isAdminRequiredPath, adminRequiredPath, spaceViewPath]);

  if (!isSpacesPage) {
    return <>{children}</>;
  }

  if (loading) {
    return (
      <LoadingSpinnerWrapper>
        <LoadingSpinner visible={true} size="xl" />
      </LoadingSpinnerWrapper>
    );
  }

  if ((needsAdmin && !isAdminRequiredPath) || (!needsAdmin && isAdminRequiredPath)) {
    return null;
  }

  return <>{children}</>;
};
